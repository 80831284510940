import { AuthenticationType } from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import React, { useEffect, useState } from 'react';
import {
  AzureMap,
  AzureMapHtmlMarker,
  AzureMapsProvider,
  useAzureMaps,
} from 'react-azure-maps';
import { MarkerIcon } from '../../assets/images';
import { azureMapsKey } from './constants';

const Marker = ({ location, activeMarker }) => {
  const style = {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
    },
    infobox: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
      position: 'absolute',
      top: '30px',
      padding: '10px',
      alignItems: 'start',
      justifyContent: 'center',
      width: '220px',
    },
    arrow: {
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderBottom: '20px solid white',
    },
    infoboxText: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  };

  const fleetNames = location.cus.map(item => item.flt.map(flt => flt.fld));
  return (
    <div style={style.container}>
      <div>
        <MarkerIcon />
      </div>
      {activeMarker && activeMarker === location.id && (
        <>
          <div style={style.arrow}></div>
          <div style={style.infobox}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '200px',
              }}>
              <div style={style.infoboxText}>{location.dsit}</div>
              <div>
                {fleetNames.length}
                &nbsp;
                {fleetNames.length > 1 ? 'fleets' : 'fleet'}
              </div>
            </div>
            <div
              style={{
                borderBottom: '1px solid #00001f30',
                width: '100%',
              }}></div>
            <div>
              {fleetNames.map((fleet, index) => (
                <div key={index}>{fleet}</div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
function MapProvider({ mapdata, handleFilterDropdownChange, mapHeight }) {
  const [data, setData] = useState(mapdata);
  const [activeMarker, setActiveMarker] = useState(null);
  const defaultMapCenter = [8.52, 47.38];
  const [mapCenter, setMapCenter] = useState(defaultMapCenter);
  const [zoom, setZoom] = useState(4);
  const { mapRef, isMapReady } = useAzureMaps();

  const option = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: azureMapsKey,
    },
    center: [8.52, 47.38],
    language: 'en-US',
    zoom,
  };
  useEffect(() => {
    setData(mapdata);
    if (mapdata.length === 1) {
      setMapCenter([mapdata[0].lng, mapdata[0].lat]);
      setZoom(8);
    }
    if (mapdata.length > 1) {
      setMapCenter(defaultMapCenter);
      setZoom(4);
    }
  }, [mapdata]);
  const handleClick = location => {
    setMapCenter([location.lng, location.lat]);
    handleFilterDropdownChange(
      location.dsit,
      'Sites',
      location.lat,
      location.lng
    );
  };

  // Set the map center when the map is ready or when the map center changes.
  useEffect(() => {
    if (isMapReady && mapRef) {
      mapRef.setCamera({
        center: mapCenter,
        zoom,
        type: 'fly',
        duration: 1000,
      });
    }
  }, [isMapReady, mapCenter]);

  return (
    <div style={{ height: mapHeight || '70vh' }}>
      <AzureMap
        options={option}
        controls={[
          {
            controlName: 'StyleControl',
            controlOptions: {
              mapStyles: ['road', 'satellite_road_labels'],
              layout: 'list',
            },
            options: { position: 'top-left' },
          },
          { controlName: 'ZoomControl', options: { position: 'top-right' } },
        ]}>
        {data.length > 0 &&
          data.map(location => (
            <AzureMapHtmlMarker
              key={location.id}
              options={{
                position: { lng: location.lng, lat: location.lat },
              }}
              markerContent={
                <Marker location={location} activeMarker={activeMarker} />
              }
              events={[
                {
                  eventName: 'mouseenter',
                  callback: () => {
                    setActiveMarker(location.id);
                  },
                },
                {
                  eventName: 'mouseleave',
                  callback: () => {
                    setActiveMarker(null);
                  },
                },
                {
                  eventName: 'click',
                  callback: () => handleClick(location),
                },
              ]}
            />
          ))}
      </AzureMap>
    </div>
  );
}

function SimpleMapProvider({ mapdata, mapHeight }) {
  const [data, setData] = useState(mapdata);
  const defaultMapCenter = [8.52, 47.38];
  const [mapCenter, setMapCenter] = useState(defaultMapCenter);
  const { mapRef, isMapReady } = useAzureMaps();

  useEffect(() => {
    setData(mapdata);
    setMapCenter([mapdata.lng, mapdata.lat]);
  }, [mapdata]);
  // Set the map center when the map is ready or when the map center changes.
  useEffect(() => {
    if (isMapReady && mapRef) {
      mapRef.setCamera({
        center: mapCenter,
        zoom: 10,
        type: 'fly',
        duration: 100,
      });
    }
  }, [isMapReady, mapCenter]);

  const option = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: azureMapsKey,
    },
    center: defaultMapCenter,
    language: 'en-US',
    zoom: 4,
  };
  return (
    <div style={{ height: mapHeight }}>
      <AzureMap
        options={option}
        controls={[
          {
            controlName: 'StyleControl',
            controlOptions: {
              mapStyles: ['road', 'satellite_road_labels'],
              layout: 'list',
            },
            options: { position: 'top-left' },
          },
          { controlName: 'ZoomControl', options: { position: 'top-right' } },
        ]}>
        {data && (
          <AzureMapHtmlMarker
            key={data.id}
            options={{
              position: { lng: data.lng, lat: data.lat },
            }}
            markerContent={<MarkerIcon />}
          />
        )}
      </AzureMap>
    </div>
  );
}

function Map({ mapdata, handleFilterDropdownChange, mapHeight, mapType }) {
  return (
    <AzureMapsProvider>
      {mapType !== 'simple' ? (
        <MapProvider
          mapdata={mapdata}
          handleFilterDropdownChange={handleFilterDropdownChange}
          mapHeight={mapHeight}
        />
      ) : (
        <SimpleMapProvider mapdata={mapdata} mapHeight={mapHeight} />
      )}
    </AzureMapsProvider>
  );
}

export default Map;
