import React from 'react';
import './styles.scss';
// const colors = ['Warning', 'Severe', 'Poor'];
const colors = [
  { color: 'Warning', code: '#FFA200' },
  { color: 'Severe', code: '#FF7300' },
  { color: 'Poor', code: '#EF3A34' },
];

const SquareBoxWithColorSelector = ({
  color,
  setColor,
  isOpen,
  toggleDropDown,
  SetOpenIndex,
}) => {
  const selectColor = selectedColor => {
    setColor(selectedColor);
    toggleDropDown(); // This will close the dropdown after selecting a color
  };

  return (
    <div style={{ position: 'relative' }} id="squareboxColorSelector">
      <div className="colorBox">
        <div
          onClick={toggleDropDown}
          className="innerBox"
          style={{
            backgroundColor: color || '#FFA200',
            cursor: 'pointer',
          }}></div>
      </div>
      {isOpen && (
        <div
          className="popupColorContainer"
          style={{
            position: 'absolute',
            marginTop: '10px',
            backgroundColor: 'white',
            top: '-104px',
            left: '-106px',
            zIndex: 9,
            width: '103px',
          }}>
          {colors.map(color => (
            <div
              className="colorListSelection"
              key={color.color}
              onClick={() => selectColor(color.code)}>
              <div
                className="colorBoxInner"
                style={{ backgroundColor: color.code }}></div>
              {color.color}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SquareBoxWithColorSelector;
