import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  fetchExcelReportDataFailure,
  fetchExcelReportDataSuccess,
  fetchExcelReportEmailSuccess,
  fetchFleetConfigReportFailure,
  fetchFleetConfigReportSuccess,
  fetchFleetSOCReportFailure,
  fetchFleetSOCReportSuccess,
  fetchFleetTemperatureReportFailure,
  fetchFleetTemperatureReportSuccess,
  fetchFleetWordReportFailure,
  fetchFleetWordReportSuccess,
} from './actions';
import {
  FETCH_EXCELREPORT_DATA,
  FETCH_EXCELREPORT_EMAIL,
  FETCH_FLEETREPORTCONFIG_DATA,
  FETCH_FLEETREPORTSOC_DATA,
  FETCH_FLEETREPORTTEMPERATURE_DATA,
  FETCH_FLEETWORDREPORT_DATA,
} from './constants';

export function* fetchExcelReportSaga() {
  yield takeLatest(FETCH_EXCELREPORT_DATA, fetchingExcelReportSaga);
}
export function* fetchExcelReportEmailSaga() {
  yield takeLatest(FETCH_EXCELREPORT_EMAIL, fetchingExcelReportSaga);
}
export function* fetchingExcelReportSaga(action) {
  try {
    const { fleetname, startDate, endDate, fleetReportOptions } =
      action.payload;
    const response = yield call(
      api.fetchExcelReportData,
      fleetname,
      startDate,
      endDate,
      fleetReportOptions
    );
    if (response.dc) {
      const excelReportData = response.dc;
      yield put(fetchExcelReportDataSuccess(excelReportData));
    } else {
      const excelReportEmail = response;
      yield put(fetchExcelReportEmailSuccess(excelReportEmail));
    }
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchExcelReportDataFailure));
  }
}

export function* fetchFleetWordReportSaga() {
  yield takeLatest(FETCH_FLEETWORDREPORT_DATA, fetchingFleetWordReportSaga);
}
export function* fetchingFleetWordReportSaga(action) {
  try {
    const { fleetname, slaDate, fleetReportOptions } = action.payload;
    const response = yield call(
      api.fetchFleetWordReportData,
      fleetname,
      slaDate,
      fleetReportOptions
    );

    if (response.dc) {
      const excelReportData = response.dc[0];
      yield put(fetchFleetWordReportSuccess(excelReportData));
    }
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchFleetWordReportFailure));
  }
}
// SOC
export function* fetchFleetSOCReportSaga() {
  yield takeLatest(FETCH_FLEETREPORTSOC_DATA, fetchingFleetSOCReportSaga);
}
export function* fetchingFleetSOCReportSaga(action) {
  try {
    const { fleetname, slaDate, fleetReportOptions } = action.payload;
    const response = yield call(
      api.fetchFleetSOCReportData,
      fleetname,
      slaDate,
      fleetReportOptions
    );

    if (response.dc) {
      const excelReportData = response.dc[0];
      yield put(fetchFleetSOCReportSuccess(excelReportData));
    }
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchFleetSOCReportFailure));
  }
}
// Temperature
export function* fetchFleetTemperatureReportSaga() {
  yield takeLatest(
    FETCH_FLEETREPORTTEMPERATURE_DATA,
    fetchingFleetTemperatureReportSaga
  );
}
export function* fetchingFleetTemperatureReportSaga(action) {
  try {
    const { fleetname, slaDate, fleetReportOptions } = action.payload;
    const response = yield call(
      api.fetchFleetTemperatureReportData,
      fleetname,
      slaDate,
      fleetReportOptions
    );

    if (response.dc) {
      const excelReportData = response.dc[0];
      yield put(fetchFleetTemperatureReportSuccess(excelReportData));
    }
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchFleetTemperatureReportFailure));
  }
}
// Config
export function* fetchFleetConfigReportSaga() {
  yield takeLatest(FETCH_FLEETREPORTCONFIG_DATA, fetchingFleetConfigReportSaga);
}
export function* fetchingFleetConfigReportSaga(action) {
  try {
    const { fleetname, slaDate, fleetReportOptions } = action.payload;
    const response = yield call(
      api.fetchFleetConfigReportData,
      fleetname,
      slaDate,
      fleetReportOptions
    );

    if (response.dc) {
      const excelReportData = response.dc[0];
      yield put(fetchFleetConfigReportSuccess(excelReportData));
    }
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchFleetConfigReportFailure));
  }
}
