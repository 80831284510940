import { createSelector } from 'reselect';
// ESS change starts here
const VehicleProductsViewData = () => state =>
  state.get('VehicleProductsViewData');

const loadingBatteryOverallConditionSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingBatteryOverallCondition')
  );

const batteryOverallConditionDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('batteryOverallConditionData')
  );

const loadingBatteryStateOfChargeSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingBatterySOC')
  );

const batteryStateOfChargeDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('batterySOCData')
  );

const loadingBatterySOCRangeSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingBatterySOCRange')
  );

const batterySOCRangeDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('batterySOCRangeData')
  );

const loadingTemperatureHistogramSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingTemperatureHistogram')
  );

const temperatureHistogramDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('temperatureHistogramData')
  );

const loadingUsgSocHistogramSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingUsgSocHistogram')
  );

const usgSocHistogramDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('usgSocHistogramData')
  );

const loadingUsgPowHistogramSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingUsgPowHistogram')
  );

const usgPowHistogramDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('usgPowHistogramData')
  );

const loadingUsgDodHistogramSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingUsgDodHistogram')
  );

const usgDodHistogramDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('usgDodHistogramData')
  );

const loadingOperatingHoursHistogramSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingOperatingHoursHistogram')
  );

const operatingHoursHistogramDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('operatingHoursHistogramData')
  );

const loadingCellVoltageSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingCellVoltage')
  );

// sync chart

const loadingSyncGraphSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingSyncGraph')
  );

const loadingHistogramSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingHistogram')
  );

const loadingBoxPlotSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingBoxPlot')
  );

const histogramDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('histogramData')
  );

const boxPlotDataSelector = () =>
  createSelector(VehicleProductsViewData(), state => state.get('boxPlotData'));

const cellVoltageDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('cellVoltageData')
  );

// sync chart
const syncGraphDatSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('syncGraphData')
  );

const syncGraphDatResSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('syncGraphDataRes')
  );

const syncGraphMain1Selector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedMainSignal1')
  );

const syncGraphMain2Selector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedMainSignal2')
  );

const syncGraphMain3Selector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedMainSignal3')
  );

const syncGraphOverlay1Selector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedOverlaySignal1')
  );

const syncGraphOverlay2Selector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedOverlaySignal2')
  );

const syncGraphOverlay3Selector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedOverlaySignal3')
  );

const syncGraphMainSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedMainSignal')
  );

const syncGraphOverlaySelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedOverlaySignal')
  );

const syncGraphChartSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedSyncChart')
  );

const syncGraphDataSelector = () =>
  createSelector(
    syncGraphDatSelector(),
    syncGraphMain1Selector(),
    syncGraphMain2Selector(),
    syncGraphMain3Selector(),
    syncGraphOverlay1Selector(),
    syncGraphOverlay2Selector(),
    syncGraphOverlay3Selector(),
    (
      graphData,
      mainSignal1,
      mainSignal2,
      mainSignal3,
      overlaySignal1,
      overlaySignal2,
      overlaySignal3
    ) => {
      let chart1 = [];
      let chart2 = [];
      let chart3 = [];
      let result = [];
      const graphDataTemp = graphData;
      if (graphData != null) {
        chart1.push(
          ...graphDataTemp.filter(item => {
            return item.name === mainSignal1;
          })
        );
        chart1.push(
          ...graphDataTemp.filter(item => {
            return item.name === overlaySignal1;
          })
        );

        chart2.push(
          ...graphDataTemp.filter(item => {
            return item.name === mainSignal2;
          })
        );
        chart2.push(
          ...graphDataTemp.filter(item => {
            return item.name === overlaySignal2;
          })
        );

        chart3.push(
          ...graphDataTemp.filter(item => {
            return item.name === mainSignal3;
          })
        );
        chart3.push(
          ...graphDataTemp.filter(item => {
            return item.name === overlaySignal3;
          })
        );
        chart1 = JSON.parse(JSON.stringify(chart1));
        chart2 = JSON.parse(JSON.stringify(chart2));
        chart3 = JSON.parse(JSON.stringify(chart3));
        result = [
          { series: chart1, title: mainSignal1 + ' vs ' + overlaySignal1 },
          { series: chart2, title: mainSignal2 + ' vs ' + overlaySignal2 },
          { series: chart3, title: mainSignal3 + ' vs ' + overlaySignal3 },
        ];
      }

      return result;
    }
  );

const loadingBatteryOverviewSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingBatteryOverview')
  );

const batteryOverviewDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('batteryOverviewData')
  );

// Timetrend chart
const loadingTimetrendSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingTimetrend')
  );

const selectedMainTrendSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedMainTrend')
  );

const selectedOverlayTrendSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('selectedOverlaysTrend')
  );

const ttAvgCellVSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTAvgCellVData')
  );
const ttAvgTemperatureSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTAvgTemperatureData')
  );
const ttSOCSelector = () =>
  createSelector(VehicleProductsViewData(), state => state.get('tTSOCData'));
const ttPowerSelector = () =>
  createSelector(VehicleProductsViewData(), state => state.get('tTPowerData'));
const ttBVoltageSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTBVoltageData')
  );
const ttBCurrentSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTBCurrentData')
  );
const ttBPeakChgSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTBatteryPeakCharge')
  );
const ttBPeakDischgSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTBatteryPeakDisCharge')
  );
const ttBConstChgSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTBatteryConstCharge')
  );
const ttBConstDischgSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTBatteryConstDisCharge')
  );
const ttBOverloadCntrSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTBatteryOverloadCounter')
  );
const ttReliabilitySelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTReliability')
  );
const ttFeedFlowSelector = () =>
  createSelector(VehicleProductsViewData(), state => state.get('tTFeedFlow'));
const ttOverloadCounterSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTOverloadCounter')
  );
const ttReturnFlowSelector = () =>
  createSelector(VehicleProductsViewData(), state => state.get('tTReturnFlow'));
const ttDiagnosticSelector = () =>
  createSelector(VehicleProductsViewData(), state => state.get('tTDiagnostic'));
const ttContactorStatusSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('tTContactorStatus')
  );

const timetrendSelector = () =>
  createSelector(
    selectedMainTrendSelector(),
    selectedOverlayTrendSelector(),
    ttAvgCellVSelector(),
    ttAvgTemperatureSelector(),
    ttSOCSelector(),
    ttPowerSelector(),
    ttBVoltageSelector(),
    ttBCurrentSelector(),
    ttBPeakChgSelector(),
    ttBPeakDischgSelector(),
    ttBConstChgSelector(),
    ttBConstDischgSelector(),
    ttBOverloadCntrSelector(),
    ttReliabilitySelector(),
    ttFeedFlowSelector(),
    ttOverloadCounterSelector(),
    ttReturnFlowSelector(),
    ttDiagnosticSelector(),
    ttContactorStatusSelector(),
    (
      selectedMain,
      selectedOverlays,
      avgCellV,
      avgTemperature,
      soc,
      power,
      bVoltage,
      bCurrent,
      bPeakChg,
      bPeakDischg,
      bConstChg,
      bConstDischg,
      bOverloadCntr,
      reliability,
      feedFlow,
      overloadCounter,
      returnFlow,
      diagnostic,
      contactorStatus
    ) => {
      const chartValues = [];
      if (
        avgCellV != null &&
        avgTemperature != null &&
        soc != null &&
        power != null &&
        bVoltage != null &&
        bCurrent != null &&
        bPeakChg != null &&
        bPeakDischg != null &&
        bConstChg != null &&
        bConstDischg != null &&
        bOverloadCntr != null &&
        reliability != null &&
        feedFlow != null &&
        overloadCounter != null &&
        returnFlow != null &&
        diagnostic != null &&
        avgCellV.data.length !== 0 &&
        avgTemperature.data.length !== 0 &&
        soc.data.length !== 0 &&
        power.data.length !== 0 &&
        bVoltage.data.length !== 0 &&
        bCurrent.data.length !== 0 &&
        bPeakChg.data.length !== 0 &&
        bPeakDischg.data.length !== 0 &&
        bConstChg.data.length !== 0 &&
        bConstDischg.data.length !== 0 &&
        bOverloadCntr.data.length !== 0 &&
        reliability.data.length !== 0 &&
        feedFlow.data.length !== 0 &&
        overloadCounter.data.length !== 0 &&
        returnFlow.data.length !== 0 &&
        diagnostic.data.length !== 0 &&
        contactorStatus.data.length !== 0
      ) {
        chartValues.push(avgCellV);
        chartValues.push(avgTemperature);
        chartValues.push(soc);
        chartValues.push(power);
        chartValues.push(bVoltage);
        chartValues.push(bCurrent);
        chartValues.push(bPeakChg);
        chartValues.push(bPeakDischg);
        chartValues.push(bConstChg);
        chartValues.push(bConstDischg);
        chartValues.push(bOverloadCntr);
        chartValues.push(reliability);
        chartValues.push(feedFlow);
        chartValues.push(overloadCounter);
        chartValues.push(returnFlow);
        chartValues.push(diagnostic);
        chartValues.push(contactorStatus);
      } else {
        const result = [];
        selectedMain !== null && result.push(selectedMain);
        selectedOverlays !== null && result.push(...selectedOverlays);

        result.forEach(value => {
          switch (value) {
            case 'Cell Voltage':
              if (
                avgCellV !== null &&
                avgCellV !== undefined &&
                avgCellV.data.length !== 0
              ) {
                chartValues.push(avgCellV);
              }
              break;
            case 'Temperature':
              if (
                avgTemperature !== null &&
                avgTemperature !== undefined &&
                avgTemperature.data.length !== 0
              ) {
                chartValues.push(avgTemperature);
              }
              break;
            case 'State of Charge':
              if (soc !== null && soc !== undefined && soc.data.length !== 0) {
                chartValues.push(soc);
              }
              break;
            case 'Power':
              if (
                power !== null &&
                power !== undefined &&
                power.data.length !== 0
              ) {
                chartValues.push(power);
              }
              break;
            case 'Battery Voltage':
              if (
                bVoltage !== null &&
                bVoltage !== undefined &&
                bVoltage.data.length !== 0
              ) {
                chartValues.push(bVoltage);
              }
              break;
            case 'Battery Current':
              if (
                bCurrent !== null &&
                bCurrent !== undefined &&
                bCurrent.data.length !== 0
              ) {
                chartValues.push(bCurrent);
              }
              break;
            case 'Battery Peak Chg.':
              if (
                bPeakChg !== null &&
                bPeakChg !== undefined &&
                bPeakChg.data.length !== 0
              ) {
                chartValues.push(bPeakChg);
              }
              break;
            case 'Battery Peak DisChg.':
              if (
                bPeakDischg !== null &&
                bPeakDischg !== undefined &&
                bPeakDischg.data.length !== 0
              ) {
                chartValues.push(bPeakDischg);
              }
              break;
            case 'Battery Cont. Chg.':
              if (
                bConstChg !== null &&
                bConstChg !== undefined &&
                bConstChg.data.length !== 0
              ) {
                chartValues.push(bConstChg);
              }
              break;
            case 'Battery Cont. DisChg.':
              if (
                bConstDischg !== null &&
                bConstDischg !== undefined &&
                bConstDischg.data.length !== 0
              ) {
                chartValues.push(bConstDischg);
              }
              break;
            case 'Battery Overload Cntr':
              if (
                bOverloadCntr !== null &&
                bOverloadCntr !== undefined &&
                bOverloadCntr.data.length !== 0
              ) {
                chartValues.push(bOverloadCntr);
              }
              break;
            case 'Reliability':
              if (
                reliability !== null &&
                reliability !== undefined &&
                reliability.data.length !== 0
              ) {
                chartValues.push(reliability);
              }
              break;
            case 'Feed Flow':
              if (
                feedFlow !== null &&
                feedFlow !== undefined &&
                feedFlow.data.length !== 0
              ) {
                chartValues.push(feedFlow);
              }
              break;
            case 'Overload Counter':
              if (
                overloadCounter !== null &&
                overloadCounter !== undefined &&
                overloadCounter.data.length !== 0
              ) {
                chartValues.push(overloadCounter);
              }
              break;
            case 'Return Flow':
              if (
                returnFlow !== null &&
                returnFlow !== undefined &&
                returnFlow.data.length !== 0
              ) {
                chartValues.push(returnFlow);
              }
              break;
            case 'Diagnostic Event':
              if (
                diagnostic !== null &&
                diagnostic !== undefined &&
                diagnostic.data.length !== 0
              ) {
                chartValues.push(diagnostic);
              }
              break;
            case 'Contactor Status':
              if (
                contactorStatus !== null &&
                contactorStatus !== undefined &&
                contactorStatus.data.length !== 0
              ) {
                chartValues.push(contactorStatus);
              }
              break;

            default:
              break;
          }
        });
      }
      return chartValues;
    }
  );

const timetrendDataSelector = () =>
  createSelector(
    timetrendSelector(),
    selectedMainTrendSelector(),
    selectedOverlayTrendSelector(),
    (graphData, mainTrend, overlayTrend) => {
      let result = [];
      if (graphData !== null && graphData !== undefined) {
        result.push(
          ...graphData.filter(item => {
            return item.config.label === mainTrend;
          })
        );
        overlayTrend &&
          overlayTrend.forEach(value => {
            result.push(
              ...graphData.filter(item => {
                return item.config.label === value;
              })
            );
          });
        result = JSON.parse(JSON.stringify(result));
      }

      return result;
    }
  );

const loadingBatteryRelSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingBatteryRel')
  );

const batteryRelDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('batteryRelData')
  );

const loadingBatteryStrSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingBatteryStr')
  );

const batteryStrDataSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('batteryStrData')
  );

const loadingSOHSelector = () =>
  createSelector(VehicleProductsViewData(), state =>
    state.get('loadingSOHData')
  );

const SOHDataSelector = () =>
  createSelector(VehicleProductsViewData(), state => state.get('SOHData'));

export {
  SOHDataSelector,
  batteryOverallConditionDataSelector,
  batteryOverviewDataSelector,
  batteryRelDataSelector,
  batterySOCRangeDataSelector,
  batteryStateOfChargeDataSelector,
  batteryStrDataSelector,
  boxPlotDataSelector,
  cellVoltageDataSelector,
  histogramDataSelector,
  loadingBatteryOverallConditionSelector,
  loadingBatteryOverviewSelector,
  loadingBatteryRelSelector,
  loadingBatterySOCRangeSelector,
  loadingBatteryStateOfChargeSelector,
  loadingBatteryStrSelector,
  loadingBoxPlotSelector,
  loadingCellVoltageSelector,
  loadingHistogramSelector,
  loadingOperatingHoursHistogramSelector,
  loadingSOHSelector,
  loadingSyncGraphSelector,
  loadingTemperatureHistogramSelector,
  loadingTimetrendSelector,
  loadingUsgDodHistogramSelector,
  loadingUsgPowHistogramSelector,
  loadingUsgSocHistogramSelector,
  operatingHoursHistogramDataSelector,
  selectedMainTrendSelector,
  selectedOverlayTrendSelector,
  syncGraphChartSelector,
  syncGraphDatResSelector,
  syncGraphDatSelector,
  syncGraphDataSelector,
  syncGraphMain1Selector,
  syncGraphMain2Selector,
  syncGraphMain3Selector,
  syncGraphMainSelector,
  syncGraphOverlay1Selector,
  syncGraphOverlay2Selector,
  syncGraphOverlay3Selector,
  syncGraphOverlaySelector,
  temperatureHistogramDataSelector,
  timetrendDataSelector,
  ttAvgCellVSelector,
  ttAvgTemperatureSelector,
  ttBConstChgSelector,
  ttBConstDischgSelector,
  ttBCurrentSelector,
  ttBOverloadCntrSelector,
  ttBPeakChgSelector,
  ttBPeakDischgSelector,
  ttBVoltageSelector,
  ttDiagnosticSelector,
  ttContactorStatusSelector,
  ttFeedFlowSelector,
  ttOverloadCounterSelector,
  ttPowerSelector,
  ttReliabilitySelector,
  ttReturnFlowSelector,
  ttSOCSelector,
  usgDodHistogramDataSelector,
  usgPowHistogramDataSelector,
  usgSocHistogramDataSelector,
};
