import GroupedCategories from 'highcharts-grouped-categories';
import Highcharts from 'highcharts-latest';
import HighchartsHeatmap from 'highcharts-latest/modules/heatmap';
import React from 'react';
import { checkUserEntitlements } from '../../userConfig';
import { platformVersion } from '../../utils/excel-utils';
import { formatNumber } from '../../utils/fleetStatisticsDataUtil';
import './heatmap.css';

HighchartsHeatmap(Highcharts);
GroupedCategories(Highcharts);

export class Heatmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandSide: this.props.expandSide,
      width: null,
      result: [],
      rectWidth: null,
      SERIES_DATA: this.props.SERIES_DATA,
      legend: {
        min: 0,
        max: this.props.max,
        midPoint: this.props.midPoint,
        fStart: this.props.fStart,
        fEnd: this.props.fEnd,
        sStart: this.props.sStart,
        sEnd: this.props.sEnd,
      },
    };
  }

  refactorNames(categories) {
    categories.forEach(category => {
      const regex = /.*_([^_]+)/; /// ^.*?(\d{1,3})\D*$/;
      category.name = category.name.replace(regex, '$1');
      return category.name || null;
      // commented as it was unrechable code
      // category.categories.forEach((subcategory, index) => {
      //   subcategory.categories[index] = subcategory.replace(
      //     /^([A-Za-z]{4}).*(.{4})$/,
      //     '$1...$2'
      //   );
      // });
    });

    return categories;
  }

  updateResultValue = () => {
    const result = this.countValuesAboveMidPoint(this.state.SERIES_DATA);
    if (result) {
      this.setState({ result });
    }
  };

  componentDidMount() {
    const result = this.countValuesAboveMidPoint(this.props.SERIES_DATA);
    this.setState({ result });
    this.createChart();
  }

  componentWillUnmount() {
    setTimeout(() => {
      this.chart.destroy();
    }, 0);
  }

  createChart = () => {
    this.chart = Highcharts.chart('heatmap', {
      credits: false,

      chart: {
        type: 'heatmap',
        plotShadow: false,
        // scrollablePlotArea: {
        //   minHeight: this.props.heatmap_min_height,
        //   opacity: 1,
        // },
        height:
          this.props.heatmap_min_height > 550
            ? this.props.heatmap_min_height
            : 550,
      },
      title: {
        text: '<span style="backgroundColor:#fff; padding:17px 16px 12px 16px; fontSize: 11px">warning count</span>',
        useHTML: true,
        align: 'left',
        marginTop: 0,
        offset: 0,
        x: 5,
        y: 80,
        style: {
          background: 'transparent',
          padding: '12px 10px',
          width: 'auto',
          fontSize: '11px',
          fontWeight: 'normal',
          textAlign: 'center',
        },
      },
      yAxis: {
        useHTML: true,
        tickWidth: 0.5,
        lineColor: '#333',
        lineWidth: 10,
        tickColor: '#7b7a7a',
        labels: {
          formatter: function () {
            const id = 'y-axis-tick-' + this.pos;
            return '<span id="' + id + '">' + this.value + '</span>';
          },
          x: -10,
        },
        categories: this.refactorNames(this.props.STORE_CATEGORIES),
        reversed: true,
      },
      xAxis: [
        {
          categories: this.props.X_AXIS_CATEGORIES,
          opposite: false,
          tickWidth: 1.5,
          tickLength: 5,
          tickmarkPlacement: 'right',
          tickColor: '#333',
          lineColor: '#ccd6eb',
          lineWidth: 1,
          labels: {
            align: 'center',
            y: 20,
            style: {
              fontWeight: 'bold',
            },
          },
          offset: 5,
        },
        {
          linkedTo: 0,
          categories: this.countValuesAboveMidPoint(this.props.SERIES_DATA),
          opposite: true,
          title: null,
          lineColor: '#ccd6eb',
          lineWidth: 1,
          tickWidth: 1.5,
          tickLength: 5,
          tickmarkPlacement: 'right',
          tickColor: '#333',
          labels: {
            style: {
              fontWeight: 'bolder',
              color: '#565656',
              fontSize: 12,
            },
            align: 'center',
            y: -2,
          },
        },
      ],
      legend: {
        align: 'right',
        y: -70,
        x: 0,
        verticalAlign: 'top',
        layout: 'horizontal',
        symbolHeight: 22,
        backgroundColor: '#fff',
        borderWidth: 5,
        borderColor: '#fff',
        labels: {
          align: 'center',
          y: 10,
        },
        symbolWidth: this.props.heatmap_min_width - 60,
      },

      colorAxis: {
        min: this.state.legend.min,
        max: this.state.legend.max,
        stops: [
          [this.state.legend.min, this.state.legend.fStart],
          [
            this.state.legend.midPoint / this.state.legend.max,
            this.state.legend.fEnd,
          ],
          [
            this.state.legend.midPoint / this.state.legend.max,
            this.state.legend.sStart,
          ],
          [
            this.state.legend.max / this.state.legend.max,
            this.state.legend.sEnd,
          ],
        ],
        tickPositions: [
          this.state.legend.min,
          this.state.legend.midPoint,
          this.state.legend.max,
        ],
        tickWidth: 1,
        tickColor: '#000',
        tickLength: 22,
        tickPosition: 'inside',
        labels: {
          format: '{value} kwh',
        },
      },
      tooltip: {
        outside: true,
        borderWidth: 1,
        backgroundColor: '#fff',
        style: {
          zIndex: 9999,
          cursor: 'pointer',
        },
        formatter: function () {
          const value = this.point.value;
          const hours = this.point.hours;
          if (hours !== '') {
            return `
          <b>Date:</b> ${this.series.xAxis.categories[this.point.x]}<br/>
          <b>Vehicle:</b> ${
            this.series.yAxis.categories[this.point.y].parent.name
          }<br/>
          <b>ESS:</b> ${this.point.name && this.point.name}<br/>
          <b>${platformVersion() === '3' ? `Accumulated total throughput:` : `Throughput:`}</b> ${value !== '' ? formatNumber(value.toFixed(0)) : ''} kWh <br/>
          <b>${platformVersion() === '3' ? `Accumulated total operating hours:` : `Operating hours:`}</b> ${
            hours !== '' ? formatNumber(hours.toFixed(0)) : ''
          } h <br/>
          `;
          } else {
            return false;
          }
        },
      },
      plotOptions: {
        heatmap: {
          // scrollablePlotArea: {
          //   maskFill: "rgba(0,0,0,0,)",
          // },
          borderWidth: 2,
          borderColor: '#fff',
          dataLabels: {
            enabled: false,
            color: '#000',
            style: {
              textOutline: false,
              color: 'black',
              margin: '5px',
              padding: '5px',
            },
          },
          point: {
            events: {},
          },
        },
        series: {
          turboThreshold: 0,
          cursor:
            (platformVersion() !== '3' &&
              !checkUserEntitlements('ESS:Product-Usage')) ||
            (platformVersion() !== '3' &&
              !checkUserEntitlements('ESS:Product-Usage-Power'))
              ? 'default'
              : 'pointer',
          point: {
            events: {
              mouseOver: function () {
                const id = 'y-axis-tick-' + this.y;
                const label = document.getElementById(id);
                if (label) {
                  label.style.fontWeight = 'bold';
                  label.style.fontSize = '12px';
                }
              },
              mouseOut: function () {
                const id = 'y-axis-tick-' + this.y;
                const label = document.getElementById(id);
                if (label) {
                  label.style.fontWeight = 'normal';
                  label.style.fontSize = '12px';
                }
              },
              click: event => {
                this.props.onBlockClick(event.point, event.point.options);
              },
            },
          },
        },
      },
      series: [...this.state.SERIES_DATA],
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
      pointPlacement: 'between',
    });
    const overusedIndicator = (chart, x, y, width, height, color) => {
      return chart.renderer
        .rect(x, y, width, height)
        .attr({
          fill: color,
          zIndex: 5,
        })
        .add();
    };
    this.props.SERIES_DATA.forEach(dataset => {
      dataset.data.forEach(point => {
        const { x, y, overuse } = point;
        const blockWidth = this.props.heatmap_min_width;
        if (overuse) {
          const xPosition = this.chart.xAxis[0].toPixels(x) - 38;
          const yPosition = this.chart.yAxis[0].toPixels(y) + 8;
          overusedIndicator(
            this.chart,
            blockWidth < 560
              ? xPosition + 22
              : blockWidth > 550 && blockWidth < 800
                ? xPosition + 14
                : xPosition,
            yPosition,
            blockWidth < 560
              ? 32
              : blockWidth > 550 && blockWidth < 800
                ? 50
                : 75,
            6,
            '#FFA200'
          );
        }
      });
    });
    this.chart.customShapes = [];
  };

  countValuesAboveMidPoint() {
    const result = [];
    for (let i = 0; i < 12; i++) {
      let count = 0;
      for (const series of this.state.SERIES_DATA) {
        for (const data of series.data) {
          if (data.x === i && data.value > this.props.midPoint) {
            count++;
          }
        }
      }
      result.push(count || '0');
    }
    return result;
  }

  render() {
    return <div id="heatmap" style={{ width: '99%' }} />;
  }
}
