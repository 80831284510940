import toast from 'react-hot-toast';
import { introjs } from '../IntroJs/Steps';

export const basicNavigationSteps = selectedFleet => {
  if (selectedFleet.pl !== '3') {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },
      {
        step: 1,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 2,
        element: '[data-intro="fleet-list"]',
        intro:
          'Here, you can view the fleets where your traction batteries are installed.',
      },
      {
        step: 3,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 5,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 6,
        element: '[data-intro="vehicle_product_list"]',
        intro:
          'You can see a list of the fleets vehicles and installed batteries here. ',
      },
      {
        step: 7,
        element: '[data-intro="search"]',
        intro: 'Use the search bar to find a specific vehicle or battery. ',
      },
      {
        step: 8,
        element: '[data-intro="filters"]',
        intro:
          'Filter the batteries based on their availability, reliability and utilization. ',
      },
      {
        step: 9,
        element: '[data-intro="overview"]',
        intro:
          'Gain a comprehensive view of your fleet by looking at the availability, reliability and utilization graphs.',
      },
      {
        step: 10,
        element: '[data-intro="vehicle_num_0"]',
        intro:
          'Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 11,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 12,
        element: '[data-intro="vehicle_product_00"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and long term operation, overall health. For more information on the battery-level graphs, refer to other available user guides.',
      },
      {
        step: 13,
        element: '[data-intro="email-alert"]',
        intro:
          'Set up alerts to ensure you dont miss any important events happening in your fleet.',
      },
      {
        step: 14,
        element: '[data-intro=""]',
        intro:
          'You are now in  email alerts view . You can set email alerts here',
      },
      {
        step: 15,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 16,
        element: '[data-intro=""]',
        intro:
          'Basic navigation ends here. For more information, you can refer glossary or other user guides.',
      },
    ];
  } else {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },
      {
        step: 1,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 2,
        element: '[data-intro="fleet-list"]',
        intro:
          'Here, you can view the fleets where your traction batteries are installed.',
      },
      {
        step: 3,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 5,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 6,
        element: '[data-intro="vehicle_product_list"]',
        intro:
          'You can see a list of the fleets vehicles and installed batteries here. ',
      },
      {
        step: 7,
        element: '[data-intro="search"]',
        intro: 'Use the search bar to find a specific vehicle or battery. ',
      },
      {
        step: 8,
        element: '[data-intro="filters"]',
        intro:
          'Filter the batteries based on their availability, reliability and utilization. ',
      },
      {
        step: 9,
        element: '[data-intro="overview"]',
        intro:
          'Gain a comprehensive view of your fleet by looking at the availability, reliability and utilization graphs.',
      },
      {
        step: 10,
        element: '[data-intro="vehicle_num_0"]',
        intro:
          'Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 11,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 12,
        element: '[data-intro="battery_num_click_0"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and long term operation, overall health. For more information on the battery-level graphs, refer to other available user guides.',
      },
      {
        step: 13,
        element: '[data-intro="string_num_0"]',
        intro:
          'Click on a product to see its latest operational status, as well as information on short and long term operation and overall health. For more information on the battery-level graphs, refer to other available user guides.',
      },
      {
        step: 14,
        element: '[data-intro="email-alert"]',
        intro:
          'Set up alerts to ensure you dont miss any important events happening in your fleet.',
      },
      {
        step: 15,
        element: '[data-intro=""]',
        intro:
          'You are now in  email alerts view . You can set email alerts here',
      },
      {
        step: 16,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 17,
        element: '[data-intro=""]',
        intro:
          'Basic navigation ends here. For more information, you can refer glossary or other user guides.',
      },
    ];
  }
};

export const basicNavStepChange = (step, selectedFleet) => {
  try {
    if (selectedFleet.pl !== '3') {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 4) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 5) {
        introjs.goToStep(6);
        introjs.start();
      }
      if (step === 10) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 11) {
        introjs.goToStep(12);
        introjs.start();
      }
      if (step === 14) {
        document.querySelector('[data-intro="email-alert"]').click();
      }
      if (step === 15) {
        introjs.goToStep(16);
        introjs.start();
      }
      if (step === 16) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    }
    if (selectedFleet.pl === '3') {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 4) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 5) {
        introjs.goToStep(6);
        introjs.start();
      }
      if (step === 10) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 11) {
        introjs.goToStep(12);
        introjs.start();
      }
      if (step === 13) {
        document.querySelector('[data-intro="battery_num_click_0"]').click();
      }
      if (step === 15) {
        document.querySelector('[data-intro="email-alert"]').click();
      }
      if (step === 16) {
        introjs.goToStep(17);
        introjs.start();
      }
      if (step === 17) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    }
  } catch (err) {
    toast.error('Error in user guide! Please start again.');
    introjs.exit(true);
  }
};
