export const tooltipData = {
  fleetTab: {
    fleetName: {
      tooltip: 'Enter Fleet name',
    },
    fleetId: {
      tooltip:
        'This is an auto generated ID. This shall be used as reference by the backend',
    },
    designedThroughput: {
      tooltip: `Designed Throughput Calculation:
 
      LifetimeThroughput : This is the total throughput supported by the given product (ESS/String)
      stringnumbers : Total number of strings supported by the Battery System (BS)
      Rated Voltage : Rated Voltage supported by the Battery System (BS)
      PerformanceWarrantyYrs : As being updated in product page (life time of Product)
      This designed throughput shall be calculated for a period of 1 month
      Therefore,
       
      Formulae for  Battery System
      designThroughput = (((lifeTimeThroughput*stringnumbers) * ratedVoltage) / 1000 ) /(8 yrs x 12 Months)
       
      For String specific Comparison:
      designThroughput = (((lifeTimeThroughput * 1) * ratedVoltage) / 1000 ) /(8 yrs x 12 Months)`,
    },
    maxThroughput: {
      tooltip: 'The maximum throughput, the Battery System can support in kwh',
    },
    conectivityType: {
      tooltip:
        'Please select the OEM specific Connectivity type to retrieve data from Source. Please ensure to have the linkage done for it to appear in dropdown menu. If not available, please contact ESS project admin',
    },
    platform: {
      tooltip: 'Please select the type of data associated w.r.t the fleet',
    },
    fleetDataPath: {
      tooltip:
        'Once the Connectivity type is selected, please enter the right location for that particular fleet',
    },
    slaStartDate: {
      tooltip:
        'Enter SLA Start Date here. This shall be basis for all report generation',
    },
  },
  vehicleTab: {
    vehicleType: {
      tooltip:
        'Please select the type of vehicle used for the given fleet. If fleet type is not available then please contact ESS admin to get it added.',
    },
    reliabilityThreshold: {
      tooltip:
        'A threshold ≥25% means that if the products within the vehicle exceed 25% of failure time based on the total operations time, the vehicle will be shown in a red reliability state.',
    },
    utilizationThreshold: {
      tooltip:
        'A threshold ≥25% means that if the products within the vehicle exceed 25% of time above normal utilization, the vehicle will be shown in a red utilization state.',
    },
  },
  productTab: {
    basicInformation: {
      batterySystemPrefix: {
        tooltip: `Number of years, this product is expected to work as per its design.
          This parameter value shall be used while calculating few KPIs w.r.t operational performance.
        `,
      },
      packPrefix: {
        tooltip: `The total Ampere-hour (Ah), this product is expected to support as per design.
          (Cumulative sum of charge, discharge for all cycles)
          `,
      },
    },
    specificationHeading: {
      designedLifetime: {
        tooltip: `Number of years, this product is expected to work as per its design.
          This parameter value shall be used while calculating few KPIs w.r.t operational performance.
        `,
      },
      lifetimeThroughput: {
        tooltip: `The total Ampere-hour (Ah), this product is expected to support as per design.
          (Cumulative sum of charge, discharge for all cycles)
          `,
      },

      capacityAfterDesignedLifetime: {
        tooltip:
          'The expected capacity of the battery after completion of the designed time when the product is operated under recommended conditions',
      },
      maximalCurrent: {
        tooltip:
          'The maximum current in Amps the ESS product of the fleet can support',
      },
    },
    thresholdSettings: {
      reliabilityThresholdForNotReliableState: {
        tooltip: `Above this defined threshold, the product is marked as not reliable. A threshold of "35%" means that if the product shows failure state in more than 35% of the operational time, the product is set to "not reliable". The reliability status includes the last 30 days of data.`,
      },
      utilizationThresholdForAboveNormalUtilizationState: {
        tooltip: `Above normal Utilization"; A threshold of "35%" means that if one or more of the KPIs below is exceeding the utilization threshold more than 35% of the operational time, the product is set to "Above normal Utilization". The utilization status includes the last 30 days of data.`,
      },
      normalTemperatureRange: {
        tooltip:
          'The minumum and maximum temperature, the ESS product of the fleet is recommended to operate for normal operations',
      },
      thresholdForSoC: {
        tooltip:
          'The minumum and maximum SoC, the ESS product of the fleet is recommended to operate for normal operations',
      },
      thresholdForCycles: {
        tooltip:
          'The maximum number of cycles, the ESS product is recommended to operate per day ',
      },
      thresholdForCRate: {
        tooltip:
          'The maximum C-rate at which the ESS product is recommended to operate  ',
      },
    },
    converterSettings: {
      converterPrefix: {
        tooltip: `The Traction converter products under the current fleet shall have the prefix which is being mentioned here. The prefix shall be followed by IP address of the traction converter unit.`,
      },
      converterEnable: {
        tooltip: `This will enable/disable traction converter as product to be available under products list of a Vehicle and to display diagnostics data under Fleet View, Vehicle View and at Asset View.`,
      },
    },
  },
};
