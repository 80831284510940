import {
  ADD_OVERLAYTRENDSIGNAL,
  CLEAR_BATTERY_OVERVIEW_DATA,
  CLEAR_CHARTDATA,
  CLEAR_DETAILVIEW_TIMETREND,
  FETCH_BATTERY_OVERALL_CONDITION_DATA,
  FETCH_BATTERY_OVERALL_CONDITION_DATA_FAILURE,
  FETCH_BATTERY_OVERALL_CONDITION_DATA_SUCCESS,
  FETCH_BATTERY_OVERVIEW_DATA,
  FETCH_BATTERY_OVERVIEW_DATA_FAILURE,
  FETCH_BATTERY_OVERVIEW_DATA_SUCCESS,
  FETCH_BATTERY_REL_DATA,
  FETCH_BATTERY_REL_DATA_FAILURE,
  FETCH_BATTERY_REL_DATA_SUCCESS,
  FETCH_BATTERY_SOC_DATA,
  FETCH_BATTERY_SOC_DATA_FAILURE,
  FETCH_BATTERY_SOC_DATA_SUCCESS,
  FETCH_BATTERY_SOC_RANGE_DATA,
  FETCH_BATTERY_SOC_RANGE_DATA_FAILURE,
  FETCH_BATTERY_SOC_RANGE_DATA_SUCCESS,
  FETCH_BATTERY_STR_DATA,
  FETCH_BATTERY_STR_DATA_FAILURE,
  FETCH_BATTERY_STR_DATA_SUCCESS,
  FETCH_BOXPLOT_DATA,
  FETCH_BOXPLOT_DATA_FAILURE,
  FETCH_BOXPLOT_DATA_SUCCESS,
  FETCH_CELL_VOLTAGE_DATA,
  FETCH_CELL_VOLTAGE_DATA_FAILURE,
  FETCH_CELL_VOLTAGE_DATA_SUCCESS,
  FETCH_HISTOGRAM_DATA,
  FETCH_HISTOGRAM_DATA_FAILURE,
  FETCH_HISTOGRAM_DATA_SUCCESS,
  FETCH_OPERATING_HOURS_HISTOGRAM_DATA,
  FETCH_OPERATING_HOURS_HISTOGRAM_DATA_FAILURE,
  FETCH_OPERATING_HOURS_HISTOGRAM_DATA_SUCCESS,
  FETCH_SOH_DATA,
  FETCH_SOH_DATA_FAILURE,
  FETCH_SOH_DATA_SUCCESS,
  FETCH_SYNC_GRAPH_DATA,
  FETCH_SYNC_GRAPH_DATA_FAILURE,
  FETCH_SYNC_GRAPH_DATA_SUCCESS,
  FETCH_TEMPERATURES_HISTOGRAM_DATA,
  FETCH_TEMPERATURES_HISTOGRAM_DATA_FAILURE,
  FETCH_TEMPERATURES_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGDOD_HEATMAP_DATA,
  FETCH_USGDOD_HEATMAP_DATA_FAILURE,
  FETCH_USGDOD_HEATMAP_DATA_SUCCESS,
  FETCH_USGDOD_HISTOGRAM_DATA,
  FETCH_USGDOD_HISTOGRAM_DATA_FAILURE,
  FETCH_USGDOD_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGPOW_HEATMAP_DATA,
  FETCH_USGPOW_HEATMAP_DATA_FAILURE,
  FETCH_USGPOW_HEATMAP_DATA_SUCCESS,
  FETCH_USGPOW_HISTOGRAM_DATA,
  FETCH_USGPOW_HISTOGRAM_DATA_FAILURE,
  FETCH_USGPOW_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGSOC_HEATMAP_DATA,
  FETCH_USGSOC_HEATMAP_DATA_FAILURE,
  FETCH_USGSOC_HEATMAP_DATA_SUCCESS,
  FETCH_USGSOC_HISTOGRAM_DATA,
  FETCH_USGSOC_HISTOGRAM_DATA_FAILURE,
  FETCH_USGSOC_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGTEMP_HEATMAP_DATA,
  FETCH_USGTEMP_HEATMAP_DATA_FAILURE,
  FETCH_USGTEMP_HEATMAP_DATA_SUCCESS,
  GET_DETAILVIEW_TIMETREND,
  GET_DETAILVIEW_TIMETREND_FAILURE,
  GET_DETAILVIEW_TIMETREND_SUCCESS,
  GET_TT_AVGCELLV_SUCCESS,
  GET_TT_AVGTEMP_SUCCESS,
  GET_TT_BCCHARGE_SUCCESS,
  GET_TT_BCDISCHARGE_SUCCESS,
  GET_TT_BCURRENT_SUCCESS,
  GET_TT_BOLCOUNTER_SUCCESS,
  GET_TT_BPCHARGE_SUCCESS,
  GET_TT_BPDISCHARGE_SUCCESS,
  GET_TT_BVOLTAGE_SUCCESS,
  GET_TT_DIAGNOSTIC_SUCCESS,
  GET_TT_CONTACTORSTATUS_SUCCESS,
  GET_TT_FEEDFLOW_SUCCESS,
  GET_TT_OVERLOADCOUNTER_SUCCESS,
  GET_TT_POWER_SUCCESS,
  GET_TT_RELIABILITY_SUCCESS,
  GET_TT_RETURNFLOW_SUCCESS,
  GET_TT_SOC_SUCCESS,
  SET_MAINSIGNAL,
  SET_MAINSIGNAL1,
  SET_MAINSIGNAL2,
  SET_MAINSIGNAL3,
  SET_MAINTRENDSIGNAL,
  SET_OVERLAYSIGNAL,
  SET_OVERLAYSIGNAL1,
  SET_OVERLAYSIGNAL2,
  SET_OVERLAYSIGNAL3,
  SET_RADIO_VALUE,
  SET_SYNCCHART,
  SET_SYNCCHARTDATA,
  SET_TIMETREND_LOADING,
} from './constants';

export const setRadioButtonValue = value => ({
  type: SET_RADIO_VALUE,
  payload: value,
});

// Battery overall condition
export const fetchBatteryOverallConditionData = (
  sitename,
  fleetname,
  vehicleName,
  pecUniqueSerialNumber
) => ({
  type: FETCH_BATTERY_OVERALL_CONDITION_DATA,
  payload: { sitename, fleetname, vehicleName, pecUniqueSerialNumber },
});

export const fetchBatteryOverallConditionDataSucess =
  batteryOverallConditionData => ({
    type: FETCH_BATTERY_OVERALL_CONDITION_DATA_SUCCESS,
    batteryOverallConditionData,
  });

export const fetchBatteryOverallConditionDataFailure = error => ({
  type: FETCH_BATTERY_OVERALL_CONDITION_DATA_FAILURE,
  error,
});

// Cell Volatage data
export const fetchCellVoltageData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate
) => ({
  type: FETCH_CELL_VOLTAGE_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate },
});

export const fetchCellVoltageDataSucess = cellVoltageData => ({
  type: FETCH_CELL_VOLTAGE_DATA_SUCCESS,
  cellVoltageData,
});

export const fetchCellVoltageDataFailure = error => ({
  type: FETCH_CELL_VOLTAGE_DATA_FAILURE,
  error,
});

// State of charge data
export const fetchBatteryStateOfChargeData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate
) => ({
  type: FETCH_BATTERY_SOC_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate },
});

export const fetchBatteryStateOfChargeDataSucess =
  batteryStateOfChargeData => ({
    type: FETCH_BATTERY_SOC_DATA_SUCCESS,
    batteryStateOfChargeData,
  });

export const fetchBatteryStateOfChargeDataFailure = error => ({
  type: FETCH_BATTERY_SOC_DATA_FAILURE,
  error,
});

// SOC Range data
export const fetchBatterySOCRangeData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_BATTERY_SOC_RANGE_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchBatterySOCRangeDataSucess = batterySOCRangeData => ({
  type: FETCH_BATTERY_SOC_RANGE_DATA_SUCCESS,
  batterySOCRangeData,
});

export const fetchBatterySOCRangeDataFailure = error => ({
  type: FETCH_BATTERY_SOC_RANGE_DATA_FAILURE,
  error,
});

/// /////////////////////////////////////////////////////////////////////////////////////TEMPERATURES Histogram

export const fetchTemperatureHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_TEMPERATURES_HISTOGRAM_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchTemperatureHistogramDataSucess =
  temperatureHistogramData => ({
    type: FETCH_TEMPERATURES_HISTOGRAM_DATA_SUCCESS,
    temperatureHistogramData,
  });

export const fetchTemperatureHistogramDataFailure = error => ({
  type: FETCH_TEMPERATURES_HISTOGRAM_DATA_FAILURE,
  error,
});

/// /////////////////////////////////////////////////////////////////////////////////////USGSOC Histogram

export const fetchUsgSocHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_USGSOC_HISTOGRAM_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchUsgSocHistogramDataSucess = usgSocHistogramData => ({
  type: FETCH_USGSOC_HISTOGRAM_DATA_SUCCESS,
  usgSocHistogramData,
});

export const fetchUsgSocHistogramDataFailure = error => ({
  type: FETCH_USGSOC_HISTOGRAM_DATA_FAILURE,
  error,
});

/// /////////////////////////////////////////////////////////////////////////////////////USGPOW Histogram

export const fetchUsgPowHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_USGPOW_HISTOGRAM_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchUsgPowHistogramDataSucess = usgPowHistogramData => ({
  type: FETCH_USGPOW_HISTOGRAM_DATA_SUCCESS,
  usgPowHistogramData,
});

export const fetchUsgPowHistogramDataFailure = error => ({
  type: FETCH_USGPOW_HISTOGRAM_DATA_FAILURE,
  error,
});

/// /////////////////////////////////////////////////////////////////////////////////////USGDOD Histogram

export const fetchUsgDodHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_USGDOD_HISTOGRAM_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchUsgDodHistogramDataSucess = usgDodHistogramData => ({
  type: FETCH_USGDOD_HISTOGRAM_DATA_SUCCESS,
  usgDodHistogramData,
});

export const fetchUsgDodHistogramDataFailure = error => ({
  type: FETCH_USGDOD_HISTOGRAM_DATA_FAILURE,
  error,
});
/// ///////////////////////////////////////////////////////////////////////////

export const fetchUsgDodHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_USGDOD_HEATMAP_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchUsgDodHeatmapDataSucess = usgDodHeatmapData => ({
  type: FETCH_USGDOD_HEATMAP_DATA_SUCCESS,
  usgDodHeatmapData,
});

export const fetchUsgDodHeatmapDataFailure = error => ({
  type: FETCH_USGDOD_HEATMAP_DATA_FAILURE,
  error,
});

export const fetchUsgPowHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_USGPOW_HEATMAP_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchUsgPowHeatmapDataSucess = usgPowHeatmapData => ({
  type: FETCH_USGPOW_HEATMAP_DATA_SUCCESS,
  usgPowHeatmapData,
});

export const fetchUsgPowHeatmapDataFailure = error => ({
  type: FETCH_USGPOW_HEATMAP_DATA_FAILURE,
  error,
});

export const fetchUsgSocHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_USGSOC_HEATMAP_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchUsgSocHeatmapDataSucess = usgSocHeatmapData => ({
  type: FETCH_USGSOC_HEATMAP_DATA_SUCCESS,
  usgSocHeatmapData,
});

export const fetchUsgSocHeatmapDataFailure = error => ({
  type: FETCH_USGSOC_HEATMAP_DATA_FAILURE,
  error,
});

export const fetchUsgTempHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_USGTEMP_HEATMAP_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchUsgTempHeatmapDataSucess = usgTempHeatmapData => ({
  type: FETCH_USGTEMP_HEATMAP_DATA_SUCCESS,
  usgTempHeatmapData,
});

export const fetchUsgTempHeatmapDataFailure = error => ({
  type: FETCH_USGTEMP_HEATMAP_DATA_FAILURE,
  error,
});

/// /////////////////////////////////////////////////////////////////////////// oPERATING HOURS HISTOGRAM

export const fetchOperatingHoursHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) => ({
  type: FETCH_OPERATING_HOURS_HISTOGRAM_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype },
});

export const fetchOperatingHoursHistogramDataSucess =
  operatingHoursHistogramData => ({
    type: FETCH_OPERATING_HOURS_HISTOGRAM_DATA_SUCCESS,
    operatingHoursHistogramData,
  });

export const fetchOperatingHoursHistogramDataFailure = error => ({
  type: FETCH_OPERATING_HOURS_HISTOGRAM_DATA_FAILURE,
  error,
});

export const fetchHistogramData = () => ({
  type: FETCH_HISTOGRAM_DATA,
});

export const fetchHistogramDataSucess = histogramData => ({
  type: FETCH_HISTOGRAM_DATA_SUCCESS,
  histogramData,
});

export const fetchHistogramDataFailure = error => ({
  type: FETCH_HISTOGRAM_DATA_FAILURE,
  error,
});

// BoxPlot
export const fetchBoxPlotData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  docType
) => ({
  type: FETCH_BOXPLOT_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate, docType },
});

export const fetchBoxPlotDataSucess = boxPlotData => ({
  type: FETCH_BOXPLOT_DATA_SUCCESS,
  boxPlotData,
});

export const fetchBoxPlotDataFailure = error => ({
  type: FETCH_BOXPLOT_DATA_FAILURE,
  error,
});

export const clearChartData = () => ({
  type: CLEAR_CHARTDATA,
});

export const clearOverviewata = () => ({
  type: CLEAR_BATTERY_OVERVIEW_DATA,
});

// Battery overview
export const fetchBatteryOverviewData = (
  vehicleName,
  pecUniqueSerialNumber,
  datetype
) => ({
  type: FETCH_BATTERY_OVERVIEW_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, datetype },
});

export const fetchBatteryOverviewDataSucess = batteryOverviewData => ({
  type: FETCH_BATTERY_OVERVIEW_DATA_SUCCESS,
  batteryOverviewData,
});

export const fetchBatteryOverviewDataFailure = error => ({
  type: FETCH_BATTERY_OVERVIEW_DATA_FAILURE,
  error,
});

// synched chart
export const fetchSyncGraphData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate
) => ({
  type: FETCH_SYNC_GRAPH_DATA,
  payload: { vehicleName, pecUniqueSerialNumber, startDate, endDate },
});

export const fetchSyncGraphDataSucess = syncGraphData => ({
  type: FETCH_SYNC_GRAPH_DATA_SUCCESS,
  syncGraphData,
});

export const fetchSyncGraphDataFailure = error => ({
  type: FETCH_SYNC_GRAPH_DATA_FAILURE,
  error,
});

export const setMainSignal1 = trend => ({
  type: SET_MAINSIGNAL1,
  payload: { trend },
});

export const setMainSignal2 = trend => ({
  type: SET_MAINSIGNAL2,
  payload: { trend },
});

export const setMainSignal3 = trend => ({
  type: SET_MAINSIGNAL3,
  payload: { trend },
});

export const setOverlaySignal1 = trend => ({
  type: SET_OVERLAYSIGNAL1,
  payload: { trend },
});

export const setOverlaySignal2 = trend => ({
  type: SET_OVERLAYSIGNAL2,
  payload: { trend },
});

export const setOverlaySignal3 = trend => ({
  type: SET_OVERLAYSIGNAL3,
  payload: { trend },
});

export const setMainSignal = trend => ({
  type: SET_MAINSIGNAL,
  payload: { trend },
});

export const setOverlaySignal = trend => ({
  type: SET_OVERLAYSIGNAL,
  payload: { trend },
});

export const setSyncChart = trend => ({
  type: SET_SYNCCHART,
  payload: { trend },
});

export const setSyncChartData = trend => ({
  type: SET_SYNCCHARTDATA,
  payload: { trend },
});

// TimeTrend
export const fetchDetailViewTimetrend = (
  id,
  startDate,
  endDate,
  docType,
  bogie,
  datetype
) => ({
  type: GET_DETAILVIEW_TIMETREND,
  payload: { id, startDate, endDate, docType, bogie, datetype },
});

export const getDetailViewTimetrendSuccess = payload => ({
  type: GET_DETAILVIEW_TIMETREND_SUCCESS,
  payload,
});

export const getTtAvgCellVSuccess = payload => ({
  type: GET_TT_AVGCELLV_SUCCESS,
  payload,
});
export const getTtAvgTempSuccess = payload => ({
  type: GET_TT_AVGTEMP_SUCCESS,
  payload,
});
export const getTtSOCSuccess = payload => ({
  type: GET_TT_SOC_SUCCESS,
  payload,
});
export const getTtPowerSuccess = payload => ({
  type: GET_TT_POWER_SUCCESS,
  payload,
});
export const getTtBVoltageSuccess = payload => ({
  type: GET_TT_BVOLTAGE_SUCCESS,
  payload,
});
export const getTtBCurrentSuccess = payload => ({
  type: GET_TT_BCURRENT_SUCCESS,
  payload,
});

export const getTtBPChargeSuccess = payload => ({
  type: GET_TT_BPCHARGE_SUCCESS,
  payload,
});
export const getTtBPDisChargeSuccess = payload => ({
  type: GET_TT_BPDISCHARGE_SUCCESS,
  payload,
});
export const getTtBCChargeSuccess = payload => ({
  type: GET_TT_BCCHARGE_SUCCESS,
  payload,
});
export const getTtBCDisChargeSuccess = payload => ({
  type: GET_TT_BCDISCHARGE_SUCCESS,
  payload,
});
export const getTtBOLCounterSuccess = payload => ({
  type: GET_TT_BOLCOUNTER_SUCCESS,
  payload,
});
export const getTtReliabilitySuccess = payload => ({
  type: GET_TT_RELIABILITY_SUCCESS,
  payload,
});
export const getTtFeedflowSuccess = payload => ({
  type: GET_TT_FEEDFLOW_SUCCESS,
  payload,
});
export const getTtOverloadcounterSuccess = payload => ({
  type: GET_TT_OVERLOADCOUNTER_SUCCESS,
  payload,
});
export const getTtReturnflowSuccess = payload => ({
  type: GET_TT_RETURNFLOW_SUCCESS,
  payload,
});
export const getTtDiagnosticEventSuccess = payload => ({
  type: GET_TT_DIAGNOSTIC_SUCCESS,
  payload,
});
export const getTtContactorStatusSuccess = payload => ({
  type: GET_TT_CONTACTORSTATUS_SUCCESS,
  payload,
});

export const getDetailViewTimetrendFailure = error => ({
  type: GET_DETAILVIEW_TIMETREND_FAILURE,
  error,
});

export const clearDetailViewTimetrend = payload => ({
  type: CLEAR_DETAILVIEW_TIMETREND,
  payload,
});

export const setMainTrendSignal = trend => ({
  type: SET_MAINTRENDSIGNAL,
  payload: { trend },
});

export const addOverlayTrendSignal = payload => ({
  type: ADD_OVERLAYTRENDSIGNAL,
  payload,
});

export const setTimetrendLoading = payload => ({
  type: SET_TIMETREND_LOADING,
  payload,
});

// Battery Reliability data
export const fetchBatteryRelData = (vehicleName, pecUniqueSerialNumber) => ({
  type: FETCH_BATTERY_REL_DATA,
  payload: { vehicleName, pecUniqueSerialNumber },
});

export const fetchBatteryRelDataSucess = batteryRelData => ({
  type: FETCH_BATTERY_REL_DATA_SUCCESS,
  batteryRelData,
});

export const fetchBatteryRelDataFailure = error => ({
  type: FETCH_BATTERY_REL_DATA_FAILURE,
  error,
});

// Battery Stress data
export const fetchBatteryStrData = (vehicleName, pecUniqueSerialNumber) => ({
  type: FETCH_BATTERY_STR_DATA,
  payload: { vehicleName, pecUniqueSerialNumber },
});

export const fetchBatteryStrDataSucess = batteryStrData => ({
  type: FETCH_BATTERY_STR_DATA_SUCCESS,
  batteryStrData,
});

export const fetchBatteryStrDataFailure = error => ({
  type: FETCH_BATTERY_STR_DATA_FAILURE,
  error,
});

// SOH data
export const fetchSOHData = (fleetName, bPUniqueSerialNumber, scuID) => ({
  type: FETCH_SOH_DATA,
  payload: { fleetName, bPUniqueSerialNumber, scuID },
});

export const fetchSOHDataSucess = sohData => ({
  type: FETCH_SOH_DATA_SUCCESS,
  sohData,
});

export const fetchSOHDataFailure = error => ({
  type: FETCH_SOH_DATA_FAILURE,
  error,
});
