import 'bootstrap-daterangepicker/daterangepicker.css';
import Moment from 'moment';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { DropdownList, Multiselect } from 'react-widgets';
import { createStructuredSelector } from 'reselect';
import {
  Addduplicate,
  CaretDown,
  DeleteIcon,
  Edit,
  Rightmark,
} from '../../assets/images';
import { fetchVehiclesList } from '../../containers/FleetView/actions';
import {
  loadingVehiclesSelector,
  vehiclesListDataSelector,
} from '../../containers/FleetView/selectors';
import Table from '../EmailAlertTable';
import { Confirmdialog } from '../EmailalertPopup';
import Loading from '../Loading';
import './styles.scss';

class EmailAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      essNameList: [],
      alertTypeList: ['Error', 'Warning', 'Information'],
      alertFrequencyList: ['Daily', 'Weekly', 'Monthly', 'Quarterly'],
      alertValidateList: ['1 Month', '3 Months', '6 Months', '1 Year'],
      n_name: '',
      n_additionalinfo: '',
      n_alerttype: ['Error', 'Warning', 'Information'],
      n_alertfreq: 'Daily',
      n_time: '1 Year',
      n_status: 'active',
      n_un: '',
      editName: '',
      e_name: '',
      e_additionalinfo: '',
      e_alerttype: [],
      e_alertfreq: '',
      e_time: '',
      e_status: '',
      e_un: '',
      dialogshow: false,
      dialogmsg: '',
      productList: [],
      tempdialogshow: false,
    };
  }

  componentDidMount() {
    this.props.vehiclesListData === null &&
      this.props.getVehiclesList(
        sessionStorage.getItem('selectedSite'),
        sessionStorage.getItem('selectedFleet')
      );
    this.setState({ tempdialogshow: false });

    if (sessionStorage.getItem('selectedVehicle') === 'null') {
      this.setState({ n_name: 'All' });
    } else if (sessionStorage.getItem('selectedVehicleProduct') !== 'null') {
      this.setState({ n_name: sessionStorage.getItem('selectedProductDname') });
    }
    this.setFilterValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.vehiclesListData !== prevProps.vehiclesListData) {
      this.setFilterValues();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (
      this.state.tempdialogshow === true &&
      this.props.emailalertSettingList !== null
    ) {
      if (this.props.emailalertSave !== null) {
        this.setState({
          dialogshow: true,
          dialogmsg: this.props.emailalertSave,
          tempdialogshow: false,
        });
      }
    }
  }

  setFilterValues = () => {
    if (this.props.vehiclesListData !== null) {
      const essNameList = this.props.vehiclesListData[0].vst.reduce(
        (acc, vehicle) => {
          return acc.concat(
            vehicle.vdat.reduce((acc, product) => {
              if (!product.dn.includes('TC')) {
                return acc.concat(product.dn);
              }
              return acc;
            }, [])
          );
        },
        ['All']
      );
      this.setState({ essNameList });
    }
  };

  dialogshow = async (val, msg) => {
    if (msg === 'Add new alert setting') {
      if (
        this.state.n_name === '' ||
        this.state.n_name === null ||
        this.state.n_alerttype.length === 0 ||
        this.state.n_alertfreq === '' ||
        this.state.n_time === '' ||
        this.state.n_alerttype.length === 0
      ) {
        this.setState({
          dialogshow: true,
          dialogmsg: 'Please enter all mandatory fields',
        });
        return;
      } else {
        await this.props.saveEmailalertSetting(
          this.state.n_name,
          this.state.n_additionalinfo,
          this.state.n_alerttype,
          this.state.n_alertfreq,
          this.state.n_time,
          this.state.n_status,
          this.state.n_un,
          'new'
        );
        this.setState({
          n_name: '',
          n_additionalinfo: '',
          n_alerttype: ['Error', 'Warning', 'Information'],
          n_alertfreq: 'Daily',
          n_time: '1 Year',
          n_status: 'active',
          n_un: '',
          tempdialogshow: true,
        });
      }
    }
    if (msg === 'Edit alert settings') {
      if (
        this.state.e_name !== '' &&
        this.state.e_alerttype.length === 0 &&
        this.state.e_alertfreq !== '' &&
        this.state.e_time !== ''
      ) {
        await this.props.saveEmailalertSetting(
          this.state.e_name,
          this.state.e_additionalinfo,
          this.state.e_alerttype,
          this.state.e_alertfreq,
          this.state.e_time,
          this.state.e_status,
          this.state.e_un,
          'edit'
        );
        this.setState({
          e_name: '',
          e_additionalinfo: '',
          e_alerttype: '',
          e_alertfreq: '',
          e_time: '',
          e_status: '',
          e_un: '',
          tempdialogshow: true,
        });
      }
    }

    if (msg === 'Delete alert settings') {
      if (
        this.state.e_name !== '' &&
        this.state.e_alerttype.length !== 0 &&
        this.state.e_alertfreq !== '' &&
        this.state.e_time !== ''
      ) {
        await this.props.saveEmailalertSetting(
          this.state.e_name,
          this.state.e_additionalinfo,
          this.state.e_alerttype,
          this.state.e_alertfreq,
          this.state.e_time,
          'false',
          this.state.e_un,
          'delete'
        );
        this.setState({
          e_name: '',
          e_additionalinfo: '',
          e_alerttype: '',
          e_alertfreq: '',
          e_time: '',
          e_status: '',
          e_un: '',
          tempdialogshow: true,
        });
      }
    }

    if (msg === 'Status alert settings') {
      if (
        this.state.e_name !== '' &&
        this.state.e_alerttype.length !== 0 &&
        this.state.e_alertfreq !== '' &&
        this.state.e_time !== ''
      ) {
        await this.props.saveEmailalertSetting(
          this.state.e_name,
          this.state.e_additionalinfo,
          this.state.e_alerttype,
          this.state.e_alertfreq,
          this.state.e_time,
          this.state.e_status,
          this.state.e_un,
          'status_change'
        );
        this.setState({
          editName: '',
          e_name: '',
          e_additionalinfo: '',
          e_alerttype: '',
          e_alertfreq: '',
          e_time: '',
          e_status: '',
          e_un: '',
          tempdialogshow: true,
        });
      }
    }

    this.setState({ dialogshow: val, dialogmsg: msg });
  };

  nameValue = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    return (
      <DropdownList
        data={this.state.essNameList}
        value={this.state.n_name}
        className="namedropdown"
        onChange={e => {
          this.setState({
            n_name: e,
            editName: '',
          });
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  additionalinfoValue = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <input
        value={this.state.n_additionalinfo}
        className="additionalinfo"
        onChange={e => {
          this.setState({
            n_additionalinfo: e.target.value,
            editName: '',
          });
        }}
        placeholder={`Optional`}
      />
    );
  };

  alertTypeValue = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <Multiselect
        data={this.state.alertTypeList}
        value={this.state.n_alerttype}
        className="alerttypedropdown"
        onChange={e => {
          this.setState({
            n_alerttype: e,
            editName: '',
          });
        }}
      />
    );
  };

  alertFrequencyValue = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <DropdownList
        data={this.state.alertFrequencyList}
        // defaultValue={"All"}
        value={this.state.n_alertfreq}
        className="alertfreqdropdown"
        onChange={e => {
          this.setState({
            n_alertfreq: e,
            editName: '',
          });
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  timeValue = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    return (
      <DropdownList
        data={this.state.alertValidateList}
        // defaultValue={"All"}
        value={this.state.n_time}
        className="alertfreqdropdown"
        onChange={e => {
          this.setState({
            n_time: e,
            editName: '',
          });
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  n_handleDateCallback = (start, end, label) => {
    this.setState({
      n_time:
        Moment(start).format('DD.MM.YYYY') +
        '-' +
        Moment(end).format('DD.MM.YYYY'),
    });
  };

  e_handleDateCallback = (start, end, label) => {
    this.setState({
      e_time:
        Moment(start).format('DD.MM.YYYY') +
        '-' +
        Moment(end).format('DD.MM.YYYY'),
    });
  };

  actionValue = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <Button
        className="btn btn-sm actionbtn"
        onClick={() => {
          this.dialogshow(false, 'Add new alert setting');
        }}>
        Add new settings
      </Button>
    );
  };

  render() {
    const columns = [
      {
        id: 'en',
        Header: 'ESS NAME',
        accessor: 'en',
        Filter: this.nameValue,
        filter: 'includes',
        Cell: ({ cell }) => {
          if (this.state.editName === cell.row.values.un) {
            return (
              <DropdownList
                data={this.state.essNameList}
                value={this.state.e_name}
                className="namedropdown"
                onChange={e => {
                  this.setState({
                    e_name: e,
                  });
                }}
                selectIcon={<CaretDown />}
              />
            );
          } else {
            return cell.row.values.en;
          }
        },
      },
      {
        id: 'ai',
        Header: 'ADDITIONAL INFORMATION',
        accessor: 'ai',
        Filter: this.additionalinfoValue,
        filter: 'includes',
        Cell: ({ cell }) => {
          if (this.state.editName === cell.row.values.un) {
            return (
              <input
                value={this.state.e_additionalinfo}
                className="additionalinfo"
                onChange={e => {
                  this.setState({
                    e_additionalinfo: e.target.value,
                  });
                }}
                autoFocus
              />
            );
          } else {
            return cell.row.values.ai;
          }
        },
      },
      {
        id: 'at',
        Header: 'ALERT TYPE',
        accessor: 'at',
        Filter: this.alertTypeValue,
        filter: 'includes',
        Cell: ({ cell }) => {
          if (this.state.editName === cell.row.values.un) {
            return (
              <Multiselect
                data={this.state.alertTypeList}
                value={this.state.e_alerttype}
                className="alerttypedropdown"
                onChange={e => {
                  this.setState({
                    e_alerttype: e,
                  });
                }}
              />
            );
          } else {
            let res = '';
            cell.row.values.at.forEach(item => {
              res += item + ', ';
            });
            return res;
          }
        },
      },
      {
        id: 'af',
        Header: 'ALERT FREQUENCY',
        accessor: 'af',
        Filter: this.alertFrequencyValue,
        filter: 'includes',
        Cell: ({ cell }) => {
          if (this.state.editName === cell.row.values.un) {
            return (
              <DropdownList
                data={this.state.alertFrequencyList}
                value={this.state.e_alertfreq}
                className="alertfreqdropdown"
                onChange={e => {
                  this.setState({
                    e_alertfreq: e,
                  });
                }}
                selectIcon={<CaretDown />}
              />
            );
          } else {
            return cell.row.values.af;
          }
        },
      },
      {
        id: 'vt',
        Header: 'ALERT VALIDITY',
        accessor: 'vt',
        Filter: this.timeValue,
        filter: 'includes',
        Cell: ({ cell }) => {
          if (this.state.editName === cell.row.values.un) {
            return (
              <DropdownList
                data={this.state.alertValidateList}
                value={this.state.e_time}
                className="alertfreqdropdown"
                onChange={e => {
                  this.setState({
                    e_time: e,
                  });
                }}
                selectIcon={<CaretDown />}
              />
            );
          } else {
            return cell.row.values.vt;
          }
        },
      },
      {
        id: 'sts',
        Header: 'ACTION',
        accessor: 'sts',
        Filter: this.actionValue,
        filter: 'includes',
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                variant="link"
                className="btn btn-link btn-sm editbutton"
                onClick={e => {
                  if (this.state.editName !== cell.row.values.un) {
                    this.setState({
                      editName: cell.row.values.un,
                      e_name: cell.row.values.en,
                      e_additionalinfo: cell.row.values.ai,
                      e_alerttype: cell.row.values.at,
                      e_alertfreq: cell.row.values.af,
                      e_time: cell.row.values.vt,
                      e_status: cell.row.values.sts,
                      e_un: cell.row.values.un,
                    });
                  } else {
                    this.dialogshow(false, 'Edit alert settings');
                    this.setState({
                      editName: '',
                      e_name: '',
                      e_additionalinfo: '',
                      e_alerttype: [],
                      e_alertfreq: '',
                      e_time: '',
                      e_status: '',
                      e_un: '',
                    });
                  }
                }}>
                {this.state.editName === cell.row.values.un ? (
                  <Rightmark title="Apply changes" />
                ) : (
                  <Edit title="Edit" />
                )}
              </Button>
              <Button
                variant="link"
                className="btn btn-link btn-sm editbutton"
                onClick={e => {
                  this.setState({
                    n_name: cell.row.values.en,
                    n_additionalinfo: cell.row.values.ai,
                    n_alerttype: cell.row.values.at,
                    n_alertfreq: cell.row.values.af,
                    n_time: cell.row.values.vt,
                  });
                }}>
                <Addduplicate title="Duplicate" />
              </Button>
              <Button
                variant="link"
                className="btn btn-link btn-sm editbutton"
                onClick={e => {
                  this.setState(
                    {
                      e_name: cell.row.values.en,
                      e_additionalinfo: cell.row.values.ai,
                      e_alerttype: cell.row.values.at,
                      e_alertfreq: cell.row.values.af,
                      e_time: cell.row.values.vt,
                      e_status: cell.row.values.sts,
                      e_un: cell.row.values.un,
                    },
                    () => this.dialogshow(false, 'Delete alert settings')
                  );
                }}>
                <DeleteIcon title="Delete" />
              </Button>

              <Form.Check
                className="togglebtn"
                type="switch"
                id={'cust-switch' + cell.row.values.un}
                label={cell.row.values.sts === 'active' ? 'ON' : 'OFF'}
                defaultChecked={cell.row.values.sts === 'active'}
                onClick={e => {
                  this.setState(
                    {
                      // editName: cell.row.values.un,
                      e_name: cell.row.values.en,
                      e_additionalinfo: cell.row.values.ai,
                      e_alerttype: cell.row.values.at,
                      e_alertfreq: cell.row.values.af,
                      e_time: cell.row.values.vt,
                      e_status:
                        cell.row.values.sts === 'active'
                          ? 'inactive'
                          : 'active',
                      e_un: cell.row.values.un,
                    },
                    () => this.dialogshow(false, 'Status alert settings')
                  );
                }}
              />
            </>
          );
        },
      },
      {
        id: 'un',
        Header: 'UN',
        accessor: 'un',
        Filter: '',
        filter: '',
      },
    ];

    // this.setFilterValues();

    return (
      <Row className="emailalertsetting">
        {this.props.loadingEmailalertSetting === true ||
        this.props.emailalertSettingList === null ? (
          <Col>
            <Loading loadintText="Loading data" />
          </Col>
        ) : (
          <Col>
            <Table
              columns={columns}
              data={this.props.emailalertSettingList[0].dpc}
            />
            <Confirmdialog
              dialogshow={this.state.dialogshow}
              dialogmsg={this.state.dialogmsg}
              handlefunc={this.dialogshow}
            />
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loadingVehicles: loadingVehiclesSelector(),
  vehiclesListData: vehiclesListDataSelector(),
});

const mapDispatchToProps = {
  getVehiclesList: fetchVehiclesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailAlert);
