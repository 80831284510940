import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  fetchBoxPlotDataFailure,
  fetchBoxPlotDataSucess,
  fetchDiagnosticListFailure,
  fetchDiagnosticListSuccess,
  fetchFleetStatisticsDataFailure,
  fetchFleetStatisticsDataSuccess,
  fetchFleetUsageFailure,
  fetchFleetUsageSuccess,
  fetchFleetsListFailure,
  fetchFleetsListSucess,
  fetchHistogramDataFailure,
  fetchHistogramDataSucess,
  fetchModuleListFailure,
  fetchModuleListSucess,
  fetchOverallConditionDataFailure,
  fetchOverallConditionDataSucess,
  fetchOverviewDataFailure,
  fetchOverviewDataSucess,
  fetchUnmountedESSListFailure,
  fetchUnmountedESSListSucess,
  fetchVehicleProductListFailure,
  fetchVehicleProductListSucess,
  fetchVehiclesListFailure,
  fetchVehiclesListSucess,
} from './actions';
import {
  FETCH_BOXPLOT_DATA,
  FETCH_DIAGNOSTICLIST,
  FETCH_FLEETSLIST,
  FETCH_FLEET_STATISTICS_DATA,
  FETCH_FLEET_USAGE_DETAILS,
  FETCH_HISTOGRAM_DATA,
  FETCH_MODULELIST,
  FETCH_OVERALL_CONDITION_DATA,
  FETCH_OVERVIEW_DATA,
  FETCH_UNMOUNTEDESS_LIST,
  FETCH_VEHICLEPRODUCTLIST,
  FETCH_VEHICLESLIST,
} from './constants';

export function* fetchOverallConditionSaga() {
  yield takeLatest(FETCH_OVERALL_CONDITION_DATA, fetchingOverallConditionSaga);
}
export function* fetchingOverallConditionSaga(action) {
  try {
    const { site, customer, fleet, ok, trip, unknown } = action.payload;
    const response = yield call(
      api.fetchOverallConditionData,
      site,
      customer,
      fleet,
      ok,
      trip,
      unknown
    );

    const overallConditionData = response.dc;

    // if (fleet === "AKKUFLIRT") {
    //   overallConditionData.push(FleetListItems);
    // }

    yield put(fetchOverallConditionDataSucess(overallConditionData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchOverallConditionDataFailure));
  }
}

export function* fetchOverviewSaga() {
  yield takeLatest(FETCH_OVERVIEW_DATA, fetchingOverviewSaga);
}
export function* fetchingOverviewSaga(action) {
  try {
    const { site, fleet } = action.payload;
    const response = yield call(api.fetchOverviewData, site, fleet);
    const overviewData = response.dc;
    yield put(fetchOverviewDataSucess(overviewData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchOverviewDataFailure));
  }
}

/// //////////////////////////////////////////////////////////////////////

export function* fetchHistogramSaga() {
  yield takeLatest(FETCH_HISTOGRAM_DATA, fetchingHistogramSaga);
}
export default function* fetchingHistogramSaga(action) {
  try {
    // const { startDate, endDate } = action.payload;
    const response = yield call(api.fetchHistogramData);
    const histogramData = response.data_Constants;
    yield put(fetchHistogramDataSucess(histogramData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchHistogramDataFailure));
  }
}

export function* fetachBoxPlotDataSaga() {
  yield takeLatest(FETCH_BOXPLOT_DATA, fetchingBoxPlotDataSaga);
}
export function* fetchingBoxPlotDataSaga(action) {
  try {
    const { fleetName, startDate, endDate, docType } = action.payload;
    const response = yield call(
      api.fetchBoxPlotData,
      fleetName,
      startDate,
      endDate,
      docType
    );
    const BoxPlotData = [];

    if (response.length !== 0) {
      BoxPlotData[0] = response[0].obs[0];
      if (response[0].sct[0].data.length > 0) {
        BoxPlotData[1] = response[0].sct[0];
      }
    }

    // dispatch a success action to the store with the new dog
    yield put(fetchBoxPlotDataSucess(BoxPlotData));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchBoxPlotDataFailure));
  }
}

// Fleetview Fleets list
export function* fetchFleetsListSaga() {
  yield takeLatest(FETCH_FLEETSLIST, fetchingFleetsListSaga);
}
export function* fetchingFleetsListSaga(action) {
  try {
    const { site, customer, fleet, ok, trip, unknown } = action.payload;
    const response = yield call(
      api.fetchFleetsList,
      site,
      customer,
      fleet,
      ok,
      trip,
      unknown
    );
    const FleetsList = response.dc;

    //  FleetsList.push(FleetListItems);

    // dispatch a success action to the store with the new
    yield put(fetchFleetsListSucess(FleetsList));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchFleetsListFailure));
  }
}

// Fleetview Vehicles list
export function* fetchVehiclesListSaga() {
  yield takeLatest(FETCH_VEHICLESLIST, fetchingVehiclesListSaga);
}
export function* fetchingVehiclesListSaga(action) {
  try {
    const { sitename, fleetname } = action.payload;
    const response = yield call(api.fetchVehiclesList, sitename, fleetname);
    const FleetsList = response.dc;
    // dispatch a success action to the store with the new
    yield put(fetchVehiclesListSucess(FleetsList));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchVehiclesListFailure));
  }
}

// fleet vie statistics data

export function* fetchFleetStatisticsSaga() {
  yield takeLatest(FETCH_FLEET_STATISTICS_DATA, fetchingFleetStatisticsSaga);
}
export function* fetchingFleetStatisticsSaga(action) {
  try {
    const { siteName, fleetName, year } = action.payload;

    const response = yield call(
      api.fetchFleetstatistics,
      siteName,
      fleetName,
      year
    );
    const payload = response;
    // dispatch a success action to the store with the new
    yield put(fetchFleetStatisticsDataSuccess(payload));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchFleetStatisticsDataFailure));
  }
}

// Fleetview Vehicle products list
export function* fetchVehicleProductListSaga() {
  yield takeLatest(FETCH_VEHICLEPRODUCTLIST, fetchingVehicleProductListSaga);
}
export function* fetchingVehicleProductListSaga(action) {
  try {
    const { sitename, fleetname, vehiclename, ok, trip, unknown } =
      action.payload;
    const response = yield call(
      api.fetchVehicleProductList,
      sitename,
      fleetname,
      vehiclename,
      ok,
      trip,
      unknown
    );
    const productList = response.dc;

    // dispatch a success action to the store with the new
    yield put(fetchVehicleProductListSucess(productList));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchVehicleProductListFailure));
  }
}

// Fleetview Modules list
export function* fetchModuleListSaga() {
  yield takeLatest(FETCH_MODULELIST, fetchingModuleListSaga);
}
export function* fetchingModuleListSaga(action) {
  try {
    const {
      sitename,
      fleetname,
      vehiclename,
      vehicleproductname,
      ok,
      trip,
      unknown,
    } = action.payload;
    const response = yield call(
      api.fetchModuleList,
      sitename,
      fleetname,
      vehiclename,
      vehicleproductname,
      ok,
      trip,
      unknown
    );
    const moduleList = response.dc;

    // dispatch a success action to the store with the new
    yield put(fetchModuleListSucess(moduleList));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchModuleListFailure));
  }
}

// Unmounted ESS list
export function* fetchUnmountedESSListSaga() {
  yield takeLatest(FETCH_UNMOUNTEDESS_LIST, fetchingUnmountedESSListSaga);
}
export function* fetchingUnmountedESSListSaga(action) {
  try {
    const { sitename, fleetname } = action.payload;
    const response = yield call(api.fetchUnmountedESSList, sitename, fleetname);
    const unmountedESSList = response.dc;

    // dispatch a success action to the store with the new
    yield put(fetchUnmountedESSListSucess(unmountedESSList));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchUnmountedESSListFailure));
  }
}

// Fleetview Diagnostic list
export function* fetchDiagnosticListSaga() {
  yield takeLatest(FETCH_DIAGNOSTICLIST, fetchingDiagnosticListSaga);
}
export function* fetchingDiagnosticListSaga(action) {
  try {
    const {
      fleetName,
      vehicleName,
      ipaddress,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      dateType,
      filterKeysName,
      filterKeysValue,
      packSerialNumber,
      pageSize,
      pageNumber,
      sortType,
      sortTo,
    } = action.payload;
    const response = yield call(
      api.fetchDiagnosticList,
      fleetName,
      vehicleName,
      ipaddress,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      dateType,
      filterKeysName,
      filterKeysValue,
      packSerialNumber,
      pageSize,
      pageNumber,
      sortType,
      sortTo
    );
    const productList = response;

    // dispatch a success action to the store with the new
    yield put(fetchDiagnosticListSuccess(productList));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchDiagnosticListFailure));
  }
}

// Fleetview Vehicle products list
export function* fetchFleetUsageSaga() {
  yield takeLatest(FETCH_FLEET_USAGE_DETAILS, fetchingFleetUsageSaga);
}
export function* fetchingFleetUsageSaga(action) {
  try {
    const { usageType, fleetName, vehicleName, startDate, endDate } =
      action.payload;
    let response;
    if (usageType !== 'SoH') {
      response = yield call(
        api.fetchFleetUsageDetails,
        usageType,
        fleetName,
        vehicleName,
        startDate,
        endDate
      );
    } else {
      response = yield call(api.fetchFleetUsageSOHDetails, fleetName);
    }
    // if usage type SOH then use the new API
    const fleetUsageDetails = response;
    // dispatch a success action to the store with the new
    yield put(fetchFleetUsageSuccess(fleetUsageDetails));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchFleetUsageFailure));
  }
}
