import {
  CLEAR_FLEETWORDREPORT_DATA,
  FETCH_EXCELREPORT_DATA,
  FETCH_EXCELREPORT_DATA_CLEAR,
  FETCH_EXCELREPORT_DATA_FAILURE,
  FETCH_EXCELREPORT_DATA_SUCCESS,
  FETCH_EXCELREPORT_EMAIL,
  FETCH_EXCELREPORT_EMAIL_SUCCESS,
  FETCH_FLEETREPORTCONFIG_DATA,
  FETCH_FLEETREPORTCONFIG_DATA_FAILURE,
  FETCH_FLEETREPORTCONFIG_DATA_SUCCESS,
  FETCH_FLEETREPORTSOC_DATA,
  FETCH_FLEETREPORTSOC_DATA_FAILURE,
  FETCH_FLEETREPORTSOC_DATA_SUCCESS,
  FETCH_FLEETREPORTTEMPERATURE_DATA,
  FETCH_FLEETREPORTTEMPERATURE_DATA_FAILURE,
  FETCH_FLEETREPORTTEMPERATURE_DATA_SUCCESS,
  FETCH_FLEETWORDREPORT_DATA,
  FETCH_FLEETWORDREPORT_DATA_FAILURE,
  FETCH_FLEETWORDREPORT_DATA_SUCCESS,
} from './constants';

// EXCEL REPORT
export const fetchExcelReportData = (
  fleetname,
  startDate,
  endDate,
  fleetReportOptions
) => ({
  type: FETCH_EXCELREPORT_DATA,
  payload: { fleetname, startDate, endDate, fleetReportOptions },
});

export const fetchExcelReportDataSuccess = excelReportData => ({
  type: FETCH_EXCELREPORT_DATA_SUCCESS,
  excelReportData,
});

// EXCEL EMAIL REPORT
export const fetchExcelReportEmailData = (
  fleetname,
  startDate,
  endDate,
  fleetReportOptions
) => ({
  type: FETCH_EXCELREPORT_EMAIL,
  payload: { fleetname, startDate, endDate, fleetReportOptions },
});

export const fetchExcelReportEmailSuccess = excelReportEmail => ({
  type: FETCH_EXCELREPORT_EMAIL_SUCCESS,
  excelReportEmail,
});

export const fetchExcelReportDataFailure = error => ({
  type: FETCH_EXCELREPORT_DATA_FAILURE,
  error,
});

export const clearExcelReportData = () => ({
  type: FETCH_EXCELREPORT_DATA_CLEAR,
});

// Fleet word REPORT
export const fetchFleetWordReportData = (
  fleetname,
  slaDate,
  fleetReportOptions
) => ({
  type: FETCH_FLEETWORDREPORT_DATA,
  payload: { fleetname, slaDate, fleetReportOptions },
});

export const fetchFleetWordReportSuccess = fleetWordReport => ({
  type: FETCH_FLEETWORDREPORT_DATA_SUCCESS,
  fleetWordReport,
});

export const fetchFleetWordReportFailure = error => ({
  type: FETCH_FLEETWORDREPORT_DATA_FAILURE,
  error,
});

// Fleet word REPORT SOC
export const fetchFleetSOCReportData = (
  fleetname,
  slaDate,
  fleetReportOptions
) => ({
  type: FETCH_FLEETREPORTSOC_DATA,
  payload: { fleetname, slaDate, fleetReportOptions },
});

export const fetchFleetSOCReportSuccess = fleetWordReport => ({
  type: FETCH_FLEETREPORTSOC_DATA_SUCCESS,
  fleetWordReport,
});

export const fetchFleetSOCReportFailure = error => ({
  type: FETCH_FLEETREPORTSOC_DATA_FAILURE,
  error,
});

// Fleet word REPORT TEMPERATURE
export const fetchFleetTemperatureReportData = (
  fleetname,
  slaDate,
  fleetReportOptions
) => ({
  type: FETCH_FLEETREPORTTEMPERATURE_DATA,
  payload: { fleetname, slaDate, fleetReportOptions },
});

export const fetchFleetTemperatureReportSuccess = fleetWordReport => ({
  type: FETCH_FLEETREPORTTEMPERATURE_DATA_SUCCESS,
  fleetWordReport,
});

export const fetchFleetTemperatureReportFailure = error => ({
  type: FETCH_FLEETREPORTTEMPERATURE_DATA_FAILURE,
  error,
});

// Fleet word REPORT Config
export const fetchFleetConfigReportData = (
  fleetname,
  slaDate,
  fleetReportOptions
) => ({
  type: FETCH_FLEETREPORTCONFIG_DATA,
  payload: { fleetname, slaDate, fleetReportOptions },
});

export const fetchFleetConfigReportSuccess = fleetWordReport => ({
  type: FETCH_FLEETREPORTCONFIG_DATA_SUCCESS,
  fleetWordReport,
});

export const fetchFleetConfigReportFailure = error => ({
  type: FETCH_FLEETREPORTCONFIG_DATA_FAILURE,
  error,
});

export const clearFleetWordReportData = () => ({
  type: CLEAR_FLEETWORDREPORT_DATA,
});
