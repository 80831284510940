import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HC_boost from 'highcharts/modules/boost';
import HC_boost_canvas from 'highcharts/modules/boost-canvas';
import HC_exporting from 'highcharts/modules/exporting';
import HC_heatMap from 'highcharts/modules/heatmap';
import PropTypes from 'prop-types';
import React from 'react';

import { withRouter } from 'react-router-dom';
import {
  getBoxPlotSingleFleet,
  getCellVoltageTrend,
  getColomChartWithCenterLine,
  getColomChartWithLine,
  getHistogramSingleFleet,
  getMultiColorTrend,
  getSOHChart,
  getTrendChart,
  xMax,
  xMin,
} from './function';
import './styles.scss';

highchartsMore(Highcharts);
HC_exporting(Highcharts);
HC_heatMap(Highcharts);
HC_boost_canvas(Highcharts);
HC_boost(Highcharts);

class SingleGraph extends React.Component {
  constructor(props, context) {
    super(props, context);
    const options = this.initGraph(props);
    this.state = {
      options,
      sideWidth: this.props.sideWidth,
    };
  }

  readUrlValue = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('utb');
  };

  componentDidMount() {
    this.draw(this.props);

    sessionStorage.setItem(
      'activeTab',
      this.props.activeTab || this.readUrlValue()
    );
  }

  shouldComponentUpdate(nextProps) {
    if (this.state.sideWidth !== nextProps.sideWidth) {
      return true;
    }
    if (this.props.expandSide !== nextProps.expandSide) {
      return true;
    }
    if (this.props.showThreshold !== nextProps.showThreshold) {
      return true;
    }

    if (
      (this.props.chartType === 'TIME_TREND_TYPE' &&
        this.props.data.length !== nextProps.data.length) ||
      this.props.selectedMainTrend !== nextProps.selectedMainTrend ||
      this.props.selectedOverlayTrend !== nextProps.selectedOverlayTrend ||
      this.props.activeTab !== nextProps.activeTab
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const options = this.initGraph();
    this.setState({ options }, () => this.draw(this.props));
    if (this.props.sideWidth !== prevProps.sideWidth) {
      this.setState({
        options,
        sideWidth: this.props.sideWidth,
      });
    }
    this.draw(this.props);
    this.zoomIn();
    if (prevProps.activeTab !== this.props.activeTab) {
      sessionStorage.setItem('activeTab', this.props.activeTab);
    }
  }

  componentWillUnmount() {
    setTimeout(() => {
      this.chart.destroy();
    }, 0);
  }

  draw(props) {
    this.chart = Highcharts.chart(this.container, { ...this.state.options });
    this.chart.reflow();
  }

  zoomIn = () => {
    if (sessionStorage.getItem('xMin') === 'true') {
      if (this.chart && this.chart.options.chart.name === 'trendData') {
        if (xMin !== 0 && xMin !== undefined) {
          this.chart.showResetZoom();
          this.chart.xAxis[0].setExtremes(xMin, xMax);
        }
      }
    }
  };

  initGraph = props => {
    let options;
    if (this.props.chartType === 'BOX_TYPE') {
      options = getBoxPlotSingleFleet(this.props.data);
    } else if (this.props.chartType === 'BATTERY_SOC_RANGE_HISTOGRAM_TYPE') {
      options = getHistogramSingleFleet(
        this.props.data,
        'BATTERY_SOC_RANGE_HISTOGRAM_TYPE'
      );
    } else if (this.props.chartType === 'OPERATING_HOURS_HISTOGRAM_TYPE') {
      options = getHistogramSingleFleet(
        this.props.data,
        'OPERATING_HOURS_HISTOGRAM_TYPE'
      );
    } else if (this.props.chartType === 'DESIGNED_TYPE') {
      options = getColomChartWithLine(
        this.props.data[0].dg,
        'TEMPERATURES_HISTOGRAM_TYPE',
        this.props
      );
    } else if (this.props.chartType === 'NORMALIZED_TYPE') {
      options = getColomChartWithCenterLine(
        this.props.data[0].ng,
        'TEMPERATURES_HISTOGRAM_TYPE'
      );
    } else if (this.props.chartType === 'SOC_TREND_TYPE') {
      options = getMultiColorTrend(this.props.data);
    } else if (this.props.chartType === 'CELL_VOLTAGE_TREND_TYPE') {
      options = getCellVoltageTrend(this.props.data);
    } else if (this.props.chartType === 'TIME_TREND_TYPE') {
      options = getTrendChart(
        'selectedOverlaysTrend',
        this.props.data,
        this.props.selectedMainTrend,
        'basicFilter',
        'advancedFilter',
        this.props.onLoadMoreGraph,
        this.props.info,
        this.props.showThreshold
      );
    } else if (this.props.chartType === 'SOH_TYPE') {
      options = getSOHChart(this.props.data);
    }

    if (Highcharts.getOptions().exporting) {
      const contextButton =
        Highcharts.getOptions().exporting.buttons.contextButton; // eslint-disable-line
      contextButton.menuItems = contextButton.menuItems.filter(item => {
        return item !== 'downloadPDF';
      });
    }
    return options;
  };

  // dynamic side width caluculation to setup graph size based on the side bar size
  getcurrentsidebarwidth = () => {
    let sidewidth = 0;
    if (this.props.sideWidth > 370) {
      sidewidth = 370;
    } else if (this.props.sideWidth < 210) {
      sidewidth = 210;
    } else {
      sidewidth = this.props.sideWidth;
    }
    return window.innerWidth - (sidewidth + 100);
  };

  render() {
    return (
      <div
        id="single_chart_123"
        style={{
          width: '100%',
        }}
        className="chart"
        ref={ref => (this.container = ref)}
      />
    );
  }
}

SingleGraph.defaultProps = {};

SingleGraph.propTypes = {
  chart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  overlayData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedOverlays: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedGraph: PropTypes.string,
  onLoadGraph: PropTypes.func,
  startTimeDate: PropTypes.object,
  endTimeDate: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  errorCharts: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLoading: PropTypes.bool,
  chartType: PropTypes.string.isRequired,
};
export default withRouter(SingleGraph);
