import toast from 'react-hot-toast';
import { introjs } from '../IntroJs/Steps';
export const productUsageHistogramSteps = selectedFleet => {
  if (selectedFleet.pl !== '3') {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },

      {
        step: 1,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro: '',
      },
      {
        step: 2,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 3,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 5,
        element: '[data-intro="vehicle_num_click_0"]',
        intro:
          'Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 6,
        element: '[data-intro=""]',
        intro: 'Clicking on the vehicle takes you to the vehicle overview page',
      },
      {
        step: 7,
        element: '[data-intro="vehicle_product_00"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and longterm operation and overall health.',
      },
      {
        step: 8,
        element: '[data-intro="vehicle_product_01"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and longterm operation and overall health.',
      },
      {
        step: 9,
        element: `[data-intro="usage-report"]`,
        intro: 'Clicking on the battery takes you to the battery overview page',
      },
      {
        step: 10,
        element: `[data-intro="usage-report"]`,
        intro:
          "Select the Usage tab to observe histograms of the selected variables, offering a long-term view of your battery's operation.",
      },
      {
        step: 11,
        element: '[data-intro="usage-report"]',
        intro:
          "Select the Usage tab to observe histograms of the selected variables, offering a long-term view of your battery's operation.",
      },
      {
        step: 12,
        element: `[data-intro=""]`,
        intro: '',
      },
      {
        step: 13,
        element: `[data-intro="usage-tabs"]`,
        intro: 'Pick the variable you want to visualize.',
      },
      {
        step: 14,
        element: `[data-intro="date-selection"]`,
        intro: 'Set the desired time range for the data you wish to view.',
      },
      {
        step: 15,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 16,
        element: `[data-intro="usage-content"]`,
        intro:
          'The areas colored orange or red indicate operation under stress and can lead to faster aging.',
      },
      {
        step: 17,
        element: `[data-intro="usage-budget"]`,
        intro:
          "Select the 'Normalized designed budget' to reveal a heatmap below the histogram.",
      },
      {
        step: 18,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 19,
        element: `[data-intro="usage-normalize"]`,
        intro:
          'The heatmap shows how the battery has performed compared to its expected operation over time. The colors range from white (indicating minimal use) to black (indicating full use), with shades of gray in between showing varying levels of usage. If the expected operation is exceeded, those areas will appear in orange and red indicating stress. The time axis allows you to pinpoint when specific usage occurred.',
      },
      {
        step: 20,
        element: `[data-intro="usage-normalize"]`,
        intro:
          'Click on a heatmap block to observe the detailed signal for the specific time period.',
      },
      {
        step: 21,
        element: '[data-intro=""]',
        intro:
          'This page shows the detailed signal for the specific time period',
      },
      {
        step: 22,
        element: `[data-intro="usage-report"]`,
        intro:
          'This page shows the detailed signal for the specific time period',
      },
      {
        step: 23,
        element: '[data-intro="main_signal_threshold"]',
        intro:
          "Tick the 'Show main signal threshold' box to show the thresholds of the main signal in the trend diagram. These thresholds allow you to easily spot instances when the utilization thresholds were exceeded.",
      },

      {
        step: 24,
        element: '[data-intro="overlay-signal"]',
        intro:
          'Overlay the reliability signal with other signals and zoom in for a higher resolution.',
      },
      {
        step: 25,
        element: '[data-intro=""]',
        intro:
          'The usage histogram guide ends here. For more information, you can refer glossary or other user guides.',
      },
    ];
  } else {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },

      {
        step: 1,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro: '',
      },
      {
        step: 2,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 3,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 5,
        element: '[data-intro="vehicle_num_click_1"]',
        intro:
          'Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 6,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 7,
        element: '[data-intro="battery_num_click_0"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and longterm operation and overall health.',
      },
      {
        step: 8,
        element: '[data-intro=""]',
        intro:
          'Here you can see the overview of the battery and its operation.',
      },
      {
        step: 9,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 10,
        element: '[data-intro="string_num_1"]',
        intro:
          'Click on a product to see its latest operational status, as well as information on short and long term operation, overall health. For more information on the product-level graphs, refer to other available user guides.',
      },
      {
        step: 11,
        element: '[data-intro=""]',
        intro: 'This page shows the overview of the product',
      },
      {
        step: 12,
        element: '[data-intro=""]',
        intro: 'This page shows the overview of the product',
      },
      {
        step: 13,
        element: `[data-intro="usage-report"]`,
        intro:
          "Select the Usage tab to observe histograms of the selected variables, offering a long-term view of your battery's operation.",
      },
      {
        step: 14,
        element: '[data-intro=""]',
        intro: 'This page shows the usage details',
      },
      {
        step: 15,
        element: `[data-intro=""]`,
        intro: '',
      },
      {
        step: 16,
        element: `[data-intro="usage-tabs"]`,
        intro: 'Pick the variable you want to visualize.',
      },
      {
        step: 17,
        element: `[data-intro="date-selection"]`,
        intro: 'Set the desired time range for the data you wish to view.',
      },
      {
        step: 18,
        element: `[data-intro=""]`,
        intro: '',
      },
      {
        step: 19,
        element: `[data-intro="usage-content"]`,
        intro:
          'The areas colored orange or red indicate operation under stress and can lead to faster aging.',
      },
      {
        step: 20,
        element: `[data-intro="usage-budget"]`,
        intro:
          "Select the 'Normalized designed budget' to reveal a heatmap below the histogram.",
      },
      {
        step: 21,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 22,
        element: `[data-intro="usage-normalize"]`,
        intro:
          'The heatmap shows how the battery has performed compared to its expected operation over time. The colors range from white (indicating minimal use) to black (indicating full use), with shades of gray in between showing varying levels of usage. If the expected operation is exceeded, those areas will appear in orange and red indicating stress. The time axis allows you to pinpoint when specific usage occurred.',
      },
      {
        step: 23,
        element: `[data-intro="usage-normalize"]`,
        intro:
          'Click on a heatmap block to observe the detailed signal for the specific time period.',
      },
      {
        step: 24,
        element: '[data-intro=""]',
        intro:
          'This page shows the detailed signal for the specific time period',
      },
      {
        step: 25,
        element: '[data-intro=""]',
        intro:
          'This page shows the detailed signal for the specific time period',
      },
      {
        step: 26,
        element: '[data-intro="main_signal_threshold"]',
        intro:
          "Tick the 'Show main signal threshold' box to show the thresholds of the main signal in the trend diagram. These thresholds allow you to easily spot instances when the utilization thresholds were exceeded.",
      },
      {
        step: 27,
        element: '[data-intro="overlay-signal"]',
        intro:
          'Overlay the reliability signal with other signals and zoom in for a higher resolution.',
      },
      {
        step: 28,
        element: '[data-intro=""]',
        intro:
          'The usage histogram guide ends here. For more information, you can refer glossary or other user guides.',
      },
    ];
  }
};
export const productUsageHistogramStepChange = (step, selectedFleet) => {
  try {
    if (selectedFleet.pl !== '3') {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 3) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 4) {
        introjs.goToStep(5);
        introjs.start();
      }
      if (step === 6) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 7) {
        introjs.goToStep(8);
        introjs.start();
      }
      if (step === 9) {
        document.querySelector('[data-intro="vehicle_product_00"]').click();
      }
      if (step === 10) {
        introjs.goToStep(11);
        introjs.start();
      }
      if (step === 11) {
        document.querySelector('[data-intro="usage-report"]').click();
      }
      if (step === 12) {
        introjs.goToStep(13);
        introjs.start();
      }
      if (step === 14) {
        document.querySelector('[data-intro="date-year"]').click();
      }
      if (step === 15) {
        introjs.goToStep(16);
        introjs.start();
      }
      if (step === 17) {
        document.querySelector('[data-intro="normalize-data"]').click();
      }
      if (step === 18) {
        introjs.goToStep(19);
        introjs.start();
      }
      if (step === 21) {
        document.querySelector('[data-intro="time-trend-title"]').click();
      }
      if (step === 22) {
        introjs.goToStep(23);
        introjs.start();
      }
      if (step === 25) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    }
    if (selectedFleet.pl === '3') {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 3) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 4) {
        introjs.goToStep(5);
        introjs.start();
      }
      if (step === 5) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 6) {
        introjs.goToStep(7);
        introjs.start();
      }
      if (step === 8) {
        document.querySelector('[data-intro="battery_num_click_0"]').click();
      }
      if (step === 9) {
        introjs.goToStep(10);
        introjs.start();
      }
      if (step === 11) {
        document.querySelector('[data-intro="string_num_0"]').click();
      }
      if (step === 12) {
        introjs.goToStep(13);
        introjs.start();
      }
      if (step === 14) {
        document.querySelector('[data-intro="usage-report"]').click();
      }
      if (step === 15) {
        introjs.goToStep(16);
        introjs.start();
      }
      if (step === 16) {
        document.querySelector('[data-intro="date-year"]').click();
      }
      if (step === 18) {
        introjs.goToStep(19);
        introjs.start();
      }
      if (step === 20) {
        document.querySelector('[data-intro="normalize-data"]').click();
      }
      if (step === 21) {
        introjs.goToStep(22);
        introjs.start();
      }
      if (step === 24) {
        document.querySelector('[data-intro="time-trend-title"]').click();
      }
      if (step === 25) {
        introjs.goToStep(26);
        introjs.start();
      }
      if (step === 28) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    }
  } catch (err) {
    toast.error('Error in user guide! Please start again.');
    introjs.exit(true);
  }
};
