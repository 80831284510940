import {
  CLEAR_CHARTDATA,
  FETCH_BOXPLOT_DATA,
  FETCH_BOXPLOT_DATA_FAILURE,
  FETCH_BOXPLOT_DATA_SUCCESS,
  FETCH_DIAGNOSTICLIST,
  FETCH_DIAGNOSTICLIST_FAILURE,
  FETCH_DIAGNOSTICLIST_SUCCESS,
  FETCH_FLEETSLIST,
  FETCH_FLEETSLIST_FAILURE,
  FETCH_FLEETSLIST_SUCCESS,
  FETCH_FLEET_STATISTICS_DATA,
  FETCH_FLEET_STATISTICS_DATA_FAILURE,
  FETCH_FLEET_STATISTICS_DATA_SUCCESS,
  FETCH_FLEET_USAGE_DETAILS,
  FETCH_FLEET_USAGE_DETAILS_FAILURE,
  FETCH_FLEET_USAGE_DETAILS_SUCCESS,
  FETCH_HISTOGRAM_DATA,
  FETCH_HISTOGRAM_DATA_FAILURE,
  FETCH_HISTOGRAM_DATA_SUCCESS,
  FETCH_MODULELIST,
  FETCH_MODULELIST_FAILURE,
  FETCH_MODULELIST_SUCCESS,
  FETCH_OVERALL_CONDITION_DATA,
  FETCH_OVERALL_CONDITION_DATA_FAILURE,
  FETCH_OVERALL_CONDITION_DATA_SUCCESS,
  FETCH_OVERVIEW_DATA,
  FETCH_OVERVIEW_DATA_FAILURE,
  FETCH_OVERVIEW_DATA_SUCCESS,
  FETCH_UNMOUNTEDESS_LIST,
  FETCH_UNMOUNTEDESS_LIST_FAILURE,
  FETCH_UNMOUNTEDESS_LIST_SUCCESS,
  FETCH_VEHICLEPRODUCTLIST,
  FETCH_VEHICLEPRODUCTLIST_FAILURE,
  FETCH_VEHICLEPRODUCTLIST_SUCCESS,
  FETCH_VEHICLESLIST,
  FETCH_VEHICLESLIST_FAILURE,
  FETCH_VEHICLESLIST_SUCCESS,
  SET_DATE,
  SET_DIAGNOSTIC_EVENT_LIST_DATA,
  SET_FLEETNAME,
  SET_LIST,
  SET_PLATFORM_VERSION_NUMBER,
  SET_VEHICLENAME,
} from './constants';

// FleetsList
export const fetchFleetsList = (site, customer, fleet, ok, trip, unknown) => ({
  type: FETCH_FLEETSLIST,
  payload: { site, customer, fleet, ok, trip, unknown },
});

export const fetchFleetsListSucess = fleetsList => ({
  type: FETCH_FLEETSLIST_SUCCESS,
  fleetsList,
});

export const fetchFleetsListFailure = error => ({
  type: FETCH_FLEETSLIST_FAILURE,
  error,
});

// fleetStatisticsdata

export const fetchFleetStatisticsData = (siteName, fleetName, year) => ({
  type: FETCH_FLEET_STATISTICS_DATA,
  payload: { siteName, fleetName, year },
});

export const fetchFleetStatisticsDataSuccess = payload => ({
  type: FETCH_FLEET_STATISTICS_DATA_SUCCESS,
  payload,
});

export const fetchFleetStatisticsDataFailure = error => ({
  type: FETCH_FLEET_STATISTICS_DATA_FAILURE,
  error,
});
// VehiclesList
export const fetchVehiclesList = (sitename, fleetname) => ({
  type: FETCH_VEHICLESLIST,
  payload: { sitename, fleetname },
});

export const fetchVehiclesListSucess = vehiclesList => ({
  type: FETCH_VEHICLESLIST_SUCCESS,
  vehiclesList,
});

export const fetchVehiclesListFailure = error => ({
  type: FETCH_VEHICLESLIST_FAILURE,
  error,
});

// VehicleProductList
export const fetchVehicleProductList = (
  sitename,
  fleetname,
  vehiclename,
  ok,
  trip,
  unknown
) => ({
  type: FETCH_VEHICLEPRODUCTLIST,
  payload: { sitename, fleetname, vehiclename, ok, trip, unknown },
});

export const fetchVehicleProductListSucess = vehicleproductList => ({
  type: FETCH_VEHICLEPRODUCTLIST_SUCCESS,
  vehicleproductList,
});

export const fetchVehicleProductListFailure = error => ({
  type: FETCH_VEHICLEPRODUCTLIST_FAILURE,
  error,
});

// ModuleList
export const fetchModuleList = (
  sitename,
  fleetname,
  vehiclename,
  vehicleproductname,
  ok,
  trip,
  unknown
) => ({
  type: FETCH_MODULELIST,
  payload: {
    sitename,
    fleetname,
    vehiclename,
    vehicleproductname,
    ok,
    trip,
    unknown,
  },
});

export const fetchModuleListSucess = moduleList => ({
  type: FETCH_MODULELIST_SUCCESS,
  moduleList,
});

export const fetchModuleListFailure = error => ({
  type: FETCH_MODULELIST_FAILURE,
  error,
});

export const setFleetName = fleetName => ({
  type: SET_FLEETNAME,
  fleetName,
});

export const setVehicleName = vehicleName => ({
  type: SET_VEHICLENAME,
  vehicleName,
});

// fleet overall condition
export const fetchOverallConditionData = (
  site,
  customer,
  fleet,
  ok,
  trip,
  unknown
) => ({
  type: FETCH_OVERALL_CONDITION_DATA,
  payload: { site, customer, fleet, ok, trip, unknown },
});

export const fetchOverallConditionDataSucess = overallConditionData => ({
  type: FETCH_OVERALL_CONDITION_DATA_SUCCESS,
  overallConditionData,
});

export const fetchOverallConditionDataFailure = error => ({
  type: FETCH_OVERALL_CONDITION_DATA_FAILURE,
  error,
});

// fleet overview
export const fetchOverviewData = (site, fleet) => ({
  type: FETCH_OVERVIEW_DATA,
  payload: { site, fleet },
});

export const fetchOverviewDataSucess = overviewData => ({
  type: FETCH_OVERVIEW_DATA_SUCCESS,
  overviewData,
});

export const fetchOverviewDataFailure = error => ({
  type: FETCH_OVERVIEW_DATA_FAILURE,
  error,
});

// Histogram
export const fetchHistogramData = () => ({
  type: FETCH_HISTOGRAM_DATA,
});

export const fetchHistogramDataSucess = histogramData => ({
  type: FETCH_HISTOGRAM_DATA_SUCCESS,
  histogramData,
});

export const fetchHistogramDataFailure = error => ({
  type: FETCH_HISTOGRAM_DATA_FAILURE,
  error,
});

// BoxPlot
export const fetchBoxPlotData = (fleetName, startDate, endDate, docType) => ({
  type: FETCH_BOXPLOT_DATA,
  payload: { fleetName, startDate, endDate, docType },
});

export const fetchBoxPlotDataSucess = boxPlotData => ({
  type: FETCH_BOXPLOT_DATA_SUCCESS,
  boxPlotData,
});

export const fetchBoxPlotDataFailure = error => ({
  type: FETCH_BOXPLOT_DATA_FAILURE,
  error,
});

export const setDate = (startDate, endDate) => ({
  type: SET_DATE,
  payload: { startDate, endDate },
});

export const clearChartData = () => ({
  type: CLEAR_CHARTDATA,
});

export const setListSelection = selection => ({
  type: SET_LIST,
  payload: { selection },
});

// Unmounted ESS List
export const fetchUnmountedESSList = (sitename, fleetname) => ({
  type: FETCH_UNMOUNTEDESS_LIST,
  payload: { sitename, fleetname },
});

export const fetchUnmountedESSListSucess = unmountedESSList => ({
  type: FETCH_UNMOUNTEDESS_LIST_SUCCESS,
  unmountedESSList,
});

export const fetchUnmountedESSListFailure = error => ({
  type: FETCH_UNMOUNTEDESS_LIST_FAILURE,
  error,
});

// DiagnosticList
export const fetchDiagnosticList = (
  fleetName,
  vehicleName,
  ipaddress,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  dateType,
  filterKeysName,
  filterKeysValue,
  packSerialNumber,
  pageSize,
  pageNumber,
  sortType,
  sortTo
) => ({
  type: FETCH_DIAGNOSTICLIST,
  payload: {
    fleetName,
    vehicleName,
    ipaddress,
    pecUniqueSerialNumber,
    startDate,
    endDate,
    dateType,
    filterKeysName,
    filterKeysValue,
    packSerialNumber,
    pageSize,
    pageNumber,
    sortType,
    sortTo,
  },
});

export const fetchDiagnosticListSuccess = diagnosticList => ({
  type: FETCH_DIAGNOSTICLIST_SUCCESS,
  diagnosticList,
});

export const fetchDiagnosticListFailure = error => ({
  type: FETCH_DIAGNOSTICLIST_FAILURE,
  error,
});
export const setDiagnosticEventListData = eventData => ({
  type: SET_DIAGNOSTIC_EVENT_LIST_DATA,
  eventData,
});
export const setplatformVersionNumber = payload => ({
  type: SET_PLATFORM_VERSION_NUMBER,
  payload,
});

export const fetchFleetUsage = (
  usageType,
  fleetName,
  vehicleName,
  startDate,
  endDate
) => ({
  type: FETCH_FLEET_USAGE_DETAILS,
  payload: { usageType, fleetName, vehicleName, startDate, endDate },
});

export const fetchFleetUsageSuccess = fleetUsageDetails => ({
  type: FETCH_FLEET_USAGE_DETAILS_SUCCESS,
  fleetUsageDetails,
});

export const fetchFleetUsageFailure = error => ({
  type: FETCH_FLEET_USAGE_DETAILS_FAILURE,
  error,
});
