import { createSelector } from 'reselect';

// Excel report data
const ExcelReportData = () => state => state.get('excelReportData');

const loadingExcelReportSelector = () =>
  createSelector(ExcelReportData(), state => state.get('loadingExcelReport'));

const excelReportDataSelector = () =>
  createSelector(ExcelReportData(), state => state.get('excelReportData'));

const loadingExcelReportEmailSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('loadingExcelReportEmail')
  );

const excelReportEmailSelector = () =>
  createSelector(ExcelReportData(), state => state.get('excelReportEmail'));

const loadingFleetWordReportSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('loadingFleetWordReport')
  );
const fleetWordReportSelector = () =>
  createSelector(ExcelReportData(), state => state.get('fleetWordReportData'));

const loadingFleetSOCReportSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('loadingFleetSOCReport')
  );
const fleetSOCReportSelector = () =>
  createSelector(ExcelReportData(), state => state.get('fleetSOCReportData'));

const loadingFleetTemperatureReportSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('loadingFleetTemperatureReport')
  );
const fleetTemperatureReportSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('fleetTemperatureReportData')
  );

const loadingFleetConfigReportSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('loadingFleetConfigReport')
  );
const fleetConfigReportSelector = () =>
  createSelector(ExcelReportData(), state =>
    state.get('fleetConfigReportData')
  );
export {
  ExcelReportData,
  excelReportDataSelector,
  excelReportEmailSelector,
  loadingExcelReportEmailSelector,
  loadingExcelReportSelector,
  loadingFleetWordReportSelector,
  fleetWordReportSelector,
  loadingFleetSOCReportSelector,
  fleetSOCReportSelector,
  loadingFleetTemperatureReportSelector,
  fleetTemperatureReportSelector,
  loadingFleetConfigReportSelector,
  fleetConfigReportSelector,
};
