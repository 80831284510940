import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Datepicker from '../DatePicker';
import './styles.scss';

moment.locale('en');
class DateRangePicker extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isSameDate: true,
    };
  }

  applyButtonEnable = value => {
    this.setState({ isSameDate: value });
  };

  onDatePickerButtonClick = value => {
    this.props.onDatePickerButtonClick(value);
    this.applyButtonEnable(false);
  };

  render() {
    return (
      <Row
        className={
          this.props.selectedTab === 'Overview-vehicleprod-view' ||
          this.props.selectedTab === 'Event-list-vehicleprod-view' ||
          this.props.selectedTab === 'Overview' ||
          this.props.selectedTab === 'SOH-vehicleprod-view' ||
          this.props.selectedTab === 'diagnostic-vehicleprod-view' ||
          sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
            ? 'date-filter-selection disabled'
            : 'date-filter-selection'
        }>
        <Col className="noLeftpadding noRightpadding date-range-btn-con">
          <button
            type="button"
            className={
              this.props.comnDateType === 'Daily'
                ? 'toggle-button activeLightBlue'
                : 'toggle-button'
            }
            onClick={() => this.props.onDatePickerButtonClick('Daily')}>
            Day
          </button>
          <button
            type="button"
            className={
              this.props.comnDateType === 'Weekly'
                ? 'toggle-button activeLightBlue'
                : 'toggle-button'
            }
            onClick={() => this.props.onDatePickerButtonClick('Weekly')}>
            Week
          </button>
          <button
            type="button"
            className={
              this.props.comnDateType === 'Monthly'
                ? 'toggle-button activeLightBlue'
                : 'toggle-button'
            }
            onClick={() => this.props.onDatePickerButtonClick('Monthly')}>
            Month
          </button>
          <button
            data-intro="date-year"
            type="button"
            className={
              this.props.selectedTab === 'Timetrend-vehicleprod-view'
                ? 'toggle-button disabled'
                : this.props.comnDateType === 'Year'
                  ? 'toggle-button activeLightBlue'
                  : 'toggle-button'
            }
            onClick={() => this.props.onDatePickerButtonClick('Year')}
            disabled={this.props.selectedTab === 'Timetrend-vehicleprod-view'}>
            Year
          </button>
        </Col>
        <Col className="noLeftpadding noRightpadding datecontainer">
          <Datepicker
            selectedStartDate={this.props.selectedStartDate}
            selectedEndDate={this.props.selectedEndDate}
            onApplyClick={this.props.onApplyClick}
            selectedTab={this.props.selectedTab}
            isSameDate={this.state.isSameDate}
            applyButtonEnable={this.applyButtonEnable}
            comnDateType={this.props.comnDateType}
            onLeftRightBtnClick={this.props.onLeftRightBtnClick}
            leftrightBtnDisable={this.props.leftrightBtnDisable}
          />
        </Col>
      </Row>
    );
  }
}

export default DateRangePicker;
