import { createReport } from 'docx-templates/lib/browser';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import templatefleetlevelwordreport from './templatefleetlevelwordreport.docx';
import templatep1doc from './templatep1doc.docx';
import templatep3doc from './templatep3doc.docx';

const DOCX_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8';
const DOCX_EXTENSION = '.docx';

export const generateReport = async (
  selectedFleet,
  report_name,
  selectedVehicle,
  selectedESS,
  startDate,
  endDate,
  vehicleCount,
  essCount,
  selectedPack,
  packCount,
  selectedPlatform
) => {
  const blob = await generateReportData(
    selectedFleet,
    report_name,
    selectedVehicle,
    selectedESS,
    startDate,
    endDate,
    vehicleCount,
    essCount,
    selectedPack,
    packCount,
    selectedPlatform
  );

  saveAs(blob, report_name + DOCX_EXTENSION);
};

export const generateReportData = async (
  selectedFleet,
  report_name,
  selectedVehicle,
  selectedESS,
  startDate,
  endDate,
  vehicleCount,
  essCount,
  selectedPack,
  packCount,
  selectedPlatform
) => {
  // const overall_reliability = await getImage("rel-con", 295, 438);
  // const overall_utilization = await getImage("str-con", 295, 438);
  const overview_report = await getImage('overviewreport', 750, 478);
  const product_reliability = await getImage('product_reliability', 770, 418);
  const product_utilization = await getImage('product_utilization', 770, 490);
  const product_operatinghours = await getImage(
    'operatinghoursreport',
    730,
    438
  );
  const product_temperature = await getImage('temperaturereport', 730, 438);
  const product_soc = await getImage('socreport', 730, 438);
  const product_power = await getImage('powerreport', 730, 438);
  const product_dod = await getImage('dodreport', 730, 438);
  const eventtable = await getImageWH('eventtable', 730, 140);

  const template =
    selectedPlatform === '3'
      ? getTemplate(templatep3doc)
      : getTemplate(templatep1doc);
  const report = await createReport({
    cmdDelimiter: ['{', '}'],
    template,
    data: {
      fleet_name: selectedFleet,
      vehicle_name: selectedVehicle,
      battery_name: selectedESS,
      startdate: startDate,
      enddate: endDate,
      vehicleCount,
      essCount,
      pack_name: selectedPack,
      packCount,
      fleetoverview_chart: {
        width: pixelstocm(530),
        height: pixelstocm(290),
        data: overview_report,
        extension: '.png',
      },
      productreliability_chart: {
        width: pixelstocm(530),
        height: pixelstocm(230),
        data: product_reliability,
        extension: '.png',
      },
      productutilization_chart: {
        width: pixelstocm(530),
        height: pixelstocm(260),
        data: product_utilization,
        extension: '.png',
      },
      productoperatinghours_chart: {
        width: pixelstocm(530),
        height: pixelstocm(238),
        data: product_operatinghours,
        extension: '.png',
      },
      producttemperature_chart: {
        width: pixelstocm(530),
        height: pixelstocm(238),
        data: product_temperature,
        extension: '.png',
      },
      productsoc_chart: {
        width: pixelstocm(530),
        height: pixelstocm(238),
        data: product_soc,
        extension: '.png',
      },
      productpower_chart: {
        width: pixelstocm(530),
        height: pixelstocm(238),
        data: product_power,
        extension: '.png',
      },
      productdod_chart: {
        width: pixelstocm(530),
        height: pixelstocm(238),
        data: product_dod,
        extension: '.png',
      },
      eventtable: {
        width: pixelstocm(530),
        height: pixelstocm(95),
        data: eventtable,
        extension: '.png',
      },
    },
  });

  return new Blob([report], { type: DOCX_TYPE });

  /* let formData = new FormData();
  let fileName = report_name + DOCX_EXTENSION;
  let file = new File([blob], fileName);
  formData.append("uploadFile", file, fileName);
  formData.append("reportFileName", fileName);
  formData.append("fleetName", sessionStorage.getItem("selectedFleet"));
  formData.append("vehicleName", selectedVehicle);
  formData.append("essName", selectedESS);
  formData.append("startDate", startDate);
  formData.append("endDate", endDate);
  formData.append(
    "emailId",
    JSON.parse(sessionStorage.getItem("profile")).email
  );

  axios.post("/api/stable1/UploadBlob", formData, {
    baseURL: baseUrl,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${sessionStorage.getItem("id_token")}`,
    },
  }); */
};

const getImage = async (domid, width, height) => {
  const dom_node = document.getElementById(domid);
  const img = await domtoimage
    .toPng(dom_node)
    .then(dataurl => dataurl.slice('data:image/png;base64,'.length));
  return img;
};

const getImageWH = async (domid, width, height) => {
  const dom_node = document.getElementById(domid);
  const img = await domtoimage
    .toPng(dom_node, { width, height })
    .then(dataurl => dataurl.slice('data:image/png;base64,'.length));
  return img;
};

const getTemplate = async filepath => {
  return await fetch(filepath).then(res => res.arrayBuffer());
};

const pixelstocm = px => {
  return px * 0.0264583333;
};

export const generateFleetWordReport = async (
  fleetWordReportData,
  fleetSOCReport,
  fleetTemperatureReport,
  fleetConfigReport,
  report_name
) => {
  const blob = await generateFleetWordReportData(
    fleetWordReportData,
    fleetSOCReport,
    fleetTemperatureReport,
    fleetConfigReport
  );

  saveAs(blob, report_name + DOCX_EXTENSION);
};

export const generateFleetWordReportData = async (
  fleetWordReportData,
  fleetSOCReport,
  fleetTemperatureReport,
  fleetConfigReport
) => {
  const template = getTemplate(templatefleetlevelwordreport);
  const report = await createReport({
    cmdDelimiter: ['{', '}'],
    template,
    data: {
      fleetName: fleetWordReportData.fleetName,
      reportingPeriod: fleetWordReportData.reportingPeriod,
      totalVehicle: fleetWordReportData.data.totalVehicle,
      activeVehicle: fleetWordReportData.data.activeVehicle,
      totalBattery: fleetWordReportData.data.totalBattery,
      activeBattery: fleetWordReportData.data.activeBattery,
      spareBattery: fleetWordReportData.data.spareBattery,
      type: fleetWordReportData.data.type,
      batteryPerVehicle: fleetWordReportData.data.batteryPerVehicle,
      btms: fleetWordReportData.data.btms,
      slaDate: fleetWordReportData.data.slaDate,
      maxSOC: fleetSOCReport.maxData.data,
      minSOC: fleetSOCReport.minData.data,
      maxTemperature: fleetTemperatureReport.maxData.data,
      minTemperature: fleetTemperatureReport.minData.data,
      configData: fleetConfigReport.data,
      currentYear: fleetWordReportData.kpiData.sohCompairYear.currentYear,
      previousYear: fleetWordReportData.kpiData.sohCompairYear.previousYear,
      currentSOHAvg: fleetWordReportData.kpiData.sohCompairYear.currentSOHAvg,
      differenceOfYears:
        fleetWordReportData.kpiData.sohCompairYear.differenceOfYears,
      globalFleetKpi: fleetWordReportData.kpiData.dataPerYear,
    },
  });

  return new Blob([report], { type: DOCX_TYPE });
};
