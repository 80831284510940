import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Loading from '../Loading';
import './styles.scss';

import Form from 'react-bootstrap/Form';
import { withRouter } from 'react-router-dom';
import { Warning } from '../../assets/images';
import { checkUserEntitlements } from '../../userConfig';
import ContentWithChart from '../ContentWithChart';
import SingleGraph from '../SingleGraph';
import Heatmap from '../UsageHistogramHeatmap';

Moment.locale('en');

class ProductUsagesTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ohActiveRadio: 'Dbudget',
      tempActiveRadio: 'Dbudget',
      socActiveRadio: 'Dbudget',
      powActiveRadio: 'Dbudget',
      deptActiveRadio: 'Dbudget',

      activetab: 'usgtemp',
      clickActive: false,
      usgTempHeatmapData: this.props.usgTempHeatmapData,
      usgSocHeatmapData: this.props.usgSocHeatmapData,
      usgDodHeatmapData: this.props.usgDodHeatmapData,
      usgPowHeatmapData: this.props.usgPowHeatmapData,
    };
  }

  redirectRadioButtonHandler = () => {
    const radiobtn = this.props.radioValue || this.props.selectedUsageRbtn;
    const url = new URL(window.location.href);
    if (this.props.selectedUsageTab === 'usgoh')
      this.setState({
        ohActiveRadio: url.searchParams.get('urbt') || radiobtn,
      });
    else if (this.props.selectedUsageTab === 'usgtemp')
      this.setState({
        tempActiveRadio: url.searchParams.get('urbt') || radiobtn,
      });
    else if (this.props.selectedUsageTab === 'usgsoc')
      this.setState({
        socActiveRadio: url.searchParams.get('urbt') || radiobtn,
      });
    else if (this.props.selectedUsageTab === 'usgpow')
      this.setState({
        powActiveRadio: url.searchParams.get('urbt') || radiobtn,
      });
    else if (this.props.selectedUsageTab === 'usgdod')
      this.setState({
        deptActiveRadio: url.searchParams.get('urbt') || radiobtn,
      });
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);

    const startDate = searchParams.get('sdt');

    const endDate = searchParams.get('edt');
    this.redirectRadioButtonHandler();
    this.calculateDateDifferenceFromUrl(startDate, endDate);
  }

  handleRadioChange = (value, name) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
    this.props.setRadioButtonValue(value);
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('urbt', value);
      window.history.pushState(null, null, url);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.usgTempHeatmapData !== this.props.usgTempHeatmapData ||
      prevProps.usgSocHeatmapData !== this.props.usgSocHeatmapData ||
      prevProps.usgDodHeatmapData !== this.props.usgDodHeatmapData ||
      prevProps.usgPowHeatmapData !== this.props.usgPowHeatmapData
    ) {
      this.setState({
        usgTempHeatmapData: this.props.usgTempHeatmapData,
        usgSocHeatmapData: this.props.usgSocHeatmapData,
        usgDodHeatmapData: this.props.usgDodHeatmapData,
        usgPowHeatmapData: this.props.usgPowHeatmapData,
      });
    }
    const searchParams = new URLSearchParams(window.location.search);
    const prevSearchParams = new URLSearchParams(prevProps.location.search);

    const startDate = searchParams.get('sdt');

    const endDate = searchParams.get('edt');
    if (prevProps.selectedUsageTab !== this.props.selectedUsageTab) {
      this.redirectRadioButtonHandler();
      this.calculateDateDifferenceFromUrl(startDate, endDate);
    }

    // the below if condition was added as a wrapper to the nested if condition to avoid the diagnostic custom date crash issue

    if (searchParams.get('tb') === 'Usage') {
      if (
        prevSearchParams.get('sdt') !== startDate ||
        prevSearchParams.get('edt') !== endDate
      ) {
        this.calculateDateDifferenceFromUrl(startDate, endDate);
      }
    }
  }

  calculateDateDifferenceFromUrl(startDateStr, endDateStr) {
    const startDate = new Date(decodeURIComponent(startDateStr));
    const endDate = new Date(decodeURIComponent(endDateStr));
    if (!isNaN(startDate) && !isNaN(endDate)) {
      const timeDifference = endDate.getTime() - startDate.getTime();
      const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
      dayDifference >= 6
        ? this.setState({ clickActive: true })
        : this.setState({ clickActive: false });
    }
    return null;
  }

  render() {
    const { clickActive } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    return (
      <Tabs
        data-intro="usage-tabs"
        id="produsgstabs"
        activeKey={this.props.selectedUsageTab}
        onSelect={k => this.props.onUsageTabsClick(k)}>
        {/** operating hours */}
        {checkUserEntitlements('ESS:Product-Usage-OH', 'usgoh') && (
          <Tab
            eventKey="usgoh"
            title={
              <span className="tabIcon">
                <span className="tabtitle" id="ohtitle">
                  Operating hours
                </span>
              </span>
            }>
            {this.props.loadingOperatingHoursHistogram === true ||
            this.props.operatingHoursHistogramData === null ? (
              sessionStorage.getItem('selectedProductDname') !== null ? (
                sessionStorage
                  .getItem('selectedProductDname')
                  .includes('New_Product') === false ? (
                  <Loading loadintText="Loading data" />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )
              ) : (
                <Loading loadintText="Loading data" />
              )
            ) : this.props.operatingHoursHistogramData.rp !== null &&
              this.props.operatingHoursHistogramData.rp.length > 0 ? (
              <div className="battery-condition-info">
                <Row>
                  <Col md={2} className="noLeftpadding noRightpadding">
                    <div className="mediumText info-title">Operating hours</div>
                    <div className="info-label">Total:</div>
                    <div className="mediumText info-value">
                      {this.props.operatingHoursHistogramData.oh[0].tot} h
                    </div>
                    <div className="info-label">Average:</div>
                    <div className="mediumText info-value">
                      {this.props.operatingHoursHistogramData.oh[0].avg} h / day
                    </div>
                  </Col>
                  <Col md={10}>
                    <SingleGraph
                      chartType="OPERATING_HOURS_HISTOGRAM_TYPE"
                      data={this.props.operatingHoursHistogramData.rp}
                      expandSide={this.props.expandSide}
                      sideWidth={this.props.sideWidth}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </Tab>
        )}
        {/** Temperature  */}
        {checkUserEntitlements('ESS:Product-Usage-Temperature', 'usgtemp') && (
          <Tab
            eventKey="usgtemp"
            title={
              <span className="tabIcon">
                {this.props.temperatureHistogramData !== null &&
                this.props.temperatureHistogramData.length > 0 ? (
                  this.props.temperatureHistogramData[0].sts === true ? (
                    <Warning />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <span className="tabtitle"> Temperature</span>
              </span>
            }>
            <>
              <div>
                {this.props.loadingTemperatureHistogram === true ||
                this.props.loadingUsgTempHeatmap ||
                this.props.temperatureHistogramData === null ? (
                  sessionStorage.getItem('selectedProductDname') !== null ? (
                    sessionStorage
                      .getItem('selectedProductDname')
                      .includes('New_Product') === false ? (
                      <Loading loadintText="Loading data" />
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">No data found !</div>
                      </Col>
                    )
                  ) : (
                    <Loading loadintText="Loading data" />
                  )
                ) : this.props.temperatureHistogramData !== null &&
                  this.props.temperatureHistogramData.length > 0 ? (
                  <Row>
                    <Col md={12} className="noLeftpadding noRightpadding">
                      <Form>
                        <div
                          data-intro="usage-budget"
                          key={`usgtemp-radio`}
                          className="mb-3 usage-graph-radio">
                          <>
                            <Form.Check
                              inline
                              label="Designed budget"
                              name="usgtemp"
                              type="radio"
                              id={`usgtemp-1`}
                              value={'Dbudget'}
                              onChange={e =>
                                this.handleRadioChange(
                                  e.currentTarget.value,
                                  'tempActiveRadio'
                                )
                              }
                              checked={this.state.tempActiveRadio === 'Dbudget'}
                            />
                            <Form.Check
                              inline
                              label="Normalized designed budget"
                              name="usgtemp"
                              data-intro="normalize-data"
                              type="radio"
                              id={`usgtemp-2`}
                              value={'Nbudget'}
                              onChange={e =>
                                this.handleRadioChange(
                                  e.currentTarget.value,
                                  'tempActiveRadio'
                                )
                              }
                              checked={this.state.tempActiveRadio === 'Nbudget'}
                            />
                          </>
                        </div>
                      </Form>

                      <>
                        <ContentWithChart
                          ActiveRadio={this.state.tempActiveRadio}
                          usgHistogramData={this.props.temperatureHistogramData}
                          activeTab={this.props.selectedUsageTab}
                          expandSide={this.props.expandSide}
                          sideWidth={this.props.sideWidth}
                        />
                        {this.state.tempActiveRadio === 'Nbudget' &&
                        checkUserEntitlements('ESS:UsageHeatmap') &&
                        this.state.usgTempHeatmapData !== null ? (
                          clickActive ? (
                            <Heatmap
                              width={this.props.width}
                              data={this.state.usgTempHeatmapData}
                              dateSelection={urlParams.get('cdty')}
                              label="Hours"
                              onBlockClick={
                                this.props.onHeatmapBlockClickHandler
                              }
                            />
                          ) : (
                            <p className="weekCheckError">
                              To view heatmap, please select the date range
                              minimum of 6 days.
                            </p>
                          )
                        ) : null}
                      </>
                    </Col>
                  </Row>
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </>
          </Tab>
        )}
        {/** SOC */}
        {checkUserEntitlements('ESS:Product-Usage-Soc', 'usgsoc') && (
          <Tab
            eventKey="usgsoc"
            title={
              <span className="tabIcon">
                {this.props.usgSocHistogramData !== null &&
                this.props.usgSocHistogramData.length > 0 ? (
                  this.props.usgSocHistogramData[0].sts === true ? (
                    <Warning />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <span className="tabtitle"> SoC </span>
              </span>
            }>
            <>
              {/* {clickActive && (
                <div className="heatmap-switch">
                  <Form.Check
                    type="switch"
                    id="usgsoc"
                    label=""
                    checked={this.props.usgsocShowHeatmap}
                    onChange={() =>
                      this.props.handleSwitchChange(urlParams.get("utb"))
                    }
                    title={
                      this.state.usgSocHeatmapData &&
                      this.state.usgSocHeatmapData.length === 0 &&
                      "Data Not Available"
                    }
                  />
                </div>
              )} */}

              <div>
                {this.props.loadingUsgSocHistogram === true ||
                this.props.loadingUsgSocHeatmap ||
                this.props.usgSocHistogramData === null ? (
                  sessionStorage.getItem('selectedProductDname') !== null ? (
                    sessionStorage
                      .getItem('selectedProductDname')
                      .includes('New_Product') === false ? (
                      <Loading loadintText="Loading data" />
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">No data found !</div>
                      </Col>
                    )
                  ) : (
                    <Loading loadintText="Loading data" />
                  )
                ) : this.props.usgSocHistogramData !== null &&
                  this.props.usgSocHistogramData.length > 0 ? (
                  <Row>
                    <Col md={12} className="noLeftpadding noRightpadding">
                      <Form>
                        <div key={`usgsoc`} className="mb-3 usage-graph-radio">
                          <>
                            <Form.Check
                              inline
                              label="Designed budget"
                              name="usgsoc"
                              type="radio"
                              id={`usgsoc-1`}
                              value={'Dbudget'}
                              onChange={e =>
                                this.handleRadioChange(
                                  e.currentTarget.value,
                                  'socActiveRadio'
                                )
                              }
                              checked={this.state.socActiveRadio === 'Dbudget'}
                            />
                            <Form.Check
                              inline
                              label="Normalized designed budget"
                              name="usgsoc"
                              type="radio"
                              id={`usgsoc-2`}
                              value={'Nbudget'}
                              onChange={e =>
                                this.handleRadioChange(
                                  e.currentTarget.value,
                                  'socActiveRadio'
                                )
                              }
                              checked={this.state.socActiveRadio === 'Nbudget'}
                            />
                          </>
                        </div>
                      </Form>

                      <ContentWithChart
                        ActiveRadio={this.state.socActiveRadio}
                        usgHistogramData={this.props.usgSocHistogramData}
                        activeTab={this.props.selectedUsageTab}
                        expandSide={this.props.expandSide}
                        sideWidth={this.props.sideWidth}
                      />
                      {this.state.socActiveRadio === 'Nbudget' &&
                      checkUserEntitlements('ESS:UsageHeatmap') &&
                      this.state.usgSocHeatmapData !== null ? (
                        clickActive ? (
                          <Heatmap
                            width={this.props.width}
                            data={this.state.usgSocHeatmapData}
                            dateSelection={urlParams.get('cdty')}
                            label="Hours"
                            onBlockClick={this.props.onHeatmapBlockClickHandler}
                          />
                        ) : (
                          <p className="weekCheckError">
                            To view heatmap, please select the date range
                            minimum of 6 days.
                          </p>
                        )
                      ) : null}
                    </Col>
                  </Row>
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </>
          </Tab>
        )}
        {/** Power */}
        {checkUserEntitlements('ESS:Product-Usage-Power', 'usgpow') && (
          <Tab
            eventKey="usgpow"
            title={
              <span className="tabIcon">
                {this.props.usgPowHistogramData !== null &&
                this.props.usgPowHistogramData.length > 0 ? (
                  this.props.usgPowHistogramData[0].sts === true ? (
                    <Warning />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <span className="tabtitle"> Power</span>
              </span>
            }>
            <>
              {/* {clickActive && (
                <div className="heatmap-switch">
                  <Form.Check
                    type="switch"
                    id="usgpow"
                    label=""
                    checked={this.props.usgpowShowHeatmap}
                    onChange={() =>
                      this.props.handleSwitchChange(urlParams.get("utb"))
                    }
                    title={
                      this.state.usgPowHeatmapData &&
                      this.state.usgPowHeatmapData.length === 0 &&
                      "Data Not Available"
                    }
                  />
                </div>
              )} */}
              {
                <div>
                  {this.props.loadingUsgSocHistogram === true ||
                  this.props.loadingUsgPowHeatmap ||
                  this.props.usgPowHistogramData === null ? (
                    sessionStorage.getItem('selectedProductDname') !== null ? (
                      sessionStorage
                        .getItem('selectedProductDname')
                        .includes('New_Product') === false ? (
                        <Loading loadintText="Loading data" />
                      ) : (
                        <Col md={12} className="addMarginTop nodatafound">
                          <div> Sorry...</div>
                          <div className="addMarginTop">No data found !</div>
                        </Col>
                      )
                    ) : (
                      <Loading loadintText="Loading data" />
                    )
                  ) : this.props.usgPowHistogramData !== null &&
                    this.props.usgPowHistogramData.length > 0 ? (
                    <Row>
                      <Col md={12} className="noLeftpadding noRightpadding">
                        <Form>
                          <div
                            key={`usgpow`}
                            className="mb-3 usage-graph-radio">
                            <>
                              <Form.Check
                                inline
                                label="Designed budget"
                                name="usgpow"
                                type="radio"
                                id={`usgpow-1`}
                                value={'Dbudget'}
                                onChange={e =>
                                  this.handleRadioChange(
                                    e.currentTarget.value,
                                    'powActiveRadio'
                                  )
                                }
                                checked={
                                  this.state.powActiveRadio === 'Dbudget'
                                }
                              />
                              <Form.Check
                                inline
                                label="Normalized designed budget"
                                name="usgpow"
                                type="radio"
                                id={`usgpow-2`}
                                value={'Nbudget'}
                                onChange={e =>
                                  this.handleRadioChange(
                                    e.currentTarget.value,
                                    'powActiveRadio'
                                  )
                                }
                                checked={
                                  this.state.powActiveRadio === 'Nbudget'
                                }
                              />
                            </>
                          </div>
                        </Form>

                        <ContentWithChart
                          ActiveRadio={this.state.powActiveRadio}
                          usgHistogramData={this.props.usgPowHistogramData}
                          activeTab={this.props.selectedUsageTab}
                          expandSide={this.props.expandSide}
                          sideWidth={this.props.sideWidth}
                        />
                        {this.state.powActiveRadio === 'Nbudget' &&
                        checkUserEntitlements('ESS:UsageHeatmap') &&
                        this.state.usgPowHeatmapData !== null ? (
                          clickActive ? (
                            <Heatmap
                              width={this.props.width}
                              data={this.state.usgPowHeatmapData}
                              dateSelection={urlParams.get('cdty')}
                              label="Throughput"
                              onBlockClick={
                                this.props.onHeatmapBlockClickHandler
                              }
                            />
                          ) : (
                            <p className="weekCheckError">
                              To view heatmap, please select the date range
                              minimum of 6 days.
                            </p>
                          )
                        ) : null}
                      </Col>
                    </Row>
                  ) : (
                    <Col md={12} className="addMarginTop nodatafound">
                      <div> Sorry...</div>
                      <div className="addMarginTop">No data found !</div>
                    </Col>
                  )}
                </div>
              }
            </>
          </Tab>
        )}
        {/** DOD */}
        {checkUserEntitlements('ESS:Product-Usage-DOD', 'usgdod') && (
          <Tab
            eventKey="usgdod"
            title={
              <span className="tabIcon ">
                {this.props.usgDodHistogramData !== null &&
                this.props.usgDodHistogramData.length > 0 ? (
                  this.props.usgDodHistogramData[0].sts === true ? (
                    <Warning />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <span className="tabtitle usgsubtabtitle">
                  Depth of discharge
                </span>
              </span>
            }>
            {/* <Col md={12} className="addMarginTop nodatafound">
            <div> Sorry...</div>
            <div className="addMarginTop boldText">
              Section under development !
            </div>
          </Col> */}
            <>
              {/* {clickActive && (
                <div className="heatmap-switch">
                  <Form.Check
                    type="switch"
                    id="usgdod"
                    label=""
                    checked={this.props.usgdodShowHeatmap}
                    onChange={() =>
                      this.props.handleSwitchChange(urlParams.get("utb"))
                    }
                    title={
                      this.state.usgDodHeatmapData &&
                      this.state.usgDodHeatmapData.length === 0 &&
                      "Data Not Available"
                    }
                  />
                </div>
              )} */}

              <div>
                {this.props.loadingUsgDodHistogram === true ||
                this.props.loadingUsgDodHeatmap ||
                this.props.usgDodHistogramData === null ? (
                  sessionStorage.getItem('selectedProductDname') !== null ? (
                    sessionStorage
                      .getItem('selectedProductDname')
                      .includes('New_Product') === false ? (
                      <Loading loadintText="Loading data" />
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">No data found !</div>
                      </Col>
                    )
                  ) : (
                    <Loading loadintText="Loading data" />
                  )
                ) : this.props.usgDodHistogramData !== null &&
                  this.props.usgDodHistogramData.length > 0 ? (
                  <Row>
                    <Col md={12} className="noLeftpadding noRightpadding">
                      <Form>
                        <div key={`usgdod`} className="mb-3 usage-graph-radio">
                          <>
                            <Form.Check
                              inline
                              label="Designed budget"
                              name="group1"
                              type="radio"
                              id={`usgdod-1`}
                              value={'Dbudget'}
                              onChange={e =>
                                this.handleRadioChange(
                                  e.currentTarget.value,
                                  'deptActiveRadio'
                                )
                              }
                              checked={this.state.deptActiveRadio === 'Dbudget'}
                            />
                            <Form.Check
                              inline
                              label="Normalized designed budget"
                              name="usgdod"
                              type="radio"
                              id={`usgdod-2`}
                              value={'Nbudget'}
                              onChange={e =>
                                this.handleRadioChange(
                                  e.currentTarget.value,
                                  'deptActiveRadio'
                                )
                              }
                              checked={this.state.deptActiveRadio === 'Nbudget'}
                            />
                          </>
                        </div>
                      </Form>

                      <ContentWithChart
                        ActiveRadio={this.state.deptActiveRadio}
                        usgHistogramData={this.props.usgDodHistogramData}
                        activeTab={this.props.selectedUsageTab}
                        expandSide={this.props.expandSide}
                        sideWidth={this.props.sideWidth}
                      />
                      {this.state.deptActiveRadio === 'Nbudget' &&
                      checkUserEntitlements('ESS:UsageHeatmap') &&
                      this.state.usgDodHeatmapData !== null ? (
                        clickActive ? (
                          <Heatmap
                            width={this.props.width}
                            data={this.state.usgDodHeatmapData}
                            dateSelection={urlParams.get('cdty')}
                            label="Throughput"
                            onBlockClick={this.props.onHeatmapBlockClickHandler}
                          />
                        ) : (
                          <p className="weekCheckError">
                            To view heatmap, please select the date range
                            minimum of 6 days.
                          </p>
                        )
                      ) : null}
                    </Col>
                  </Row>
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </>
          </Tab>
        )}
      </Tabs>
    );
  }
}

export default withRouter(ProductUsagesTabs);
