import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import BTable from 'react-bootstrap/Table';
import { DebounceInput } from 'react-debounce-input';
import { withRouter } from 'react-router-dom';
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { ArrowDownIcon, ArrowUpIcon } from '../../assets/images';
import { updateState } from '../../utils/eventList-util';
import Loading from '../Loading';
import './styles.scss';

const Table = ({
  columns,
  data,
  pageCount: ControlledPageCount,
  setAllEventListData,
  eventData,
  loadingEventList,
}) => {
  const initialValuesFunc = () => {
    let val = [];
    if (new URL(window.location.href).searchParams.get('efty') !== null) {
      val = val.concat([
        {
          id: 'typ',
          value: new URL(window.location.href).searchParams.get('efty'),
        },
      ]);
    }
    if (new URL(window.location.href).searchParams.get('efr') !== null) {
      val = val.concat([
        {
          id: 'rsn',
          value: new URL(window.location.href).searchParams.get('efr'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('efm') !== null &&
      new URL(window.location.href).searchParams.get('efm') !== ''
    ) {
      val = val.concat([
        {
          id: 'ms',
          value: new URL(window.location.href).searchParams.get('efm'),
        },
      ]);
    }
    if (new URL(window.location.href).searchParams.get('efvn') !== null) {
      val = val.concat([
        {
          id: 'vn',
          value: new URL(window.location.href).searchParams.get('efvn'),
        },
      ]);
    }
    if (new URL(window.location.href).searchParams.get('efpn') !== null) {
      val = val.concat([
        {
          id: 'pt',
          value: new URL(window.location.href).searchParams.get('efpn'),
        },
      ]);
    }
    if (new URL(window.location.href).searchParams.get('efsn') !== null) {
      val = val.concat([
        {
          id: 'esn',
          value: new URL(window.location.href).searchParams.get('efsn'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('ebpft') !== null &&
      new URL(window.location.href).searchParams.get('ebpft') !== ''
    ) {
      val = val.concat([
        {
          id: 'bp',
          value: new URL(window.location.href).searchParams.get('ebpft'),
        },
      ]);
    }
    if (
      new URL(window.location.href).searchParams.get('efp') !== null &&
      new URL(window.location.href).searchParams.get('efp') !== ''
    ) {
      val = val.concat([
        {
          id: 'pun',
          value: new URL(window.location.href).searchParams.get('efp'),
        },
      ]);
    }
    return val;
  };

  const filterTypes = () => ({
    // Add a new fuzzyTextFilterFn filter type.
    // fuzzyText: fuzzyTextFilterFn,
    // Or, override the default text filter to use
    // "startWith"
    text: (page, id, filterValue) => {
      return page.filter(row => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
          : true;
      });
    },
  });

  // Define a default UI for filtering
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    const count = preFilteredRows.length;
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  };

  const defaultColumn = () => ({
    // Let's set up our default Filter UI
    Filter: DefaultColumnFilter,
  });

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, filters, sortBy },
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageIndex: Number(ControlledPageCount.cpg),
        pageSize: Number(eventData.pageSize),
        filters: initialValuesFunc(),
        sortBy: [
          {
            id:
              eventData.sortValueKey !== undefined
                ? eventData.sortValueKey
                : 'dt',
            desc:
              eventData.sortValue !== undefined ? eventData.sortValue : 'false',
          },
        ],
      },

      manualPagination: true,
      pageCount: ControlledPageCount?.tpg,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useExpanded,
    usePagination
  );
  const tbSearchParam = new URLSearchParams(window.location.search).get('tb');
  const ftSearchParam = new URLSearchParams(window.location.search).get('ft');
  const startItemIndex = pageIndex * pageSize + 1;
  const endItemIndex = Math.min(
    (pageIndex + 1) * pageSize,
    ControlledPageCount.ttl
  );
  useEffect(() => {
    setAllEventListData({
      pageSize: 20,
      pageIndex: ControlledPageCount.cpg,
      uniqueKey: updateState(filters, 'uniqueKey') || 'All',
      uniqueValue: updateState(filters, 'uniqueValue') || 'All',
      sortValue: sortBy.length > 0 && sortBy[0].desc,
      sortValueKey: sortBy.length > 0 && sortBy[0].id,
    });
  }, [
    ftSearchParam,
    updateState(filters, 'uniqueValue'),
    updateState(filters, 'uniqueKey'),
  ]);

  useEffect(() => {
    const addClassToThirdButton = () => {
      const buttons = document.querySelectorAll('.rightSide button');
      const pageIndex = Number(eventData.pageIndex);
      if (buttons.length >= 3 && pageIndex !== null) {
        buttons.forEach((button, index) => {
          const pageNumber = parseInt(button.textContent, 10);
          if (pageNumber === pageIndex + 1) {
            button.classList.add('activeButton');
          } else {
            button.classList.remove('activeButton');
          }
        });
      }
    };

    addClassToThirdButton();
    setAllEventListData({
      pageSize,
      pageIndex,
      uniqueKey: updateState(filters, 'uniqueKey') || 'All',
      uniqueValue: updateState(filters, 'uniqueValue') || 'All',
      sortValue: sortBy.length > 0 && sortBy[0].desc,
      sortValueKey: sortBy.length > 0 && sortBy[0].id,
    });
  }, [pageSize, pageIndex, sortBy]);

  useEffect(() => {
    setAllFilters([]);
  }, [tbSearchParam, ftSearchParam]);
  if (loadingEventList) {
    return <Loading loadintText="Loading data" />;
  }

  return (
    <>
      <button
        className={'reset-fill'}
        onClick={() => setAllFilters([])}
        id={'eventfilterresetClick'}>
        Reset
      </button>
      <BTable responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  key={i}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.isSorted ? 'active-sort' : 'sortablecon'}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span className={column.isSorted ? '' : 'sortable'}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <td className={'filterrow'} key={i}>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </td>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(page.length > 0 &&
            page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td key={i} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={1} className={'tdbordertop'}></td>
                      <td colSpan={12} className={'tdexpanded'}>
                        {row.cells[10].value}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })) || (
            <Col
              md={12}
              className="addMarginTop nodatafound"
              style={{
                position: 'absolute',
                transform: ' translate(0%, 50%)',
              }}>
              <div style={{ marginBottom: '-10px' }}> Sorry...</div>
              <div>No data found !</div>
            </Col>
          )}
        </tbody>
      </BTable>
      <div className="pagination">
        <div className="leftSide">
          <span>
            Showing {pageOptions.length > 0 ? startItemIndex : '0'} -{' '}
            {pageOptions.length > 0 ? endItemIndex : '0'} of ~
            {ControlledPageCount.ttl}
          </span>
        </div>
        <div className="rightSide">
          <span className="pagecount">
            Page &nbsp;{' '}
            <span>
              <DebounceInput
                minLength={1}
                debounceTimeout={1000}
                className="pageInput"
                type="text"
                value={pageOptions.length > 0 ? Number(pageIndex + 1) : '0'}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />{' '}
              of {pageOptions.length}
            </span>{' '}
          </span>
          <button
            onClick={() => gotoPage(0)}
            disabled={ControlledPageCount.ppg === 'No'}>
            {'<<'}
          </button>{' '}
          <button
            onClick={() => previousPage()}
            disabled={ControlledPageCount.ppg === 'No'}>
            {'<'}
          </button>{' '}
          {pageOptions.map((page, index) => {
            if (
              index === pageOptions.length - 1 ||
              (index >= pageIndex && index <= pageIndex + 4)
            ) {
              return (
                <React.Fragment key={index}>
                  {index === pageOptions.length - 1 ? (
                    <span>{index >= pageIndex + 6 ? '...' : ''}</span>
                  ) : (
                    index > 0 && null
                  )}
                  <button onClick={() => gotoPage(index)}>{index + 1}</button>
                </React.Fragment>
              );
            } else if (
              pageIndex >= pageOptions.length - 6 &&
              index >= pageOptions.length - 6
            ) {
              return (
                <React.Fragment key={index}>
                  <button
                    onClick={() => {
                      gotoPage(index);
                    }}>
                    {index + 1}
                  </button>
                </React.Fragment>
              );
            }
            return null;
          })}
          <button
            onClick={() => nextPage()}
            disabled={ControlledPageCount.npg === 'No'}>
            {'>'}
          </button>{' '}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={ControlledPageCount.npg === 'No'}>
            {'>>'}
          </button>{' '}
        </div>
      </div>
    </>
  );
};
export default withRouter(Table);
