import 'bootstrap-daterangepicker/daterangepicker.css';
import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FormattedMessage } from 'react-intl';
import {
  LeftArrowDate,
  Leftarrowdated,
  Rightarrowdate,
  Rightarrowdated,
} from '../../assets/images';

import { withRouter } from 'react-router-dom';
import { handleDateChange } from '../../utils/other';
import DateDiffText from '../DateDiffText';
import messages from './messages';
import './styles.scss';
Moment.locale('en');

let isValid;
class Datepicker extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      startDate: new Date(Moment().subtract(1, 'days').startOf('day')),
      startTime: new Date(Moment().subtract(1, 'days').startOf('day')),
      endDate: new Date(Moment().subtract(1, 'days').endOf('day')),
      endTime: new Date(Moment().subtract(1, 'days').endOf('day')),
      dateIndex: 1,
    };
    this.datepickerRef = React.createRef();
  }

  componentDidMount() {
    this.props.selectedStartDate != null &&
      this.props.selectedEndDate != null &&
      this.setState({
        startDate: new Date(this.props.selectedStartDate.replace(/%3A/g, ':')),
        startTime: new Date(this.props.selectedStartDate.replace(/%3A/g, ':')),
        endDate: new Date(this.props.selectedEndDate.replace(/%3A/g, ':')),
        endTime: new Date(this.props.selectedEndDate.replace(/%3A/g, ':')),
      });
    this.datepickerRef.current.setStartDate(
      new Date(this.props.selectedStartDate.replace(/%3A/g, ':'))
    );
    this.datepickerRef.current.setEndDate(
      new Date(this.props.selectedEndDate.replace(/%3A/g, ':'))
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      this.props.selectedStartDate !== newProps.selectedStartDate ||
      this.props.selectedEndDate !== newProps.selectedEndDate
    ) {
      newProps.selectedStartDate != null &&
        newProps.selectedEndDate != null &&
        this.setState({
          startDate: new Date(newProps.selectedStartDate.replace(/%3A/g, ':')),
          startTime: new Date(newProps.selectedStartDate.replace(/%3A/g, ':')),
          endDate: new Date(newProps.selectedEndDate.replace(/%3A/g, ':')),
          endTime: new Date(newProps.selectedEndDate.replace(/%3A/g, ':')),
        });
      this.datepickerRef.current.setStartDate(
        new Date(newProps.selectedStartDate.replace(/%3A/g, ':'))
      );
      this.datepickerRef.current.setEndDate(
        new Date(newProps.selectedEndDate.replace(/%3A/g, ':'))
      );
    }
    if (this.props.comnDateType !== newProps.comnDateType) {
      this.setState({ dateIndex: 1 });
    }
  }

  commonDate_onApply = (event, picker) => {
    const start = picker.startDate;
    const end = picker.endDate;
    let date1, time1, result, result1;
    date1 = Moment(start).format('MM DD,YYYY');
    time1 = Moment(start).format('HH:mm:ss');
    result = date1 + ' ' + time1;
    result = new Date(result);
    this.setState({ startDate: result, startTime: result });
    date1 = Moment(end).format('MM DD,YYYY');
    time1 = Moment(end).format('HH:mm:ss');
    time1 = time1 === '00:00:00' ? '23:59:59' : time1;
    result1 = date1 + ' ' + time1;
    result1 = new Date(result1);
    this.setState({ endDate: result1, endTime: result1 });
    this.props.applyButtonEnable(false);
    this.applyDateValidation(result, result1);
  };

  commonDate_callback = (start, end, label) => {
    let date1, time1, result;
    date1 = Moment(start).format('MM DD,YYYY');
    time1 = Moment(start).format('HH:mm:ss');
    result = date1 + ' ' + time1;
    result = new Date(result);
    this.setState({ startDate: result, startTime: result });
    date1 = Moment(end).format('MM DD,YYYY');
    time1 = Moment(end).format('HH:mm:ss');
    result = date1 + ' ' + time1;
    result = new Date(result);
    this.setState({ endDate: result, endTime: result });
    this.props.applyButtonEnable(false);
    this.applyDateValidation();
  };

  handleDatePickerChange = (name, value) => {
    let date1, time1, result;
    if (name === 'startDate') {
      date1 = Moment(value).format('MM DD,YYYY');
      time1 = Moment(this.state.startTime).format('HH:mm:ss');
      result = date1 + ' ' + time1;
      result = new Date(result);
      this.setState({ startDate: result, startTime: result });
    } else if (name === 'startTime') {
      date1 = Moment(this.state.startDate).format('MM DD,YYYY');
      time1 = Moment(value).format('HH:mm:ss');
      result = date1 + ' ' + time1;
      result = new Date(result);
      this.setState({ startDate: result, startTime: result });
    } else if (name === 'endDate') {
      date1 = Moment(value).format('MM DD,YYYY');
      time1 = Moment(this.state.endTime).format('HH:mm:ss');
      result = date1 + ' ' + time1;
      result = new Date(result);
      this.setState({ endDate: result, endTime: result });
    } else if (name === 'endTime') {
      date1 = Moment(this.state.endDate).format('MM DD,YYYY');
      time1 = Moment(value).format('HH:mm:ss');
      result = date1 + ' ' + time1;
      result = new Date(result);
      this.setState({ endDate: result, endTime: result });
    }

    this.props.applyButtonEnable(false);
  };

  checkDateValidation = () => {
    // check the dates
    const diff = Moment(
      new Date(
        this.state.endDate.getFullYear(),
        this.state.endDate.getMonth(),
        this.state.endDate.getDate(),
        this.state.endTime.getHours(),
        this.state.endTime.getMinutes()
      )
    ).diff(
      Moment(
        new Date(
          this.state.startDate.getFullYear(),
          this.state.startDate.getMonth(),
          this.state.startDate.getDate(),
          this.state.startTime.getHours(),
          this.state.startTime.getMinutes()
        )
      )
    );
    const d = Moment.duration(diff);

    const days = Math.floor(d.asHours() / 24);
    const validateDays = d.asHours() / 24;
    const hours = Math.floor(d.asHours() - days * 24);
    const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));

    if (validateDays === 0 && hours === 0 && minutes === 0) {
      return false;
    } else if (validateDays < 0 || hours < 0 || minutes < 0) {
      return false;
    } else if (validateDays > 90) {
      return false;
    } else {
      return true;
    }
  };

  applyDateValidation = (startdate, endDate) => {
    this.props.onApplyClick(startdate, endDate, 'custom');
    this.props.applyButtonEnable(true);
  };

  render() {
    const newDate =
      handleDateChange(
        new URL(window.location.href).searchParams.get('pickerValue')
      ) === 'Year' &&
      Number(new URL(window.location.href).searchParams.get('pickerValue')) ===
        new Date().getFullYear()
        ? new Date()
        : this.state.endDate;
    isValid = this.checkDateValidation();
    return (
      <React.Fragment>
        <Row className="datePickerContainer">
          <Col
            className={
              this.props.leftrightBtnDisable === true ||
              this.props.comnDateType === 'Custom'
                ? 'noLeftpadding noRightpadding daterange-Backbtn disabled'
                : 'noLeftpadding noRightpadding daterange-Backbtn'
            }>
            <button
              className="btn btn-link btn-sm"
              onClick={e => {
                this.setState(
                  {
                    dateIndex: this.state.dateIndex + 1,
                  },
                  () =>
                    this.props.onLeftRightBtnClick('left', this.state.dateIndex)
                );
              }}>
              {this.props.leftrightBtnDisable === true ||
              this.props.comnDateType === 'Custom' ? (
                <Leftarrowdated title="Backward" />
              ) : (
                <LeftArrowDate title="Backward" />
              )}
            </button>
          </Col>
          <Col
            className={
              this.props.comnDateType === 'Custom'
                ? 'datePickerTextbox noLeftpadding noRightpadding date-picker-datemax-width'
                : 'datePickerTextbox noLeftpadding noRightpadding date-picker-datemax-width'
            }>
            <DateRangePicker
              ref={this.datepickerRef}
              onApply={this.commonDate_onApply}
              // onCallback={this.commonDate_callback}
              initialSettings={{
                showDropdowns: true,
                maxDate: Moment(
                  new Date(Moment().subtract(0, 'days').endOf('day'))
                ).format('MM/DD/YYYY'),
              }}>
              <span className="timevalue" data-intro="date-selection">
                <input
                  value={
                    Moment(this.state.startDate).format('DD.MM.YYYY') +
                    ' - ' +
                    Moment(newDate).format('DD.MM.YYYY')
                  }
                  className="datepickervaluetimetrend"
                  readOnly
                />
              </span>
            </DateRangePicker>
            <div className="error-message-time">
              {!isValid &&
              this.props.comnDateType === 'Custom' &&
              this.props.selectedTab === 'Timetrend-vehicleprod-view' ? (
                <FormattedMessage {...messages.problem} />
              ) : (
                ''
              )}
            </div>
          </Col>
          <Col
            className={
              this.props.leftrightBtnDisable === true ||
              this.state.dateIndex === 1 ||
              this.props.comnDateType === 'Custom'
                ? 'noLeftpadding noRightpadding daterange-Forwardbtn disabled'
                : 'noLeftpadding noRightpadding daterange-Forwardbtn'
            }>
            <button
              className="btn btn-link btn-sm"
              onClick={e => {
                this.setState(
                  {
                    dateIndex: this.state.dateIndex - 1,
                  },
                  () =>
                    this.props.onLeftRightBtnClick(
                      'right',
                      this.state.dateIndex
                    )
                );
              }}>
              {this.props.leftrightBtnDisable === true ||
              this.state.dateIndex === 1 ||
              this.props.comnDateType === 'Custom' ? (
                <Rightarrowdated title="Forward" />
              ) : (
                <Rightarrowdate title="Forward" />
              )}
            </button>
          </Col>

          <Col className=" datediff  noLeftpadding  noRightpadding boldText">
            <span className="duration">
              <DateDiffText
                startdate={
                  new Date(
                    this.state.startDate.getFullYear(),
                    this.state.startDate.getMonth(),
                    this.state.startDate.getDate(),
                    this.state.startTime.getHours(),
                    this.state.startTime.getMinutes()
                  )
                }
                enddate={
                  new Date(
                    this.state.endDate.getFullYear(),
                    this.state.endDate.getMonth(),
                    this.state.endDate.getDate(),
                    this.state.endTime.getHours(),
                    this.state.endTime.getMinutes()
                  )
                }
                comnDateType={this.props.comnDateType}
                selectedTab={this.props.selectedTab}
              />
            </span>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default withRouter(Datepicker);
