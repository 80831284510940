export const FETCH_OVERALL_CONDITION_DATA = 'FETCH_OVERALL_CONDITION_DATA';
export const FETCH_OVERALL_CONDITION_DATA_SUCCESS =
  'FETCH_OVERALL_CONDITION_DATA_SUCCESS';
export const FETCH_OVERALL_CONDITION_DATA_FAILURE =
  'FETCH_OVERALL_CONDITION_DATA_FAILURE';

export const FETCH_OVERVIEW_DATA = 'FETCH_OVERVIEW_DATA';
export const FETCH_OVERVIEW_DATA_SUCCESS = 'FETCH_OVERVIEW_DATA_SUCCESS';
export const FETCH_OVERVIEW_DATA_FAILURE = 'FETCH_OVERVIEW_DATA_FAILURE';

export const FETCH_HISTOGRAM_DATA = 'FETCH_HISTOGRAM_DATA';
export const FETCH_HISTOGRAM_DATA_SUCCESS = 'FETCH_HISTOGRAM_DATA_SUCCESS';
export const FETCH_HISTOGRAM_DATA_FAILURE = 'FETCH_HISTOGRAM_DATA_FAILURE';

export const FETCH_BOXPLOT_DATA = 'FETCH_BOXPLOT_DATA';
export const FETCH_BOXPLOT_DATA_SUCCESS = 'FETCH_BOXPLOT_DATA_SUCCESS';
export const FETCH_BOXPLOT_DATA_FAILURE = 'FETCH_BOXPLOT_DATA_FAILURE';

export const FETCH_FLEETSLIST = 'FETCH_FLEETSLIST';
export const FETCH_FLEETSLIST_SUCCESS = 'FETCH_FLEETSLIST_SUCCESS';
export const FETCH_FLEETSLIST_FAILURE = 'FETCH_FLEETSLIST_FAILURE';

export const FETCH_VEHICLESLIST = 'FETCH_VEHICLESLIST';
export const FETCH_VEHICLESLIST_SUCCESS = 'FETCH_VEHICLESLIST_SUCCESS';
export const FETCH_VEHICLESLIST_FAILURE = 'FETCH_VEHICLESLIST_FAILURE';

export const FETCH_VEHICLEPRODUCTLIST = 'FETCH_VEHICLEPRODUCTLIST';
export const FETCH_VEHICLEPRODUCTLIST_SUCCESS =
  'FETCH_VEHICLEPRODUCTLIST_SUCCESS';
export const FETCH_VEHICLEPRODUCTLIST_FAILURE =
  'FETCH_VEHICLEPRODUCTLIST_FAILURE';

export const FETCH_MODULELIST = 'FETCH_MODULELIST';
export const FETCH_MODULELIST_SUCCESS = 'FETCH_MODULELIST_SUCCESS';
export const FETCH_MODULELIST_FAILURE = 'FETCH_MODULELIST_FAILURE';

export const SET_FLEETNAME = 'SET_FLEETNAME';
export const SET_VEHICLENAME = 'SET_VEHICLENAME';

export const SET_DATE = 'SET_DATE';

export const CLEAR_CHARTDATA = 'CLEAR_CHARTDATA';

export const SET_LIST = 'SET_LIST';

export const FETCH_UNMOUNTEDESS_LIST = 'FETCH_UNMOUNTEDESS_LIST';
export const FETCH_UNMOUNTEDESS_LIST_SUCCESS =
  'FETCH_UNMOUNTEDESS_LIST_SUCCESS';
export const FETCH_UNMOUNTEDESS_LIST_FAILURE =
  'FETCH_UNMOUNTEDESS_LIST_FAILURE';

export const FETCH_FLEET_STATISTICS_DATA = 'FETCH_FLEET_STATISTICS_DATA';
export const FETCH_FLEET_STATISTICS_DATA_SUCCESS =
  'FETCH_FLEET_STATISTICS_DATA_SUCCESS';
export const FETCH_FLEET_STATISTICS_DATA_FAILURE =
  'FETCH_FLEET_STATISTICS_DATA_FAILURE';

export const FETCH_DIAGNOSTICLIST = 'FETCH_DIAGNOSTICLIST';
export const FETCH_DIAGNOSTICLIST_SUCCESS = 'FETCH_DIAGNOSTICLIST_SUCCESS';
export const FETCH_DIAGNOSTICLIST_FAILURE = 'FETCH_DIAGNOSTICLIST_FAILURE';
export const SET_DIAGNOSTIC_EVENT_LIST_DATA = 'SET_DIAGNOSTIC_EVENT_LIST_DATA';
export const SET_PLATFORM_VERSION_NUMBER = 'SET_PLATFORM_VERSION_NUMBER';

export const FETCH_FLEET_USAGE_DETAILS = 'FETCH_FLEET_USAGE_DETAILS';
export const FETCH_FLEET_USAGE_DETAILS_SUCCESS =
  'FETCH_FLEET_USAGE_DETAILS_SUCCESS';
export const FETCH_FLEET_USAGE_DETAILS_FAILURE =
  'FETCH_FLEET_USAGE_DETAILS_FAILURE';
