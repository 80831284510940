import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { RightDirectionArrow } from '../../assets/images';
import { checkUserEntitlements } from '../../userConfig';
import { IconInfo } from '../Icons';
import {
  basicNavStepChange,
  basicNavigationSteps,
} from './basicNavigationSteps';
import { userGuideInfo } from './data';
import {
  fleetStatisticsStepChange,
  fleetStatisticsSteps,
} from './fleetStatistics';
import {
  productReliabilityStepChange,
  productReliabilitySteps,
} from './productReliability';
import { productSOHStepChange, productSOHSteps } from './productSOH';
import {
  productUtilizationStepChange,
  productUtilizationSteps,
} from './productUtilization';
import {
  productUsageHistogramStepChange,
  productUsageHistogramSteps,
} from './productUsageHistogram';

import './styles.scss';

/**
 * Renders a modal component for selecting a user guide.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal is visible or not.
 * @param {Function} props.handleClose - The function to handle closing the modal.
 * @param {Function} props.handleStartGuide - The function to handle starting the user guide.
 * @returns {JSX.Element} The UserGuide component.
 */
function UserGuide({ show, handleClose, handleStartGuide, fleetData }) {
  const [selectedFleet, setSelectedFleet] = useState({});
  const handleClick = guide => {
    if (
      selectedFleet.nm === '' ||
      selectedFleet.nm === undefined ||
      selectedFleet.nm === null
    ) {
      alert('Please select a fleet');
    } else {
      switch (guide) {
        case 'Basic Navigation':
          handleStartGuide(basicNavigationSteps(selectedFleet), step =>
            basicNavStepChange(step, selectedFleet)
          );
          break;
        case 'Product Utilization':
          handleStartGuide(productUtilizationSteps(selectedFleet), step =>
            productUtilizationStepChange(step, selectedFleet)
          );
          break;
        case 'Traction Battery SoH':
          handleStartGuide(productSOHSteps(selectedFleet), step =>
            productSOHStepChange(step, selectedFleet)
          );
          break;
        case 'Product Reliability':
          handleStartGuide(productReliabilitySteps(selectedFleet), step =>
            productReliabilityStepChange(step, selectedFleet)
          );
          break;
        case 'Fleet Statistics':
          handleStartGuide(fleetStatisticsSteps(selectedFleet), step =>
            fleetStatisticsStepChange(step, selectedFleet)
          );
          break;
        case 'Product Usage Histogram':
          handleStartGuide(productUsageHistogramSteps(selectedFleet), step =>
            productUsageHistogramStepChange(step, selectedFleet)
          );
          break;
        default:
          break;
      }
      handleClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      size="lg">
      <Modal.Header
        style={{
          border: 'none',
          marginTop: '15px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}>
        <Modal.Title>
          <h5 className="user_guide_title">Select user guide</h5>
        </Modal.Title>
        <div className="user_guide_sub_title">
          <IconInfo color={'#0051FF'} />
          <div>
            By selecting the user guide, the portal will display and navigate
            you through its features directly from the Dashboard page.
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modalContent">
        {fleetData && fleetData.length > 0 && (
          <>
            <Form.Group>
              <Form.Label>Choose Fleet </Form.Label>
              <Form.Control
                as="select"
                type="select"
                className="custom-select"
                placeholder="Please select"
                // disabled
                onChange={e => {
                  if (
                    fleetData.filter(item => item.nm === e.target.value)
                      .length > 0
                  ) {
                    setSelectedFleet(
                      fleetData.filter(item => item.nm === e.target.value)[0]
                    );
                  } else {
                    setSelectedFleet({ nm: '' });
                  }
                }}
                value={selectedFleet.nm}
                required>
                <option key="select" value="">
                  select fleet
                </option>
                {fleetData.map(fleet => (
                  <option key={fleet.nm} value={fleet.nm}>
                    {fleet.dnm}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </>
        )}
        {userGuideInfo
          .filter(item => {
            return (
              item.entitlement === '' || checkUserEntitlements(item.entitlement)
            );
          })
          .map((item, idx) => (
            <div
              key={idx}
              className="guide_item"
              onClick={() => handleClick(item.name)}>
              <div>{item.name}</div>
              <div>
                <RightDirectionArrow
                  style={{ width: '14px', height: '14px' }}
                />
              </div>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <button className="btn_close" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserGuide;
