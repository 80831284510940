import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProductSetupForm from '../ProductSetupForm';
import SideMenuFleetSetup from '../SideMenuFleetSetup';
import SiteSetupForm from '../SiteSetupForm';
import './styles.scss';

class FleetSetupTabs extends React.Component {
  render() {
    return (
      <div className="tabfleetsetupview">
        <Row className="addMarginTop">
          <Col md={3} className="sideMenucon  noRightpadding ">
            <SideMenuFleetSetup
              loadingSetupSidemenuList={this.props.loadingSetupSidemenuList}
              setupSidemenuList={this.props.setupSidemenuList}
              fleetList={this.props.fleetList}
              handleFleetSelect={this.props.handleFleetSelect}
              handleSearchClear={this.props.handleSearchClear}
              handleSiteClick={this.props.handleSiteClick}
              handleFleetClick={this.props.handleFleetClick}
              selectedSite={this.props.selectedSite}
              selectedFleet={this.props.selectedFleet}
              addSiteActive={this.props.addSiteActive}
              addFleetActive={this.props.addFleetActive}
              setupSiteList={this.props.setupSiteList}
              loadingSetupSiteList={this.props.loadingSetupSiteList}
              loadingSetupFleetList={this.props.loadingSetupFleetList}
              setupFleetList={this.props.setupFleetList}
              handleProdFleetClick={this.props.handleProdFleetClick}
            />
          </Col>
          <Col className="detailViewRightinner">
            {this.props.loadingPrdtSetupValues ||
              this.props.loadingSetupSidemenuList ||
              (this.props.loadingSetupSiteList && (
                <div id="transp">
                  <span id="transpp">Loading...</span>
                </div>
              ))}
            {this.props.loadingPrdtInfoSetupValues ||
              this.props.loadingPrdtSetupValues ||
              (this.props.loadingSetupSiteList && (
                <div id="transp">
                  <span id="transpp">Loading...</span>
                </div>
              ))}
            {this.props.loadingPrdtInfoSetupValues && (
              <div id="transp">
                <span id="transpp">Loading...</span>
              </div>
            )}
            {this.props.fleetFormVisible && (
              <ProductSetupForm
                onUpdateNotifications={this.props.onUpdateNotifications}
                onDeleteNotification={this.props.onDeleteNotification}
                onAddNotification={this.props.onAddNotification}
                handleContractorFormChange={
                  this.props.handleContractorFormChange
                }
                handleNotificationChange={this.props.handleNotificationChange}
                selectedFleet={this.props.selectedFleet}
                handleFleetInputChange={this.props.handleFleetInputChange}
                handleFleetSubmit={this.props.handleFleetSubmit}
                handleFleetReset={this.props.handleFleetReset}
                fleetFormValues={this.props.fleetFormValues}
                handleFleetDropdownChange={this.props.handleFleetDropdownChange}
                loadingsavefleet={this.props.loadingsavefleet}
                savefleetresponce={this.props.savefleetresponce}
                handleFleetCheckboxChange={this.props.handleFleetCheckboxChange}
                editFleet={this.props.editFleet}
                productSetupFormValues={this.props.productSetupFormValues}
                vehicleSetupFormValues={this.props.vehicleSetupFormValues}
                handleProdInputChange={this.props.handleProdInputChange}
                handleVehicleInputChange={this.props.handleVehicleInputChange}
                handleSohModelInputChange={this.props.handleSohModelInputChange}
                handleProdSubmit={this.props.handleProdSubmit}
                selectedProdSetupUsgsTab={this.props.selectedProdSetupUsgsTab}
                onProdSetupUsgsTabClick={this.props.onProdSetupUsgsTabClick}
                handleProdReset={this.props.handleProdReset}
                loadingproductSetup={this.props.loadingproductSetup}
                saveProductSetupResponce={this.props.loadingproductSetup}
                loadingproductInfoSetup={this.props.loadingproductInfoSetup}
                setupFleetList={this.props.setupFleetList}
                usedFleetNameList={this.props.usedFleetNameList}
                handleFleetDataPath={this.props.handleFleetDataPath}
                fleetDataPathValidate={this.props.fleetDataPathValidate}
                loadingFleetDataPathValidate={
                  this.props.loadingFleetDataPathValidate
                }
                fleetSetupData={this.props.fleetSetupData}
                data={this.props.data}
                activeTab={this.props.activeTab}
                onTabChange={this.props.onTabChange}
                handleInputChange={this.props.handleInputChange}
                handleSwitchChange={this.props.handleSwitchChange}
                setColor={this.props.setColor}
                handleReset={this.props.handleReset}
                activeTabName={this.props.activeTabName}
                sohStressActiveTab={this.props.sohStressActiveTab}
                onAddRow={this.props.onAddRow}
                onDeleteRow={this.props.onDeleteRow}
                onInputChange={this.props.onInputChange}
                commonDate_onApply={this.props.commonDate_onApply}
              />
            )}
            {this.props.siteFormVisible && (
              <SiteSetupForm
                selectedSite={this.props.selectedSite}
                handleSiteInputChange={this.props.handleSiteInputChange}
                handleSiteSubmit={this.props.handleSiteSubmit}
                siteFormValues={this.props.siteFormValues}
                handleSiteReset={this.props.handleSiteReset}
                handleSiteAddressChange={this.props.handleSiteAddressChange}
                loadingsavesite={this.props.loadingsavesite}
                savesiteresponce={this.props.savesiteresponce}
                editSite={this.props.editSite}
                onPlaceSelected={this.props.onPlaceSelected}
                setupSidemenuList={this.props.setupSidemenuList}
                setupSiteList={this.props.setupSiteList}
                loadingSetupSidemenuList={this.props.loadingSetupSidemenuList}
                loadingSetupSiteList={this.props.loadingSetupSiteList}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default FleetSetupTabs;
