import PropTypes from 'prop-types';
import React from 'react';

const IconFault = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-fault">
        <circle
          id="fill-Combined-Shape"
          fill={props.fill}
          cx="24"
          cy="23"
          r="17"
        />
        <circle id="Oval-2" fill="#FFFFFF" cx="24" cy="34" r="3" />
        <rect
          id="Rectangle-4"
          fill="#FFFFFF"
          x="22"
          y="10"
          width="4"
          height="18"
        />
      </g>
    </g>
  </svg>
);
IconFault.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconTemperature = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-temperature">
        <path
          d="M28,9 C28,9 28,5 24.5,5 C21,5 21,9 21,9 L21,26.7517393 C18.0514749,28.0857287 16,31.0532905 16,34.5 C16,39.1944204 19.8055796,43 24.5,43 C29.1944204,43 33,39.1944204 33,34.5 C33,31.0532905 30.9485251,28.0857287 28,26.7517393 L28,9 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
        />
        <path
          d="M19,17 L17,17 L17,15 L19,15 L19,12 L17,12 L17,10 L19,10 L19,9 C19,8.24854444 19.1458847,7.24819241 19.6051853,6.19836233 C20.4507694,4.26559875 22.1118678,3 24.5,3 C26.8881322,3 28.5492306,4.26559875 29.3948147,6.19836233 C29.8541153,7.24819241 30,8.24854444 30,9 L30,25.553744 C33.0585664,27.4350275 35,30.7909414 35,34.5 C35,40.2989899 30.2989899,45 24.5,45 C18.7010101,45 14,40.2989899 14,34.5 C14,30.7909414 15.9414336,27.4350275 19,25.553744 L19,22 L17,22 L17,20 L19,20 L19,17 Z M28,9 C28,9 28,5 24.5,5 C21,5 21,9 21,9 L21,26.7517393 C18.0514749,28.0857287 16,31.0532905 16,34.5 C16,39.1944204 19.8055796,43 24.5,43 C29.1944204,43 33,39.1944204 33,34.5 C33,31.0532905 30.9485251,28.0857287 28,26.7517393 L28,9 Z"
          id="fill-Combined-Shape"
          fill={props.fill ? props.fill : '#3C3C3C'}
        />
        <path
          d="M23,28.1739318 C20.1333,28.8510956 18,31.4264184 18,34.5 C18,38.0898509 20.9101491,41 24.5,41 C28.0898509,41 31,38.0898509 31,34.5 C31,31.4264184 28.8667,28.8510956 26,28.1739318 L26,18 L23,18 L23,28.1739318 Z"
          id="fill-Combined-Shape"
          fill={props.fill ? props.fill : '#3C3C3C'}
        />
      </g>
    </g>
  </svg>
);
IconTemperature.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconAvailability = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M28,26.7517393 C30.9485251,28.0857287 33,31.0532905 33,34.5 C33,39.1944204 29.1944204,43 24.5,43 C19.8055796,43 16,39.1944204 16,34.5 C16,31.0532905 18.0514749,28.0857287 21,26.7517393 L21,9 C21,9 21,5 24.5,5 C28,5 28,9 28,9 L28,26.7517393 Z"
        id="path-ava"
      />
      <mask
        id="mask-ava"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="-2"
        y="-2"
        width="21"
        height="42">
        <rect x="14" y="3" width="21" height="42" fill="white" />
        <use xlinkHref="#path-ava" fill="black" />
      </mask>
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-availability">
        <use
          id="Combined-Shape"
          stroke={props.fill}
          mask="url(#mask-ava)"
          strokeWidth="4"
          xlinkHref="#path-ava"
        />
        <rect
          id="Rectangle-3"
          fill={props.fill}
          x="17"
          y="20"
          width="4"
          height="2"
        />
        <rect
          id="Rectangle-3"
          fill={props.fill}
          x="17"
          y="10"
          width="4"
          height="2"
        />
        <rect
          id="Rectangle-3"
          fill={props.fill}
          x="17"
          y="15"
          width="4"
          height="2"
        />
        <circle id="Oval-2" fill={props.fill} cx="24.5" cy="34.5" r="6.5" />
        <rect
          id="Rectangle-2"
          fill={props.fill}
          x="23"
          y="18"
          width="3"
          height="13"
        />
      </g>
    </g>
  </svg>
);
IconAvailability.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconStress = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-stress">
        <polygon id="Path" fill="#FFFFFF" points="7 41 41 41 41 7 7 7" />
        <path
          d="M8,40 L8.006,23 L15.281,23 L19.06,33.342 C19.401,34.279 20.754,34.194 20.975,33.222 L25.156,14.938 L29.046,27.298 C29.303,28.122 30.413,28.255 30.857,27.514 L33.594,23 L40,23 L40,40 L8,40 Z M40,8 L40,21 L33,21 C32.649,21 32.323,21.184 32.143,21.485 L30.312,24.562 L25.954,10.702 C25.65,9.727 24.251,9.782 24.025,10.778 L19.781,29.406 L16.94,21.658 C16.796,21.263 16.421,21 16,21 L8.007,21 L8.012,8.012 L40,8 Z M6,42 L42,42 L42,6 L6,6 L6,42 Z"
          id="fill-Combined-Shape"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);

IconStress.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconReliability = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-reliability">
        <circle id="Combined-Shape" fill="#FFFFFF" cx="24" cy="23" r="16" />
        <path
          d="M24,39 C32.836556,39 40,31.836556 40,23 C40,14.163444 32.836556,7 24,7 C15.163444,7 8,14.163444 8,23 C8,31.836556 15.163444,39 24,39 Z M24,41 C14.0588745,41 6,32.9411255 6,23 C6,13.0588745 14.0588745,5 24,5 C33.9411255,5 42,13.0588745 42,23 C42,32.9411255 33.9411255,41 24,41 Z"
          id="fill-Combined-Shape"
          fill={props.fill}
          fillRule="nonzero"
        />
        <path
          d="M24,36 C31.1797017,36 37,30.1797017 37,23 L24,23 L24,10 C16.8202983,10 11,15.8202983 11,23 C11,30.1797017 16.8202983,36 24,36 Z"
          id="fill-Combined-Shape"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);
IconReliability.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconCalendar = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-commonux-calendar" fill={props.fill ? props.fill : '#464646'}>
        <g id="commonux-calendar-16" transform="translate(5.000000, 5.000000)">
          <g id="CommonUX_Calendar_16">
            <path
              d="M12,1 L12,0 L9,0 L9,1 L5,1 L5,0 L2,0 L2,1 L0,1 L0,15 L14,15 L14,1 L12,1 Z M10,1 L11,1 L11,3 L10,3 L10,1 Z M3,1 L4,1 L4,3 L3,3 L3,1 Z M13,14 L1,14 L1,5 L13,5 L13,14 Z M5,9 L3,9 L3,7 L5,7 L5,9 Z M8,9 L6,9 L6,7 L8,7 L8,9 Z M11,9 L9,9 L9,7 L11,7 L11,9 Z M8,12 L6,12 L6,10 L8,10 L8,12 Z M5,12 L3,12 L3,10 L5,10 L5,12 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconCalendar.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconFilter = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon id="123path-1" points="0 0 9 11 9 25 17 19 17 11 26 0" />
      <mask
        id="123mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="26"
        height="25"
        fill="white">
        <use xlinkHref="#123path-1" />
      </mask>
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-commonux-filter" stroke={props.fill ? props.fill : '#3C3C3C'}>
        <g id="commonux-filter-16" transform="translate(3.000000, 3.500000)">
          <g id="CommonUX_Filter_16">
            <use
              id="Shape"
              mask="url(#123mask-2)"
              strokeWidth="4"
              xlinkHref="#123path-1"
            />
            <path
              d="M10.5,11 L15.5,11"
              id="Line"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconFilter.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconClose = props => (
  <svg
    width={props.width ? props.width : '32'}
    height={props.height ? props.height : '32'}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g
      id="Icons"
      strokeWidth="1"
      fill={props.fill || 'none'}
      fillRule="evenodd"
      strokeLinecap="square">
      <g
        id="icon-commonux-close-idle"
        strokeWidth="3"
        stroke={props.stroke || '#3C3C3C'}>
        <g id="commonux-close-16" transform="translate(6.000000, 6.000000)">
          <g id="CommonUX_Close_16">
            <g id="Group-2">
              <path d="M0.5,0.5 L19.5,19.5" id="Line" />
              <path d="M0.5,19.5 L19.5,0.5" id="Line" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconClose.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
};

const IconHome = props => (
  <svg
    width={props.width ? props.width : '36'}
    height={props.height ? props.height : '36'}
    viewBox="0 0 36 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="icon-home-top"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="icon-home-middle"
        strokeWidth="2"
        stroke={props.fill ? props.fill : '#3C3C3C'}>
        <polygon
          id="Fill-1"
          points="25 28 19 28 19 21 13 21 13 28 7 28 7 18 4 18 16 4 28 18 25 18"
        />
      </g>
    </g>
  </svg>
);
IconHome.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconMultiSignal = props => (
  <svg
    width={props.width ? props.width : '32'}
    height={props.height ? props.height : '32'}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <rect id="11path-1" x="0" y="0" width="8" height="6" />
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="8"
        height="6"
        fill="white">
        <use xlinkHref="#11path-1" />
      </mask>
      <rect id="22path-3" x="10" y="0" width="8" height="6" />
      <mask
        id="mask-4"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="8"
        height="6"
        fill="white">
        <use xlinkHref="#22path-3" />
      </mask>
      <rect id="33path-5" x="20" y="0" width="8" height="6" />
      <mask
        id="mask-6"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="8"
        height="6"
        fill="white">
        <use xlinkHref="#33path-5" />
      </mask>
      <rect id="44path-7" x="0" y="8" width="8" height="6" />
      <mask
        id="mask-8"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="8"
        height="6"
        fill="white">
        <use xlinkHref="#44path-7" />
      </mask>
      <rect id="55path-9" x="10" y="8" width="8" height="6" />
      <mask
        id="mask-10"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="8"
        height="6"
        fill="white">
        <use xlinkHref="#55path-9" />
      </mask>
      <rect id="66path-11" x="20" y="8" width="8" height="6" />
      <mask
        id="mask-12"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="8"
        height="6"
        fill="white">
        <use xlinkHref="#66path-11" />
      </mask>
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-multi-signal-idle" stroke="#3C3C3C" strokeWidth="4">
        <g id="Group-2" transform="translate(2.000000, 9.000000)">
          <use id="Rectangle-61" mask="url(#mask-2)" xlinkHref="#11path-1" />
          <use id="Rectangle-61" mask="url(#mask-4)" xlinkHref="#22path-3" />
          <use id="Rectangle-61" mask="url(#mask-6)" xlinkHref="#33path-5" />
          <use id="Rectangle-61" mask="url(#mask-8)" xlinkHref="#44path-7" />
          <use id="Rectangle-61" mask="url(#mask-10)" xlinkHref="#55path-9" />
          <use id="Rectangle-61" mask="url(#mask-12)" xlinkHref="#66path-11" />
        </g>
      </g>
    </g>
  </svg>
);
IconMultiSignal.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconAlarm = props => (
  <svg
    width={props.width ? `${props.width}px` : '48px'}
    height={props.height ? `${props.height}px` : '48px'}
    viewBox={
      props.width && props.height
        ? `0 0 ${props.width} ${props.height}`
        : '0 0 48 48'
    }
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-email-alarm-dark" fill={props.fill ? props.fill : '#3C3C3C'}>
        <g
          id="commonux-alarmbell-16"
          transform="translate(11.500000, 8.000000)">
          <g id="CommonUX_AlarmBell_16">
            <path
              d="M16.5,28 C16.5,30.209139 14.709139,32 12.5,32 C10.290861,32 8.5,30.209139 8.5,28 L16.5,28 Z M22.5,18 C22.5,12.44 20.68,5.56 14.5,4.22 L14.5,2 C14.5,0.8954305 13.6045695,6.76353751e-17 12.5,0 C11.3954305,-6.76353751e-17 10.5,0.8954305 10.5,2 L10.5,4.22 C4.32,5.56 2.5,12.44 2.5,18 C2.5,21.66 0.5,22 0.5,22 L0.5,26 L24.5,26 L24.5,22 C24.5,22 22.5,21.66 22.5,18 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconAlarm.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconReport = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="icon-commonux-report-dark"
        fill={props.fill ? props.fill : '#3C3C3C'}>
        <g
          id="commonux-newdocument-16"
          transform="translate(8.000000, 8.000000)">
          <g id="CommonUX_NewDocument_16">
            <path
              d="M23,14 C18.0294373,14 14,18.0294373 14,23 C14,27.9705627 18.0294373,32 23,32 C27.9705627,32 32,27.9705627 32,23 C32,20.6130516 31.0517884,18.3238664 29.363961,16.636039 C27.6761336,14.9482116 25.3869484,14 23,14 L23,14 Z M28,24 L24,24 L24,28 L22,28 L22,24 L18,24 L18,22 L22,22 L22,18 L24,18 L24,22 L28,22 L28,24 Z"
              id="Shape"
            />
            <path
              d="M16,10 L8,10 L8,8 L16,8 L16,10 Z M13.26,16 L8,16 L8,18 L12.1,18 C12.4213919,17.2975507 12.8098612,16.6277761 13.26,16 L13.26,16 Z M18.22,12 L8,12 L8,14 L15.08,14 C16.0162515,13.1740465 17.0756643,12.4992612 18.22,12 Z M12.1,28 L4,28 L4,4 L16.6,4 L24,10 L24,11.12 C25.3846549,11.2390405 26.7381074,11.5977054 28,12.18 L28,8 L18,0 L0,0 L0,32 L15.08,32 C13.8174601,30.8901124 12.802197,29.5273431 12.1,28 L12.1,28 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconReport.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconAnalysis = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-analysis-dark">
        <g id="commonux-document-16" transform="translate(12.000000, 9.000000)">
          <g id="CommonUX_Document_16" />
        </g>
        <g
          id="commonux-document-16"
          transform="translate(10.000000, 8.000000)"
          fill={props.fill ? props.fill : '#3C3C3C'}>
          <g id="CommonUX_Document_16">
            <path
              d="M16.6,4 L24,10 L24,28 L4,28 L4,4 L16.6,4 L16.6,4 Z M18,0 L0,0 L0,32 L28,32 L28,8 L18,0 Z M20,14 L8,14 L8,16 L20,16 L20,14 Z M20,18 L8,18 L8,20 L20,20 L20,18 Z M16,10 L8,10 L8,12 L16,12 L16,10 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconAnalysis.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconEdit = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="icon-commonux-edit-dark"
        fill={props.fill ? props.fill : '#3C3C3C'}>
        <g id="commonux-edit-16" transform="translate(16.000000, 16.000000)">
          <g id="CommonUX_Edit_16">
            <path
              d="M13.8971429,5.47428571 L10.5257143,2.10285714 L12.6285714,8.8817842e-16 L16,3.37142857 L13.8971429,5.47428571 Z M9.26857143,3.37142857 L1.68,10.9485714 L0,16 L5.05142857,14.32 L12.6285714,6.74285714 L9.26857143,3.37142857 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconEdit.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconDone = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-done-dark" fill={props.fill ? props.fill : '#464646'}>
        <g
          id="commonux-checkmark-16"
          transform="translate(12.000000, 14.000000)">
          <g id="CommonUX_CheckMark_16">
            <polygon
              id="Shape"
              points="9.02 19 0.16 10.32 3.66 6.74 9 11.98 20.38 0.74 23.9 4.3"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconDone.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const AbbLogo = props => (
  <svg
    width={props.width ? props.width : '105.43'}
    height={props.height ? props.height : '41.38'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105.43 41.38">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#ed2024"
          d="M19.84,33.08H13.24l-2.9,8.3H0L6.93,21.54h12.9Zm0-13.24H7.5L14.49,0h5.34ZM21.54,0h5.34l7,19.84H21.54ZM41.38,41.38H31l-2.9-8.3H21.54V21.54h12.9Z"
        />
        <path
          fill="#ed2024"
          d="M44.22,19.84V0H55.81V19.84Zm11.59,1.71V41.38H44.22V21.54Zm1.71-1.71V0H60.8a8.92,8.92,0,0,1,6.56,2.73,9,9,0,0,1,2.72,6.59,8.82,8.82,0,0,1-4.72,8.13,12.7,12.7,0,0,1,3.86,2.39ZM73.21,29A12.16,12.16,0,0,1,60.8,41.38H57.52V21.54h13.2A12.19,12.19,0,0,1,73.21,29Z"
        />
        <path
          fill="#ed2024"
          d="M76.45,19.84V0H88V19.84ZM88,21.54V41.38H76.45V21.54Zm1.71-1.71V0H93a8.92,8.92,0,0,1,6.56,2.73,9,9,0,0,1,2.72,6.59,8.82,8.82,0,0,1-4.72,8.13,12.7,12.7,0,0,1,3.86,2.39ZM105.43,29A12.16,12.16,0,0,1,93,41.38H89.75V21.54h13.2A12.19,12.19,0,0,1,105.43,29Z"
        />
      </g>
    </g>
  </svg>
);
AbbLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconOptions = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%" />
        <stop stopColor="#000000" stopOpacity="0.5" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="icon-desktop-options-idle"
        fill={props.fill ? props.fill : '#3C3C3C'}>
        <path
          d="M38,21 L38,27 L32,27 L32,21 L38,21 Z M27,21 L27,27 L21,27 L21,21 L27,21 Z M16,21 L16,27 L10,27 L10,21 L16,21 Z"
          id="…"
          transform="translate(24.000000, 24.000000) scale(-1, -1) rotate(90.000000) translate(-24.000000, -24.000000) "
        />
      </g>
    </g>
  </svg>
);
IconOptions.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconCollapseButton = props => (
  <svg
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%" />
        <stop stopColor="#000000" stopOpacity="0.5" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square">
      <g id="icon-desktop-options-idle" stroke="#3C3C3C" strokeWidth="2">
        <g id="Group-2" transform="translate(12.000000, 14.000000)">
          <path d="M23.5,9 L0.5,9" id="Line" />
          <path d="M23.5,1 L0.5,1" id="Line" />
          <path d="M23.5,17 L0.5,17" id="Line" />
        </g>
      </g>
    </g>
  </svg>
);
IconCollapseButton.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconDownCaret = props => (
  <svg
    width={props.width ? props.width : '16'}
    height={props.height ? props.height : '16'}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-commonux-down" fillRule="nonzero" fill="#3C3C3C">
        <polygon
          id="Shape"
          points="3.41 4.295 8 8.875 12.59 4.295 14 5.705 8 11.705 2 5.705"
        />
      </g>
    </g>
  </svg>
);
IconDownCaret.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconAddFile = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="icon-commonux-report-dark"
        fill={props.fill ? props.fill : '#3C3C3C'}>
        <g
          id="commonux-newdocument-16"
          transform="translate(8.000000, 8.000000)">
          <g id="CommonUX_NewDocument_16">
            <path
              d="M23,14 C18.0294373,14 14,18.0294373 14,23 C14,27.9705627 18.0294373,32 23,32 C27.9705627,32 32,27.9705627 32,23 C32,20.6130516 31.0517884,18.3238664 29.363961,16.636039 C27.6761336,14.9482116 25.3869484,14 23,14 L23,14 Z M28,24 L24,24 L24,28 L22,28 L22,24 L18,24 L18,22 L22,22 L22,18 L24,18 L24,22 L28,22 L28,24 Z"
              id="Shape"
            />
            <path
              d="M16,10 L8,10 L8,8 L16,8 L16,10 Z M13.26,16 L8,16 L8,18 L12.1,18 C12.4213919,17.2975507 12.8098612,16.6277761 13.26,16 L13.26,16 Z M18.22,12 L8,12 L8,14 L15.08,14 C16.0162515,13.1740465 17.0756643,12.4992612 18.22,12 Z M12.1,28 L4,28 L4,4 L16.6,4 L24,10 L24,11.12 C25.3846549,11.2390405 26.7381074,11.5977054 28,12.18 L28,8 L18,0 L0,0 L0,32 L15.08,32 C13.8174601,30.8901124 12.802197,29.5273431 12.1,28 L12.1,28 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconAddFile.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconAsc = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 18.14 19.48"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style>
        .cls-1{'{fill:#398fb6;}'}
        .cls-2{'{fill:#727272;}'}
        .cls-3{'{fill:#3697d3;}'}
      </style>
    </defs>
    <title>icon-sort-up</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M10.57,17.84a.66.66,0,0,0-.66-.66H.66a.66.66,0,0,0-.66.66v.66a.66.66,0,0,0,.66.66H9.9a.66.66,0,0,0,.66-.66v-.66Zm0,0"
        />
        <path
          className="cls-1"
          d="M8.66,15a.66.66,0,0,0,.66-.66v-.66A.66.66,0,0,0,8.66,13h-8a.66.66,0,0,0-.66.66v.66A.66.66,0,0,0,.66,15Z"
        />
        <path className="cls-1" d="M8.66,4.18" />
        <path
          className="cls-1"
          d="M7.41,10.81a.66.66,0,0,0,.66-.66V9.48a.66.66,0,0,0-.66-.66H.66A.66.66,0,0,0,0,9.48v.66a.66.66,0,0,0,.66.66Z"
        />
        <path className="cls-1" d="M7.41,8.35" />
        <path
          className="cls-1"
          d="M6.17,6.17a.66.66,0,0,0,.66-.66V4.84a.66.66,0,0,0-.66-.66H.66A.66.66,0,0,0,0,4.84V5.5a.66.66,0,0,0,.66.66Z"
        />
        <path className="cls-1" d="M6.17,13" />
        <path
          className="cls-1"
          d="M4.92,2a.66.66,0,0,0,.66-.66V.66A.66.66,0,0,0,4.92,0H.66A.66.66,0,0,0,0,.66v.66A.66.66,0,0,0,.66,2Z"
        />
        <path className="cls-1" d="M4.92,2" />
        <path className="cls-2" d="M19.78,14.13" />
        <path
          className="cls-3"
          d="M8.58,5.68,9,6.14a.66.66,0,0,0,.94,0l2.28-2.28V18.81a.66.66,0,0,0,.66.66h.66a.66.66,0,0,0,.66-.66V3.86l2.28,2.28a.66.66,0,0,0,.94,0l.47-.47a.66.66,0,0,0,0-.94L13.73.53a.66.66,0,0,0-.94,0L8.58,4.74a.66.66,0,0,0,0,.94Z"
        />
      </g>
    </g>
  </svg>
);
IconAsc.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconDesc = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 18.14 19.48"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style>
        .cls-1{'{fill:#398fb6;}'}
        .cls-2{'{fill:#727272;}'}
        .cls-3{'{fill:#3697d3;}'}
      </style>
    </defs>
    <title>icon-sort-down</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M10.57,1.33V.66A.66.66,0,0,0,9.9,0H.66A.66.66,0,0,0,0,.66v.66A.66.66,0,0,0,.66,2H9.9a.66.66,0,0,0,.66-.66Zm0,0"
        />
        <path
          className="cls-1"
          d="M8.66,4.18h-8A.66.66,0,0,0,0,4.84V5.5a.66.66,0,0,0,.66.66h8a.66.66,0,0,0,.66-.66V4.84a.66.66,0,0,0-.66-.66Z"
        />
        <path className="cls-1" d="M8.66,4.18" />
        <path
          className="cls-1"
          d="M7.41,8.35H.66A.66.66,0,0,0,0,9v.66a.66.66,0,0,0,.66.66H7.41a.66.66,0,0,0,.66-.66V9a.66.66,0,0,0-.66-.66Z"
        />
        <path className="cls-1" d="M7.41,8.35" />
        <path
          className="cls-1"
          d="M6.17,13H.66a.66.66,0,0,0-.66.66v.66A.66.66,0,0,0,.66,15h5.5a.66.66,0,0,0,.66-.66v-.66A.66.66,0,0,0,6.17,13Z"
        />
        <path className="cls-1" d="M6.17,13" />
        <path
          className="cls-1"
          d="M4.92,17.17H.66a.66.66,0,0,0-.66.66v.66a.66.66,0,0,0,.66.66H4.92a.66.66,0,0,0,.66-.66v-.66a.66.66,0,0,0-.66-.66Z"
        />
        <path className="cls-1" d="M4.92,17.17" />
        <path className="cls-2" d="M19.78,14.13" />
        <path
          className="cls-3"
          d="M17.94,14.13l-.47-.47a.66.66,0,0,0-.94,0l-2.28,2.28V1a.66.66,0,0,0-.66-.66h-.66a.66.66,0,0,0-.66.66V15.94L10,13.66a.66.66,0,0,0-.94,0l-.47.47a.66.66,0,0,0,0,.94l4.21,4.21a.66.66,0,0,0,.94,0l4.21-4.21a.66.66,0,0,0,0-.94Z"
        />
      </g>
    </g>
  </svg>
);
IconDesc.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconSuccess = props => (
  <svg
    width={props.width}
    height={props.height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.6 21.6">
    <g id="Layer_icon-success1" data-name="Layer 2">
      <g id="Layer_icon-success2" data-name="Layer 1">
        <path
          fill={props.fill ? props.fill : '#FFFFFF'}
          d="M10.8,0A10.8,10.8,0,1,0,21.6,10.8,10.81,10.81,0,0,0,10.8,0Zm0,20.77a10,10,0,1,1,10-10,10,10,0,0,1-10,10Zm0,0"
        />
        <path
          fill={props.fill ? props.fill : '#FFFFFF'}
          d="M15.89,6.37,9.5,13.55,5.66,10.48a.42.42,0,1,0-.52.65l4.15,3.32a.42.42,0,0,0,.26.09.41.41,0,0,0,.31-.14l6.64-7.48a.42.42,0,1,0-.62-.55Zm0,0"
        />
      </g>
    </g>
  </svg>
);

IconSuccess.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconPrevious = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 35.56 35.56"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style>
        .cls-prev-1{`{fill:${props.fill ? props.fill : '#3697d3'}}`}
        .cls-prev-2{'{fill:#2d3033;}'}
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-prev-1"
          d="M0,17.78A17.78,17.78,0,1,0,17.78,0,17.8,17.8,0,0,0,0,17.78Zm1.44,0A16.34,16.34,0,1,1,17.78,34.12,16.36,16.36,0,0,1,1.44,17.78Z"
        />
        <path className="cls-prev-2" d="M17.78,1.44" />
        <path
          className="cls-prev-1"
          d="M20.31,23.69a.73.73,0,0,0,0-1l-4.89-4.89,4.89-4.89a.72.72,0,0,0-1-1l-5.4,5.4a.72.72,0,0,0,0,1l5.4,5.4a.72.72,0,0,0,1,0Z"
        />
        <path className="cls-prev-1" d="M20.31,23.69" />
      </g>
    </g>
  </svg>
);

IconPrevious.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconUnsuccessfull = props => (
  <svg
    width={props.width ? props.width : '21.92px'}
    height={props.height ? props.height : '21.93px'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.93 21.93">
    <defs />
    <g id="Layer_icon-unsuccessfull1" data-name="Layer 2">
      <g id="Layer_icon-unsuccessfull2" data-name="Layer 1">
        <path
          fill={props.fill ? props.fill : '#FFFFFF'}
          d="M11,0a11,11,0,1,0,11,11A11,11,0,0,0,11,0Zm0,21.09A10.12,10.12,0,1,1,21.09,11,10.13,10.13,0,0,1,11,21.09Zm0,0"
        />
        <path
          fill={props.fill ? props.fill : '#FFFFFF'}
          d="M15.06,6.87a.42.42,0,0,0-.6,0L11,10.37l-3.5-3.5a.42.42,0,1,0-.6.6l3.5,3.5-3.5,3.5a.42.42,0,0,0,.3.72.42.42,0,0,0,.3-.12l3.5-3.5,3.5,3.5a.42.42,0,0,0,.6-.6L11.56,11l3.5-3.5a.42.42,0,0,0,0-.6Zm0,0"
        />
      </g>
    </g>
  </svg>
);

IconUnsuccessfull.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconNext = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 35.56 35.56"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style>
        .cls-next-1{`{fill:${props.fill ? props.fill : '#3697d3'}}`}
        .cls-next-2{'{fill:#2d3033;}'}
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-next-1"
          d="M35.56,17.78A17.78,17.78,0,1,0,17.78,35.56,17.8,17.8,0,0,0,35.56,17.78Zm-1.44,0A16.34,16.34,0,1,1,17.78,1.44,16.36,16.36,0,0,1,34.12,17.78Z"
        />
        <path className="cls-next-2" d="M17.78,34.12" />
        <path
          className="cls-next-1"
          d="M15.25,11.88a.73.73,0,0,0,0,1l4.89,4.89-4.89,4.89a.72.72,0,1,0,1,1l5.4-5.4a.72.72,0,0,0,0-1l-5.4-5.4a.72.72,0,0,0-1,0Z"
        />
        <path className="cls-next-1" d="M15.25,11.88" />
      </g>
    </g>
  </svg>
);

IconNext.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconWarning = props => (
  <svg
    width={props.width}
    height={props.height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.8 20.8">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={props.fill ? props.fill : '#FFFFFF'}
          d="M10.4,0A10.4,10.4,0,1,0,20.8,10.4,10.41,10.41,0,0,0,10.4,0Zm0,20A9.6,9.6,0,1,1,20,10.4,9.61,9.61,0,0,1,10.4,20Zm0,0"
        />
        <path
          fill={props.fill ? props.fill : '#FFFFFF'}
          d="M10.4,4a.4.4,0,0,0-.4.4v8.8a.4.4,0,1,0,.8,0V4.4a.4.4,0,0,0-.4-.4Zm0,0"
        />
        <path
          fill={props.fill ? props.fill : '#FFFFFF'}
          d="M10.4,14.8a.4.4,0,0,0-.4.4V16a.4.4,0,1,0,.8,0v-.8a.4.4,0,0,0-.4-.4Zm0,0"
        />
      </g>
    </g>
  </svg>
);
IconWarning.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconReady = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g
      id="Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square">
      <g
        id="icon-ready"
        strokeWidth="2"
        stroke={props.fill ? props.fill : '#3C3C3C'}>
        <g
          id="commonux-checkmark-16"
          transform="translate(16.000000, 7.500000)">
          <g id="CommonUX_CheckMark_16">
            <g id="Group-2">
              <path
                d="M0.333333333,4.33333333 L6.33333333,9.66666667"
                id="Line"
              />
              <path
                d="M15.6666667,0.333333333 L6.33333333,9.66666667"
                id="Line"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconReady.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconGenerateReport = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon id="path-1" points="0 0 17 0 24 7 24 28 0 28" />
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="24"
        height="28"
        fill="white">
        <use xlinkHref="#path-1" />
      </mask>
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="icon-generate-report-dark"
        stroke={props.fill ? props.fill : '#3C3C3C'}>
        <g id="Group-5" transform="translate(10.000000, 10.000000)">
          <use
            id="Rectangle-7"
            mask="url(#mask-2)"
            strokeWidth="4"
            xlinkHref="#path-1"
          />
          <g
            id="Group-4"
            transform="translate(6.000000, 7.000000)"
            strokeWidth="2">
            <path d="M0.5,1 L9.5,1" id="Line" strokeLinecap="square" />
            <path d="M0.5,5 L12.5,5" id="Line" strokeLinecap="square" />
            <path d="M0.5,9 L12.5,9" id="Line" strokeLinecap="square" />
            <g id="Group-2" transform="translate(4.000000, 7.000000)">
              <path
                d="M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,9.87826808 19.1571453,7.84343678 17.6568542,6.34314575 C16.1565632,4.84285472 14.1217319,4 12,4 L12,4 Z"
                id="Shape"
                fill="#FFFFFF"
                transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) "
              />
              <g
                id="Group-3"
                transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(9.000000, 9.000000)"
                strokeLinecap="square">
                <path
                  d="M5.12132034,0.878679656 L0.878679656,5.12132034"
                  id="Line"
                />
                <path
                  d="M0.878679656,0.878679656 L5.12132034,5.12132034"
                  id="Line"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconGenerateReport.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconMenu = props => (
  <svg
    width={props.width ? `${props.width}px` : '32px'}
    height={props.height ? `${props.height}px` : '32px'}
    viewBox={
      props.width && props.height
        ? `0 0 ${props.width} ${props.height}`
        : '0 0 32 32'
    }
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%" />
        <stop stopColor="#000000" stopOpacity="0.5" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square">
      <g id="icon-desktop-options-idle" stroke="#3C3C3C" strokeWidth="2">
        <g id="Group-2" transform="translate(12.000000, 14.000000)">
          <path d="M23.5,9 L0.5,9" id="Line" />
          <path d="M23.5,1 L0.5,1" id="Line" />
          <path d="M23.5,17 L0.5,17" id="Line" />
        </g>
      </g>
    </g>
  </svg>
);
IconMenu.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconDownloadParameterFile = props => (
  <svg
    width={props.width ? props.width : '24'}
    height={props.height ? props.height : '24'}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-download24-idle" stroke="#3C3C3C">
        <g id="commonux-download-16" transform="translate(3.857143, 1.000000)">
          <g id="CommonUX_Download_16">
            <path
              d="M14.5714286,6.5 L8.14285714,15 L1.71428571,6.5 L4.64285714,6.5 L4.64285714,0.5 L11.6428571,0.5 L11.6428571,6.5 L14.5714286,6.5 Z M3.64285714,18.5 L0.642857143,18.5 L0.642857143,21.5 L3.64285714,21.5 L3.64285714,18.5 Z M9.64285714,18.5 L6.64285714,18.5 L6.64285714,21.5 L9.64285714,21.5 L9.64285714,18.5 Z M15.6428571,18.5 L12.6428571,18.5 L12.6428571,21.5 L15.6428571,21.5 L15.6428571,18.5 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
IconDownloadParameterFile.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconTrend = props => (
  <svg
    width={props.width ? props.width : '48px'}
    height={props.height ? props.height : '48px'}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%" />
        <stop stopColor="#000000" stopOpacity="0.5" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-trend" fillRule="nonzero" fill="#3C3C3C">
        <g id="fills" transform="translate(5.000000, 5.000000)">
          <path
            d="M2,36 L36,36 L36,2 L2,2 L2,36 Z M0,0 L38,0 L38,38 L0,38 L0,0 Z"
            id="Rectangle-8"
          />
          <polygon id="Line" points="1.8 28.6 13.8 12.6 12.2 11.4 0.2 27.4" />
          <polygon id="Line" points="25.8 20.6 37.8 4.6 36.2 3.4 24.2 19.4" />
          <polygon id="Line" points="22.8 19.4 16.8 11.4 15.2 12.6 21.2 20.6" />
          <path
            d="M14.5,13.5 C12.8431458,13.5 11.5,12.1568542 11.5,10.5 C11.5,8.84314575 12.8431458,7.5 14.5,7.5 C16.1568542,7.5 17.5,8.84314575 17.5,10.5 C17.5,12.1568542 16.1568542,13.5 14.5,13.5 Z M14.5,11.5 C15.0522847,11.5 15.5,11.0522847 15.5,10.5 C15.5,9.94771525 15.0522847,9.5 14.5,9.5 C13.9477153,9.5 13.5,9.94771525 13.5,10.5 C13.5,11.0522847 13.9477153,11.5 14.5,11.5 Z"
            id="Oval"
          />
          <path
            d="M23,24.5 C21.3431458,24.5 20,23.1568542 20,21.5 C20,19.8431458 21.3431458,18.5 23,18.5 C24.6568542,18.5 26,19.8431458 26,21.5 C26,23.1568542 24.6568542,24.5 23,24.5 Z M23,22.5 C23.5522847,22.5 24,22.0522847 24,21.5 C24,20.9477153 23.5522847,20.5 23,20.5 C22.4477153,20.5 22,20.9477153 22,21.5 C22,22.0522847 22.4477153,22.5 23,22.5 Z"
            id="Oval"
          />
        </g>
      </g>
    </g>
  </svg>
);
IconTrend.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

// const IconHistogram = props => (
//   <svg width={props.width ? props.width : "48px"} height={props.height ? props.height : "48px"} viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
//     <defs>
//       <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
//         <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%"></stop>
//         <stop stopColor="#000000" stopOpacity="0.5" offset="100%"></stop>
//       </linearGradient>
//     </defs>
//     <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//       <g id="icon-histogram" fill="#3C3C3C">
//         <path d="M7,41 L41,41 L41,7 L7,7 L7,41 Z M5,5 L43,5 L43,43 L5,43 L5,5 Z" id="Rectangle-8" fillRule="nonzero"></path>
//         <rect id="Rectangle" x="10" y="27" width="8" height="12"></rect>
//         <rect id="Rectangle" x="20" y="15" width="8" height="24"></rect>
//         <rect id="Rectangle" x="30" y="19" width="8" height="20"></rect>
//       </g>
//     </g>
//   </svg>
// );
// IconHistogram.propTypes = {
//   fill: PropTypes.string,
//   width: PropTypes.string,
//   height: PropTypes.string,
// }

const IconScatter = props => (
  <svg
    width={props.width ? props.width : '48px'}
    height={props.height ? props.height : '48px'}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%" />
        <stop stopColor="#000000" stopOpacity="0.5" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-scatter" fill="#3C3C3C">
        <path
          d="M7,41 L41,41 L41,7 L7,7 L7,41 Z M5,5 L43,5 L43,43 L5,43 L5,5 Z"
          id="Rectangle-8"
          fillRule="nonzero"
        />
        <circle id="Oval-4" cx="16.5" cy="27.5" r="2.5" />
        <circle id="Oval-4" cx="12.5" cy="16.5" r="2.5" />
        <circle id="Oval-4" cx="26.5" cy="30.5" r="2.5" />
        <circle id="Oval-4" cx="20.4608912" cy="21.5" r="2.5" />
        <circle id="Oval-4" cx="31.5" cy="21.5" r="2.5" />
        <circle id="Oval-4" cx="36.5" cy="27.5" r="2.5" />
        <circle id="Oval-4" cx="26.5" cy="24.5" r="2.5" />
        <circle id="Oval-4" cx="25.5" cy="16.5" r="2.5" />
      </g>
    </g>
  </svg>
);
IconScatter.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconEventDetail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 14.25">
    <g id="event-detail-Layer1">
      <g id="event-detail-Layer1-2">
        <path
          fill="#88c64e"
          d="M3.45,13.11H5.67a1.42,1.42,0,0,1-.37.81,1,1,0,0,1-1.47,0,1.42,1.42,0,0,1-.37-.81Zm-.54-.44H6.21l.15-1.21H2.75ZM9.12,4.53A4.38,4.38,0,0,1,7.92,7.61l-.07.08L7.69,8a7.19,7.19,0,0,0-.37.77A8.87,8.87,0,0,0,7,9.58a.32.32,0,0,0,0,.15.57.57,0,0,1,0,.14.34.34,0,0,0,0,.11v1H2.23V10s0-.1,0-.19l-.07-.24a8.85,8.85,0,0,0-.31-.85q-.18-.39-.41-.8l-.18-.28,0-.08v0A4.35,4.35,0,0,1,0,4.53a4.34,4.34,0,0,1,1.34-3.2A4.42,4.42,0,0,1,4.56,0,4.42,4.42,0,0,1,7.78,1.33a4.34,4.34,0,0,1,1.34,3.2Zm-1,0A3.33,3.33,0,0,0,7,2.06,3.42,3.42,0,0,0,4.56,1a3.42,3.42,0,0,0-2.48,1A3.33,3.33,0,0,0,1,4.53a3.37,3.37,0,0,0,.9,2.33l.18.23.18.33a10,10,0,0,1,.46.9q.21.47.37,1a3.65,3.65,0,0,1,.15.7H5.85A3.67,3.67,0,0,1,6,9.27a7.9,7.9,0,0,1,.36-1q.21-.47.42-.86L7,7.12a1.26,1.26,0,0,1,.16-.23,3.34,3.34,0,0,0,.93-2.36ZM5.39,5.78"
        />
      </g>
    </g>
  </svg>
);

const IconEventFault = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.68 14.68">
    <defs>
      <style>.cls-2{'clip-path:url(#clip-path);'}</style>
      <clipPath id="clip-path">
        <rect fill="none" y="0.01" width="14.67" height="14.82" />
      </clipPath>
    </defs>
    <g id="Layer_2">
      <g id="Layer_1-2">
        <g className="cls-2">
          <path
            fill="#f27a59"
            d="M7.34,14.68A7.34,7.34,0,1,0,0,7.34a7.35,7.35,0,0,0,7.34,7.34ZM7.34,1A6.31,6.31,0,1,1,1,7.34,6.32,6.32,0,0,1,7.34,1Zm0,0"
          />
        </g>
        <path
          fill="#f27a59"
          d="M4.58,10.18a.52.52,0,0,0,.73,0l2-2,2,2a.52.52,0,0,0,.73,0,.52.52,0,0,0,0-.73l-2-2,2-2a.52.52,0,0,0-.73-.73l-2,2-2-2a.52.52,0,0,0-.73.73l2,2-2,2a.52.52,0,0,0,0,.73Zm0,0"
        />
      </g>
    </g>
  </svg>
);

const IconEventNotification = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.27 14.44">
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          fill="#88cbdc"
          d="M6.14,14.44A1.45,1.45,0,0,0,7.58,13H4.69a1.45,1.45,0,0,0,1.45,1.45Zm4.69-4.33v-4A4.64,4.64,0,0,0,7.22,1.59V1.08a1.08,1.08,0,0,0-2.16,0v.51A4.64,4.64,0,0,0,1.45,6.14v4L0,11.55v.72H12.27v-.72Zm-1.45.72H2.89V6.14a3.25,3.25,0,1,1,6.5,0Zm0,0"
        />
      </g>
    </g>
  </svg>
);

const IconEventWarning = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.54 12.91">
    <g id="Layer_2">
      <g id="Layer_1-2">
        <rect fill="none" width="14.53" height="12.91" />
        <polygon
          fill="#e1d54a"
          points="7.79 9.04 6.74 9.04 6.6 4.18 7.94 4.18 7.79 9.04"
        />
        <rect fill="#e1d54a" x="6.75" y="9.77" width="1.05" height="1.05" />
        <path
          fill="#e1d54a"
          d="M14.32,10.49,8.65.8A1.61,1.61,0,0,0,5.88.8L.22,10.49a1.56,1.56,0,0,0,0,1.59,1.59,1.59,0,0,0,1.39.8H12.93a1.59,1.59,0,0,0,1.39-.8A1.56,1.56,0,0,0,14.32,10.49ZM13,11.35a.1.1,0,0,1-.1.05H1.6a.1.1,0,0,1-.1-.05.08.08,0,0,1,0-.09L7.17,1.56a.12.12,0,0,1,.19,0L13,11.26A.08.08,0,0,1,13,11.35Z"
        />
      </g>
    </g>
  </svg>
);

const IconEventCollapsed = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.41 12">
    <g id="Layer_2">
      <g id="Layer_1-2">
        <g id="Icons">
          <g id="icon-commonux-right-blue">
            <g id="commonux-right-16">
              <g id="CommonUX_Right_16">
                <polygon
                  id="Shape"
                  fill="#3b92cf"
                  points="0 10.59 4.58 6 0 1.41 1.41 0 7.41 6 1.41 12 0 10.59"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const IconEventExpanded = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7.41">
    <g id="Layer_2">
      <g id="Layer_1-2">
        <g id="Icons">
          <g id="icon-commonux-right-blue">
            <g id="commonux-right-16">
              <g id="CommonUX_Right_16">
                <polygon
                  id="Shape"
                  fill="#3b92cf"
                  points="1.41 0 6 4.58 10.59 0 12 1.41 6 7.41 0 1.41 1.41 0"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const IconInfo = ({ color }) => (
  <svg
    width="16px"
    height="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={color || '#fff'}
          d="M8,1A7,7,0,1,1,1,8,7,7,0,0,1,8,1M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm2,12H9V6H6V7H7v5H6v1h4ZM9,3H7V5H9Z"
        />
      </g>
    </g>
  </svg>
);

const IconArrowTopBar = () => (
  <svg
    width="16px"
    height="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.22 9.49">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          fill="#b3b2b3"
          points="7.11 9.49 0 1.53 1.72 0 7.11 6.04 12.5 0 14.22 1.53 7.11 9.49"
        />
      </g>
    </g>
  </svg>
);

const IconHelp = props => (
  <svg
    width="16px"
    height="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#b3b3b4"
          d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM9,13H7V11H9ZM9,8.75V10H7V7.5s3.31.12,3.24-1.62c0-.76-1-1.2-2.06-1.21C7.69,4.67,6.09,4.74,6,6H4c.13-1.77,1.58-3,4.17-3C11.5,3,12,5.12,12,5.75,12,8.56,9,8.75,9,8.75Z"
        />
      </g>
    </g>
  </svg>
);

const IconWheel = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="26px"
    viewBox="0 0 16 15">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={props.fill ? props.fill : '#b3b3b4'}
          d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM5.59,10.29,4.4,12.36a5.68,5.68,0,0,1-1.3-1.53,5.58,5.58,0,0,1-.68-1.89H4.83a.89.89,0,0,1,.74.46A1,1,0,0,1,5.59,10.29Zm0-3.69a1,1,0,0,1-.76.46H2.42A5.58,5.58,0,0,1,3.1,5.17,5.68,5.68,0,0,1,4.4,3.64L5.6,5.72A.89.89,0,0,1,5.57,6.6ZM8,13.66a5.65,5.65,0,0,1-2-.36l1.21-2.09a.87.87,0,0,1,.77-.4,1,1,0,0,1,.78.42L10,13.3A5.65,5.65,0,0,1,8,13.66ZM6.59,8A1.41,1.41,0,1,1,8,9.41,1.41,1.41,0,0,1,6.59,8ZM8.77,4.79a.87.87,0,0,1-.77.4,1,1,0,0,1-.78-.42L6,2.7a5.62,5.62,0,0,1,4,0Zm4.13,6a5.68,5.68,0,0,1-1.3,1.53l-1.2-2.09a.87.87,0,0,1,0-.87,1,1,0,0,1,.76-.46h2.39A5.58,5.58,0,0,1,12.9,10.83ZM11.17,7.06a.89.89,0,0,1-.74-.46,1,1,0,0,1,0-.89L11.6,3.64a5.68,5.68,0,0,1,1.3,1.53,5.58,5.58,0,0,1,.68,1.89Z"
        />
      </g>
    </g>
  </svg>
);

const IconTimeTrend = props => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M4,25 L30,25 L30,27 L4,27 L3,27 L2,27 L2,4 L4,4 L4,25 Z M6.71,19.71 L5.29,18.29 L10.13,13.36 C10.0087324,13.0409234 9.95098365,12.7012249 9.96,12.36 C9.9921539,11.466836 10.4983626,10.6586724 11.2879423,10.2399365 C12.0775219,9.8212006 13.0305165,9.85550841 13.7879423,10.3299365 C14.545368,10.8043646 14.9921539,11.646836 14.96,12.54 L17.96,15.54 C18.6505761,14.9837176 19.5885903,14.8458869 20.41,15.18 L28.29,7.29 L29.71,8.74 L21.82,16.62 C22.1963613,17.5576802 21.9712928,18.6297243 21.2496096,19.3368774 C20.5279265,20.0440304 19.4515271,20.2472511 18.5216945,19.8518987 C17.591862,19.4565463 16.9914214,18.5403556 17,17.53 C17,17.45 17,17.45 17,17.45 L14,14.45 C13.5476243,14.8152137 12.981315,15.0098825 12.4,15 C12.1217296,14.9864904 11.8478019,14.9256176 11.59,14.82 L6.71,19.71 Z"
        id="path-1"
      />
    </defs>
    <g
      id="IconTrend"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlinkHref="#path-1" />
      </mask>
      <use id="Combined-Shape" fill="#1F1F1F" xlinkHref="#path-1" />
      <g id="Colors-/-Grey-70" mask="url(#mask-2)" fill="#464646">
        <rect id="Rectangle" x="0" y="0" width="24" height="26" />
      </g>
    </g>
  </svg>
);

const IconScatterPlot = props => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M4,25 L30,25 L30,27 L4,27 L3,27 L2,27 L2,4 L4,4 L4,25 Z M8,23 C6.8954305,23 6,22.1045695 6,21 C6,19.8954305 6.8954305,19 8,19 C9.1045695,19 10,19.8954305 10,21 C10,22.1045695 9.1045695,23 8,23 Z M18,23 C16.8954305,23 16,22.1045695 16,21 C16,19.8954305 16.8954305,19 18,19 C19.1045695,19 20,19.8954305 20,21 C20,22.1045695 19.1045695,23 18,23 Z M26,9 C24.8954305,9 24,8.1045695 24,7 C24,5.8954305 24.8954305,5 26,5 C27.1045695,5 28,5.8954305 28,7 C28,8.1045695 27.1045695,9 26,9 Z M20,15 C18.8954305,15 18,14.1045695 18,13 C18,11.8954305 18.8954305,11 20,11 C21.1045695,11 22,11.8954305 22,13 C22,14.1045695 21.1045695,15 20,15 Z M13,18 C11.8954305,18 11,17.1045695 11,16 C11,14.8954305 11.8954305,14 13,14 C14.1045695,14 15,14.8954305 15,16 C15,17.1045695 14.1045695,18 13,18 Z M11,11 C9.8954305,11 9,10.1045695 9,9 C9,7.8954305 9.8954305,7 11,7 C12.1045695,7 13,7.8954305 13,9 C13,10.1045695 12.1045695,11 11,11 Z"
        id="path-2"
      />
    </defs>
    <g
      id="Icon-/-Scatter-plot"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <mask id="mask-3" fill="white">
        <use xlinkHref="#path-2" />
      </mask>
      <use id="Combined-Shape" fill="#1F1F1F" xlinkHref="#path-2" />
      <g id="Colors-/-Icon-Grey-70" mask="url(#mask-3)" fill="#464646">
        <rect id="Rectangle" x="0" y="0" width="32" height="32" />
      </g>
    </g>
  </svg>
);

const IconHistogram = props => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M22,11 L29,11 L29,23 L22,23 L22,11 Z M14,15 L21,15 L21,23 L14,23 L14,15 Z M4,25 L30,25 L30,28 L4,28 L3,28 L2,28 L2,5 L4,5 L4,25 Z M13,7 L13,23 L6,23 L6,7 L13,7 Z M11,21 L11,9 L8,9 L8,21 L11,21 Z"
        id="histogrampath"
      />
    </defs>
    <g
      id="Icon-/-Histogram"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <mask id="histogram-mask-2" fill="white">
        <use xlinkHref="#histogrampath" />
      </mask>
      <use id="Combined-Shape" fill="#1F1F1F" xlinkHref="#histogrampath" />
      <g id="Colors-/-Grey-70" mask="url(#histogram-mask-2)" fill="#464646">
        <rect id="Rectangle" x="0" y="0" width="32" height="32" />
      </g>
    </g>
  </svg>
);

const IconGps = props => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M25.62,22 L29.62,30 L2.38,30 L6.38,22 L9.59,22 C10.19,22.74 10.8,23.41 11.38,24 L7.62,24 L5.62,28 L26.38,28 L24.38,24 L20.47,24 C21.05,23.41 21.66,22.74 22.26,22 L25.62,22 Z M15.92,2 C20.8947582,2.02138926 24.934588,6.02562592 25,11 C25,18.8 15.92,25.4 15.92,25.4 C15.92,25.4 7,18.8 7,11 C6.99980486,6.06051233 10.9807075,2.04390655 15.92,2 Z M15.92,15.15 C16.980866,15.15 17.9982816,14.7285726 18.7484271,13.9784271 C19.4985726,13.2282816 19.92,12.210866 19.92,11.15 C19.92,8.940861 18.129139,7.15 15.92,7.15 C13.710861,7.15 11.92,8.940861 11.92,11.15 C11.92,13.359139 13.710861,15.15 15.92,15.15 Z"
        id="Gpspath"
      />
    </defs>
    <g
      id="Icon-/-GPS"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <mask id="gps-mask-2" fill="white">
        <use xlinkHref="#Gpspath" />
      </mask>
      <use id="Combined-Shape" fill="#1F1F1F" xlinkHref="#Gpspath" />
      <g id="Colors-/-Grey-70" mask="url(#gps-mask-2)" fill="#464646">
        <rect id="Rectangle" x="0" y="0" width="32" height="32" />
      </g>
    </g>
  </svg>
);

const IconUser = () => (
  <svg
    width="16px"
    height="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#b3b3b4"
          d="M14,11.64V16H0V11.64C0,9.64,3.12,8,7,8S14,9.67,14,11.64ZM7,0a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,7,0Z"
        />
      </g>
    </g>
  </svg>
);

const IconEmailBell = () => (
  <svg
    width="24px"
    height="33px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 33">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#fff"
          d="M22,18c0-5.56-1.82-12.44-8-13.78V2a2,2,0,0,0-4,0V4.22C3.82,5.56,2,12.44,2,18c0,3.66-2,4-2,4v4H24V22S22,21.66,22,18ZM16,29a4,4,0,0,1-8,0Z"
        />
      </g>
    </g>
  </svg>
);

const IconBreadCrumb = props => (
  <svg
    width={props.width ? props.width : '16px'}
    height={props.height ? props.height : '15px'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          fill={props.fill ? props.fill : '#0c74da'}
          points="8 0 0 7 2 7 2 15 6 15 6 10 10 10 10 15 14 15 14 7 16 7 8 0"
        />
      </g>
    </g>
  </svg>
);
IconBreadCrumb.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

const IconMobileHome = () => (
  <svg
    width="24px"
    height="24px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          fill="#eee"
          points="21 24 15 24 15 17 9 17 9 24 3 24 3 14 0 14 12 0 24 14 21 14 21 24"
        />
      </g>
    </g>
  </svg>
);

const IconArrowToDown = () => (
  <svg
    width="14px"
    height="9px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.22 9.49">
    <g id="Layer_2" data-name="Layer 2">
      <g data-name="Layer 1">
        <polygon
          id="icon-arrow-down"
          fill="#fff"
          points="7.11 9.49 0 1.53 1.72 0 7.11 6.04 12.5 0 14.22 1.53 7.11 9.49"
        />
      </g>
    </g>
  </svg>
);

const IconFeedback = () => (
  <svg
    width="16px"
    height="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#b3b3b4"
          d="M14,3v7H10.32L8,11.78V10H2V3H14m2-2H0V12H6v3.83L11,12h5V1Z"
        />
      </g>
    </g>
  </svg>
);

const IconFailedUpload = () => (
  <svg
    width="15px"
    height="15px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Content">
        <path
          fill="#f03040"
          d="M7.5,0A7.5,7.5,0,1,0,15,7.5,7.5,7.5,0,0,0,7.5,0Zm3.26,9.7L9.7,10.76,7.5,8.56l-2.2,2.2L4.24,9.7l2.2-2.2L4.24,5.3,5.3,4.24l2.2,2.2,2.2-2.2L10.76,5.3,8.56,7.5Z"
        />
        <polygon
          fill="#fff"
          points="10.76 9.7 9.7 10.76 7.5 8.56 5.3 10.76 4.24 9.7 6.44 7.5 4.24 5.3 5.3 4.24 7.5 6.44 9.7 4.24 10.76 5.3 8.56 7.5 10.76 9.7"
        />
      </g>
    </g>
  </svg>
);

const IconGoToLink = () => (
  <svg
    width="16px"
    height="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#b4b4b5"
          d="M12,9h2v5H0V0H5V2H2V12H12ZM8,0l2.22,2.22L4.33,8.11,5.89,9.67l5.89-5.89L14,6V0Z"
        />
      </g>
    </g>
  </svg>
);

/* Powertrain ICONS */
const IconDrive = props => (
  <svg
    id={props.id ? props.id : 'drive-icon'}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48">
    <defs>
      <path id={`${props.id}_11dax`} d="M0 0v35h2v2h16v-2h2V0z" />
      <path
        id={`${props.id}_11dcx`}
        d="M14 6v35h2v2h16v-2h2V6H14zm3 10h14v-6H17v6zm11 4l-3 8h7L20 40l3-9h-6l11-11z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(14 6)">
        <mask id={`${props.id}_11dbx`} fill="#fff">
          <use xlinkHref={`#${props.id}_11dax`} />
        </mask>
        <use fill="#0096EA" xlinkHref={`#${props.id}_11dax`} />
        <g fill="#FFF" mask={`url(#${props.id}_11dbx)`}>
          <path d="M-14-6h48v48h-48z" />
        </g>
      </g>
      <mask id={`${props.id}_11ddx`} fill="#fff">
        <use xlinkHref={`#${props.id}_11dcx`} />
      </mask>
      <use fill="#319A5D" xlinkHref={`#${props.id}_11dcx`} />
      <g
        fill={props.fill ? props.fill : '#141414'}
        mask={`url(#${props.id}_11ddx)`}>
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);
IconDrive.propTypes = {
  id: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
};

const IconTransformer = props => (
  <svg
    id={props.id || 'trafo_icon_'}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48">
    <defs>
      <path
        id={`${props.id}_a`}
        d="M22 8V6h-2V0h-4v6h-2V0h-4v6H8V0H4v6H2v2H0v20h6v4h12v-4h6V8z"
      />
      <path
        id={`${props.id}_c`}
        d="M34 19v-2h-2v-6h-4v6h-2v-6h-4v6h-2v-6h-4v6h-2v2h-2v20h6v4h12v-4h6V19h-2zM16 35h-2V23h2v12zm6 0h-2V23h2v12zm6 0h-2V23h2v12zm6 0h-2V23h2v12z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(12 11)">
        <mask id={`${props.id}_b`} fill="#fff">
          <use xlinkHref={`#${props.id}_a`} />
        </mask>
        <use fill="#0096EA" xlinkHref={`#${props.id}_a`} />
        <g fill="#FFF" mask={`url(#${props.id}_b)`}>
          <path d="M-12-11h48v48h-48z" />
        </g>
      </g>
      <mask id={`${props.id}_d`} fill="#fff">
        <use xlinkHref={`#${props.id}_c`} />
      </mask>
      <use fill="#464646" fillRule="nonzero" xlinkHref={`#${props.id}_c`} />
      <g
        fill={props.fill ? props.fill : '#141414'}
        mask={`url(#${props.id}_d)`}>
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);
IconTransformer.propTypes = {
  id: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
};

const IconGenerator = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48">
    <defs>
      <path id={`${props.id}_a`} d="M36 18H12v4h-2v4H4v6h6v4h2v4h24z" />
      <path
        id={`${props.id}_c`}
        d="M36 18H12v4h-2v4H4v6h6v4h2v4h24V18zm-4 18H16v-2h16v2zm0-6H16v-2h16v2zm0-6H16v-2h16v2zm6-6h3l3 4v14l-3 4h-3V18z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id={`${props.id}_b`} fill="#fff">
        <use xlinkHref={`#${props.id}_a`} />
      </mask>
      <use fill="#0096EA" xlinkHref={`#${props.id}_a`} />
      <g fill="#FFF" mask={`url(#${props.id}_b)`}>
        <path d="M0 0h48v48H0z" />
      </g>
      <mask id={`${props.id}_d`} fill="#fff">
        <use xlinkHref={`#${props.id}_c`} />
      </mask>
      <use fill="#464646" fillRule="nonzero" xlinkHref={`#${props.id}_c`} />
      <g
        fill={props.fill ? props.fill : '#141414'}
        mask={`url(#${props.id}_d)`}>
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);
IconGenerator.propTypes = {
  id: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
};

const IconPowertrain = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '24'}
    height={props.width || '24'}
    viewBox="0 0 48 48">
    <defs />
    <g fill="none" fillRule="evenodd">
      <mask id={`${props.id}_b`} fill="#fff">
        <path d="M16.336 8h15.328L38 13.977V42h-6l-2-4H18l-2 4h-6V13.977z" />
      </mask>
      <use fill="#0096EA" xlinkHref="#1a33" />
      <g fill="#FFF" mask={`url(#${props.id}_b)`}>
        <path d="M0 0h48v48H0z" />
      </g>
      <mask id={`${props.id}_d`} fill="#fff">
        <path d="M16.336 8h15.328L38 13.977V42h-6l-2-4H18l-2 4h-6V13.977L16.336 8zM4 22h4v12H4V22zm36 0h4v12h-4V22zm-12-8L16 24l6 2-4 8 14-12h-8l4-8z" />
      </mask>
      <use fill="#000" xlinkHref="#1c33" />
      <g fill={props.fill || '#141414'} mask={`url(#${props.id}_d)`}>
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);
IconPowertrain.propTypes = {
  id: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
};

const IconSwitch = () => (
  <svg
    width="16"
    height="12"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 12">
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          fill="#4f89c8"
          d="M0,12S.58,6.8,10,6.8V10l6-5L10,0V3.2C2.75,3.2,0,6.34,0,12Z"
        />
      </g>
    </g>
  </svg>
);

const IconArrowDownDark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M8 11.664L2.312 5.617l1.376-1.281L8 8.946l4.313-4.61 1.374 1.281z"
    />
  </svg>
);

const IconCommonClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M23.876 10.392l-5.565 5.52 5.565 5.546-2.374 2.392-5.564-5.546-5.565 5.546L8 21.458l5.565-5.546L8 10.392 10.373 8l5.565 5.546L21.502 8z"
    />
  </svg>
);

const IconMobileArrowUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24 15.75l13 13.5-3.5 3-9.5-10.5-10 10.5-3-3z"
    />
  </svg>
);

const IconMobileArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24 32.25l-13-13.5 3.5-3 9.5 10.5 10-10.5 3 3z"
    />
  </svg>
);

const SortIcon = props =>
  props.asc ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 5V16L10.5 15L11.5 16L8.5 19L5.5 16L6.5 15L7.5 16V5H9.5ZM14.5 11V12H11.5V11H14.5ZM16.5 8V9H11.5V8H16.5ZM18.5 5.09V6H11.5V5.09H18.5Z"
        fill="#1F1F1F"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 5L11.5 8L10.5 9L9.5 8V19H7.5V8L6.5 9L5.5 8L8.5 5ZM18.5 18V19H11.5V18H18.5ZM16.5 15V16H11.5V15H16.5ZM14.5 12V13H11.5V12H14.5Z"
        fill="#1F1F1F"
      />
    </svg>
  );

export {
  AbbLogo,
  IconAddFile,
  IconAlarm,
  IconAnalysis,
  IconArrowDownDark,
  IconArrowToDown,
  IconArrowTopBar,
  IconAsc,
  IconAvailability,
  IconBreadCrumb,
  IconCalendar,
  IconClose,
  IconCollapseButton,
  IconCommonClose,
  IconDesc,
  IconDone,
  IconDownCaret,
  IconDownloadParameterFile,
  IconDrive,
  IconEdit,
  IconEmailBell,
  IconEventCollapsed,
  IconEventDetail,
  IconEventExpanded,
  IconEventFault,
  IconEventNotification,
  IconEventWarning,
  IconFailedUpload,
  IconFault,
  IconFeedback,
  IconFilter,
  IconGenerateReport,
  IconGenerator,
  IconGoToLink,
  IconGps,
  IconHelp,
  IconHistogram,
  IconHome,
  IconInfo,
  IconMenu,
  IconMobileArrowDown,
  IconMobileArrowUp,
  IconMobileHome,
  IconMultiSignal,
  IconNext,
  IconOptions,
  IconPowertrain,
  IconPrevious,
  IconReady,
  IconReliability,
  IconReport,
  IconScatter,
  IconScatterPlot,
  IconStress,
  IconSuccess,
  IconSwitch,
  IconTemperature,
  IconTimeTrend,
  IconTransformer,
  IconTrend,
  IconUnsuccessfull,
  IconUser,
  IconWarning,
  IconWheel,
  SortIcon,
};
