import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ArrowDownLine } from '../../assets/images';
import './styles.scss';

class Glossary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      glossaryData: [],
      isVisible: true,
    };
    this.topRef = React.createRef();
    this.glossaryRef = React.createRef();
  }

  componentDidMount() {
    this.loadGlossaryContent();
    window.addEventListener('scroll', this.toggleBackToTopVisibility);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.redirectToDataFromLetter();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleBackToTopVisibility);
  }

  toggleBackToTopVisibility = () => {
    const glossary = window.scrollY;
    if (glossary > 400) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  loadGlossaryContent = () => {
    fetch(`${process.env.PUBLIC_URL}/glossary.txt`)
      .then(response => response.text())
      .then(text => {
        const glossaryData = this.parseGlossaryContent(text);
        this.setState({ glossaryData }, this.redirectToDataFromLetter);
      })
      .catch(error => {
        console.error('error', error);
      });
  };

  parseGlossaryContent = text => {
    const glossaryDatas = {};
    const formattedDatas = text.split('\n');
    formattedDatas.forEach(data => {
      const [key, value] = data.split(':').map(item => item.trim());
      if (key && value) {
        const zeroPositionLetter = key.charAt(0).toUpperCase();
        if (!glossaryDatas[zeroPositionLetter]) {
          glossaryDatas[zeroPositionLetter] = [];
        }
        glossaryDatas[zeroPositionLetter].push({ key, value });
      }
    });

    const glossaryArray = Object.keys(glossaryDatas)
      .sort()
      .map(letter => ({
        letter,
        items: glossaryDatas[letter],
      }));

    return glossaryArray;
  };

  scrollToTop = () => {
    if (this.topRef.current) {
      // this.props.history.push('/Glossary/A');
      this.topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  scrollToLetter = letter => {
    const tElement = document.getElementById(letter);
    if (tElement) {
      tElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`error`);
    }
  };

  redirectToDataFromLetter = () => {
    const { letter } = this.props.match.params;
    if (letter) {
      this.scrollToLetter(letter.toUpperCase());
    }
  };

  render() {
    const { glossaryData, isVisible } = this.state;

    return (
      <>
        {/* <Breadcrumb MonitoredCount={this.props.monitoredCountData} /> */}

        <div className="glossaryOverlay">
          <div className="glossaryContainer" ref={this.glossaryRef}>
            <div ref={this.topRef}></div>
            <span className="redUnderline"></span>

            <div className="glossaryHeader">
              <h1 className="glossaryHeading">
                Glossary of technical keys commonly used by ABB
              </h1>
            </div>
            <div className="glossaryContent">
              <div className="alphabet-navigation">
                {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(letter => (
                  <span
                    key={letter}
                    className="alphabet-letter"
                    onClick={() => this.scrollToLetter(letter)}>
                    {letter}
                  </span>
                ))}
              </div>

              <div className="glossary-items">
                {glossaryData.map(group => (
                  <div
                    key={group.letter}
                    id={group.letter}
                    className="glossary-section">
                    <span className="redUnderlineSmall"></span>

                    <h2>{group.letter}</h2>
                    {group.items.map((item, index) => (
                      <p key={index}>
                        <strong>{item.key}:</strong> {item.value}
                      </p>
                    ))}
                    <button
                      className="back-to-top-button"
                      onClick={this.scrollToTop}>
                      Back to the top
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="backtotopdiv">
          {isVisible && (
            <span
              onClick={this.scrollToTop}
              className="backToTop"
              style={{
                bottom: isVisible ? '20px' : '-100px',
                opacity: isVisible ? 1 : 0,
                transition: 'bottom 0.5s ease-in-out, opacity 0.5s ease-in-out',
              }}>
              <ArrowDownLine style={{ transform: 'rotate(180deg)' }} />
            </span>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(Glossary);
