import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { checkUserEntitlements } from '../../userConfig';
import Diagnostic from '../Diagnostic';
import './styles.scss';

class TractionConverterTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loader: false, isFixed: false };
  }

  handleScroll = () => {
    const isFixed = window.scrollY >= 300;
    if (isFixed !== this.state.isFixed) {
      this.setState({ isFixed });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isResizing !== this.props.isResizing ||
      prevProps.showSidebar !== this.props.showSidebar
    ) {
      this.setState({ loader: true });

      setTimeout(() => {
        this.setState({ loader: false });
      }, 300);
    }
  }

  render() {
    const { isFixed } = this.state;

    return (
      <div className="tabfleetview" id="vehicle-products-tab">
        <Tabs
          id="fleet"
          style={{ width: this.props.heatmap_min_width - 5 }}
          className={`main-tab-content${isFixed ? ' fixed' : ''}`}
          activeKey={this.props.selectedTab}
          onSelect={(k, ev) =>
            ev.type === 'click'
              ? this.props.onVehicleProductsViewTabClick(k)
              : null
          }>
          {checkUserEntitlements(
            'ESS:Diagnostic-event-tab',
            'diagnostic-vehicleprod-view'
          ) && (
            <Tab
              eventKey="diagnostic-vehicleprod-view"
              title={<span className="tabtitle">Diagnostics</span>}>
              <div
                className="custom-tab-content"
                id="fleet-diag-list"
                style={{
                  // position: "absolute",
                  width: this.props.heatmap_min_width - 5,
                }}>
                <Diagnostic
                  entitlements={this.props.userConfig.entitlements}
                  selectedView={this.props.selectedView}
                  diagFilterApply={this.props.diagFilterApply}
                  vehiclesListData={this.props.vehiclesListData}
                  fetchDiagnosticList={this.props.fetchDiagnosticList}
                  loadingDiagnosticList={this.props.loadingDiagnosticList}
                  diagnosticList={
                    this.props.diagnosticList && this.props.diagnosticList
                  }
                  redirectToTimetrendFrmDiagnostic={
                    this.props.redirectToTimetrendFrmDiagnostic
                  }
                  copyLinkDiagnosticTab={this.props.copyLinkDiagnosticTab}
                  diagnosticListConfig={
                    this.props.diagnosticListConfig &&
                    this.props.diagnosticListConfig
                  }
                  diagnosticEventData={
                    this.props.diagnosticEventData &&
                    this.props.diagnosticEventData
                  }
                  setDiagnosticEventListData={
                    this.props.setDiagnosticEventListData
                  }
                />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

export default TractionConverterTabs;
