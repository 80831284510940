import { fromJS } from 'immutable';
import {
  ADD_OVERLAYTRENDSIGNAL,
  CLEAR_BATTERY_OVERVIEW_DATA,
  CLEAR_CHARTDATA,
  CLEAR_DETAILVIEW_TIMETREND,
  FETCH_BATTERY_OVERALL_CONDITION_DATA,
  FETCH_BATTERY_OVERALL_CONDITION_DATA_FAILURE,
  FETCH_BATTERY_OVERALL_CONDITION_DATA_SUCCESS,
  FETCH_BATTERY_OVERVIEW_DATA,
  FETCH_BATTERY_OVERVIEW_DATA_FAILURE,
  FETCH_BATTERY_OVERVIEW_DATA_SUCCESS,
  FETCH_BATTERY_REL_DATA,
  FETCH_BATTERY_REL_DATA_FAILURE,
  FETCH_BATTERY_REL_DATA_SUCCESS,
  FETCH_BATTERY_SOC_DATA,
  FETCH_BATTERY_SOC_DATA_FAILURE,
  FETCH_BATTERY_SOC_DATA_SUCCESS,
  FETCH_BATTERY_SOC_RANGE_DATA,
  FETCH_BATTERY_SOC_RANGE_DATA_FAILURE,
  FETCH_BATTERY_SOC_RANGE_DATA_SUCCESS,
  FETCH_BATTERY_STR_DATA,
  FETCH_BATTERY_STR_DATA_FAILURE,
  FETCH_BATTERY_STR_DATA_SUCCESS,
  FETCH_BOXPLOT_DATA,
  FETCH_BOXPLOT_DATA_FAILURE,
  FETCH_BOXPLOT_DATA_SUCCESS,
  FETCH_CELL_VOLTAGE_DATA,
  FETCH_CELL_VOLTAGE_DATA_FAILURE,
  FETCH_CELL_VOLTAGE_DATA_SUCCESS,
  FETCH_HISTOGRAM_DATA,
  FETCH_HISTOGRAM_DATA_FAILURE,
  FETCH_HISTOGRAM_DATA_SUCCESS,
  FETCH_OPERATING_HOURS_HISTOGRAM_DATA,
  FETCH_OPERATING_HOURS_HISTOGRAM_DATA_FAILURE,
  FETCH_OPERATING_HOURS_HISTOGRAM_DATA_SUCCESS,
  FETCH_SOH_DATA,
  FETCH_SOH_DATA_FAILURE,
  FETCH_SOH_DATA_SUCCESS,
  FETCH_SYNC_GRAPH_DATA,
  FETCH_SYNC_GRAPH_DATA_FAILURE,
  FETCH_SYNC_GRAPH_DATA_SUCCESS,
  FETCH_TEMPERATURES_HISTOGRAM_DATA,
  FETCH_TEMPERATURES_HISTOGRAM_DATA_FAILURE,
  FETCH_TEMPERATURES_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGDOD_HEATMAP_DATA,
  FETCH_USGDOD_HEATMAP_DATA_FAILURE,
  FETCH_USGDOD_HEATMAP_DATA_SUCCESS,
  FETCH_USGDOD_HISTOGRAM_DATA,
  FETCH_USGDOD_HISTOGRAM_DATA_FAILURE,
  FETCH_USGDOD_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGPOW_HEATMAP_DATA,
  FETCH_USGPOW_HEATMAP_DATA_FAILURE,
  FETCH_USGPOW_HEATMAP_DATA_SUCCESS,
  FETCH_USGPOW_HISTOGRAM_DATA,
  FETCH_USGPOW_HISTOGRAM_DATA_FAILURE,
  FETCH_USGPOW_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGSOC_HEATMAP_DATA,
  FETCH_USGSOC_HEATMAP_DATA_FAILURE,
  FETCH_USGSOC_HEATMAP_DATA_SUCCESS,
  FETCH_USGSOC_HISTOGRAM_DATA,
  FETCH_USGSOC_HISTOGRAM_DATA_FAILURE,
  FETCH_USGSOC_HISTOGRAM_DATA_SUCCESS,
  FETCH_USGTEMP_HEATMAP_DATA,
  FETCH_USGTEMP_HEATMAP_DATA_FAILURE,
  FETCH_USGTEMP_HEATMAP_DATA_SUCCESS,
  GET_DETAILVIEW_TIMETREND,
  GET_DETAILVIEW_TIMETREND_FAILURE,
  GET_DETAILVIEW_TIMETREND_SUCCESS,
  GET_TT_AVGCELLV_SUCCESS,
  GET_TT_AVGTEMP_SUCCESS,
  GET_TT_BCCHARGE_SUCCESS,
  GET_TT_BCDISCHARGE_SUCCESS,
  GET_TT_BCURRENT_SUCCESS,
  GET_TT_BOLCOUNTER_SUCCESS,
  GET_TT_BPCHARGE_SUCCESS,
  GET_TT_BPDISCHARGE_SUCCESS,
  GET_TT_BVOLTAGE_SUCCESS,
  GET_TT_CONTACTORSTATUS_SUCCESS,
  GET_TT_DIAGNOSTIC_SUCCESS,
  GET_TT_FEEDFLOW_SUCCESS,
  GET_TT_OVERLOADCOUNTER_SUCCESS,
  GET_TT_POWER_SUCCESS,
  GET_TT_RELIABILITY_SUCCESS,
  GET_TT_RETURNFLOW_SUCCESS,
  GET_TT_SOC_SUCCESS,
  SET_MAINSIGNAL,
  SET_MAINSIGNAL1,
  SET_MAINSIGNAL2,
  SET_MAINSIGNAL3,
  SET_MAINTRENDSIGNAL,
  SET_OVERLAYSIGNAL,
  SET_OVERLAYSIGNAL1,
  SET_OVERLAYSIGNAL2,
  SET_OVERLAYSIGNAL3,
  SET_RADIO_VALUE,
  SET_SYNCCHART,
  SET_SYNCCHARTDATA,
  SET_TIMETREND_LOADING,
} from './constants';
const initialState = fromJS({
  loadingBatteryOverallCondition: false,
  loadingCellVoltage: false,
  loadingSyncGraph: false,
  loadingHistogram: false,
  loadingBoxPlot: false,
  // selectedTrain: "Re 460 117-5",
  batteryOverallConditionData: null,
  cellVoltageData: null,
  syncGraphData: null,
  histogrampData: null,
  boxPlotData: null,
  loadingBatterySOC: false,
  batterySOCData: null,
  loadingBatterySOCRange: false,
  batterySOCRangeData: null,
  loadingBatteryOverview: false,
  batteryOverviewData: null,
  loadingTemperatureHistogram: false,
  temperatureHistogramData: null,
  loadingOperatingHoursHistogram: false,
  operatingHoursHistogramData: null,
  loadingUsgSocHistogram: false,
  usgSocHistogramData: null,
  loadingUsgPowHistogram: false,
  usgPowHistogramData: null,
  loadingUsgDodHistogram: false,
  usgDodHistogramData: null,
  loadingUsgDodHeatmap: false,
  usgDodHeatmapData: null,
  loadingUsgPowHeatmap: false,
  usgPowHeatmapData: null,
  loadingUsgSocHeatmap: false,
  usgSocHeatmapData: null,
  loadingUsgTempHeatmap: false,
  usgTempHeatmapData: null,
  error: null,
  selectedMainSignal1: 'Avg CellV',
  selectedOverlaySignal1: 'Avg Temperature',
  selectedMainSignal2: 'State of Charge',
  selectedOverlaySignal2: 'Power',
  selectedMainSignal3: 'Battery Voltage',
  selectedOverlaySignal3: 'Battery Current',
  selectedMainSignal: 'Avg CellV',
  selectedOverlaySignal: 'Avg Temperature',
  selectedSyncChart: 'chart1',
  syncGraphDataRes: null,
  loadingTimetrend: null,
  timeTrendData: null,
  tTAvgCellVData: null,
  tTAvgTemperatureData: null,
  tTSOCData: null,
  tTPowerData: null,
  tTBVoltageData: null,
  tTBCurrentData: null,
  tTReliability: null,
  selectedMainTrend: 'State of Charge',
  selectedOverlaysTrend: ['Power'],
  tTBatteryPeakCharge: null,
  tTBatteryPeakDisCharge: null,
  tTBatteryConstCharge: null,
  tTBatteryConstDisCharge: null,
  tTBatteryOverloadCounter: null,
  loadingBatteryRel: false,
  batteryRelData: null,
  loadingBatteryStr: false,
  batteryStrData: null,
  tTFeedFlow: null,
  tTOverloadCounter: null,
  tTReturnFlow: null,
  loadingSOHData: false,
  SOHData: null,
  radioValue: 'Dbudget',
  tTDiagnostic: null,
  tTContactorStatus: null,
});

export function reducerVehicleProductsView(state = initialState, action) {
  switch (action.type) {
    case SET_RADIO_VALUE:
      return state.set('radioValue', action.payload);
    case FETCH_BATTERY_OVERALL_CONDITION_DATA:
      return state
        .set('loadingBatteryOverallCondition', true)
        .set('error', null);
    case FETCH_BATTERY_OVERALL_CONDITION_DATA_SUCCESS:
      return state
        .set('loadingBatteryOverallCondition', false)
        .set('batteryOverallConditionData', action.batteryOverallConditionData);
    case FETCH_BATTERY_OVERALL_CONDITION_DATA_FAILURE:
      return state
        .set('loadingBatteryOverallCondition', false)
        .set('batteryOverallConditionData', null)
        .set('error', action.error);

    case FETCH_BATTERY_SOC_DATA:
      return state.set('loadingBatterySOC', true).set('error', null);
    case FETCH_BATTERY_SOC_DATA_SUCCESS:
      return state
        .set('loadingBatterySOC', false)
        .set('batterySOCData', action.batteryStateOfChargeData);
    case FETCH_BATTERY_SOC_DATA_FAILURE:
      return state
        .set('loadingBatterySOC', false)
        .set('batterySOCData', null)
        .set('error', action.error);

    case FETCH_BATTERY_SOC_RANGE_DATA:
      return state.set('loadingBatterySOCRange', true).set('error', null);
    case FETCH_BATTERY_SOC_RANGE_DATA_SUCCESS:
      return state
        .set('loadingBatterySOCRange', false)
        .set('batterySOCRangeData', action.batterySOCRangeData);
    case FETCH_BATTERY_SOC_RANGE_DATA_FAILURE:
      return state
        .set('loadingBatterySOCRange', false)
        .set('batterySOCRangeData', null)
        .set('error', action.error);

    case FETCH_CELL_VOLTAGE_DATA:
      return state.set('loadingCellVoltage', true).set('error', null);
    case FETCH_CELL_VOLTAGE_DATA_SUCCESS:
      return state
        .set('loadingCellVoltage', false)
        .set('cellVoltageData', action.cellVoltageData);
    case FETCH_CELL_VOLTAGE_DATA_FAILURE:
      return state
        .set('loadingCellVoltage', false)
        .set('cellVoltageData', null)
        .set('error', action.error);

    case FETCH_SYNC_GRAPH_DATA:
      return state.set('loadingSyncGraph', true).set('error', null);
    case FETCH_SYNC_GRAPH_DATA_SUCCESS:
      return state
        .set('loadingSyncGraph', false)
        .set('syncGraphData', action.syncGraphData);
    case FETCH_SYNC_GRAPH_DATA_FAILURE:
      return state
        .set('loadingSyncGraph', false)
        .set('syncGraphData', null)
        .set('error', action.error);

    case FETCH_HISTOGRAM_DATA:
      return state.set('loadingHistogram', true).set('error', null);
    case FETCH_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingHistogram', false)
        .set('histogramData', action.histogramData);
    case FETCH_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingHistogram', false)
        .set('histogramData', null)
        .set('error', action.error);

    case FETCH_BOXPLOT_DATA:
      return state.set('loadingBoxPlot', true).set('error', null);
    case FETCH_BOXPLOT_DATA_SUCCESS:
      return state
        .set('loadingBoxPlot', false)
        .set('boxPlotData', action.boxPlotData);
    case FETCH_BOXPLOT_DATA_FAILURE:
      return state
        .set('loadingBoxPlot', false)
        .set('boxPlotData', null)
        .set('error', action.error);

    case CLEAR_CHARTDATA:
      return state
        .set('batterySOCRangeData', null)
        .set('batterySOCData', null)
        .set('cellVoltageData', null)
        .set('temperatureHistogramData', null)
        .set('usgSocHistogramData', null)
        .set('usgPowHistogramData', null)
        .set('usgDodHistogramData', null)
        .set('operatingHoursHistogramData', null)
        .set('boxPlotData', null)
        .set('syncGraphData', null)
        .set('tTAvgCellVData', null)
        .set('tTAvgTemperatureData', null)
        .set('tTSOCData', null)
        .set('tTPowerData', null)
        .set('tTBVoltageData', null)
        .set('tTBCurrentData', null)
        .set('tTBatteryPeakCharge', null)
        .set('tTBatteryPeakDisCharge', null)
        .set('tTBatteryConstCharge', null)
        .set('tTBatteryConstDisCharge', null)
        .set('tTBatteryOverloadCounter', null)
        .set('tTReliability', null)
        .set('tTFeedFlow', null)
        .set('tTOverloadCounter', null)
        .set('tTReturnFlow', null)
        .set('tTDiagnostic', null)
        .set('tTContactorStatus', null);

    case CLEAR_BATTERY_OVERVIEW_DATA:
      return state
        .set('batteryOverviewData', null)
        .set('batteryRelData', null)
        .set('batteryStrData', null);

    /// //////////////////////////////////////////////////////////////////////////////
    case FETCH_BATTERY_OVERVIEW_DATA:
      return state.set('loadingBatteryOverview', true).set('error', null);
    case FETCH_BATTERY_OVERVIEW_DATA_SUCCESS:
      return state
        .set('loadingBatteryOverview', false)
        .set('batteryOverviewData', action.batteryOverviewData);
    case FETCH_BATTERY_OVERVIEW_DATA_FAILURE:
      return state
        .set('loadingBatteryOverview', false)
        .set('batteryOverviewData', null)
        .set('error', action.error);

    case FETCH_TEMPERATURES_HISTOGRAM_DATA:
      return state.set('loadingTemperatureHistogram', true).set('error', null);
    case FETCH_TEMPERATURES_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingTemperatureHistogram', false)
        .set('temperatureHistogramData', action.temperatureHistogramData);
    case FETCH_TEMPERATURES_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingTemperatureHistogram', false)
        .set('temperatureHistogramData', null)
        .set('error', action.error);
    /// ///////////////////////////////////////////////////
    case FETCH_USGSOC_HISTOGRAM_DATA:
      return state.set('loadingUsgSocHistogram', true).set('error', null);
    case FETCH_USGSOC_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingUsgSocHistogram', false)
        .set('usgSocHistogramData', action.usgSocHistogramData);
    case FETCH_USGSOC_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingUsgSocHistogram', false)
        .set('usgSocHistogramData', null)
        .set('error', action.error);

    case FETCH_USGPOW_HISTOGRAM_DATA:
      return state.set('loadingUsgPowHistogram', true).set('error', null);
    case FETCH_USGPOW_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingUsgPowHistogram', false)
        .set('usgPowHistogramData', action.usgPowHistogramData);
    case FETCH_USGPOW_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingUsgPowHistogram', false)
        .set('usgPowHistogramData', null)
        .set('error', action.error);

    case FETCH_USGDOD_HISTOGRAM_DATA:
      return state.set('loadingUsgDodHistogram', true).set('error', null);
    case FETCH_USGDOD_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingUsgDodHistogram', false)
        .set('usgDodHistogramData', action.usgDodHistogramData);
    case FETCH_USGDOD_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingUsgDodHistogram', false)
        .set('usgDodHistogramData', null)
        .set('error', action.error);
    /// ///////////////////////////////////////////////////////////////////////

    case FETCH_USGDOD_HEATMAP_DATA:
      return state.set('loadingUsgDodHeatmap', true).set('error', null);
    case FETCH_USGDOD_HEATMAP_DATA_SUCCESS:
      return state
        .set('loadingUsgDodHeatmap', false)
        .set('usgDodHeatmapData', action.usgDodHeatmapData);
    case FETCH_USGDOD_HEATMAP_DATA_FAILURE:
      return state
        .set('loadingUsgDodHeatmap', false)
        .set('usgDodHeatmapData', null)
        .set('error', action.error);

    case FETCH_USGPOW_HEATMAP_DATA:
      return state.set('loadingUsgPowHeatmap', true).set('error', null);
    case FETCH_USGPOW_HEATMAP_DATA_SUCCESS:
      return state
        .set('loadingUsgPowHeatmap', false)
        .set('usgPowHeatmapData', action.usgPowHeatmapData);
    case FETCH_USGPOW_HEATMAP_DATA_FAILURE:
      return state
        .set('loadingUsgPowHeatmap', false)
        .set('usgPowHeatmapData', null)
        .set('error', action.error);

    case FETCH_USGSOC_HEATMAP_DATA:
      return state.set('loadingUsgSocHeatmap', true).set('error', null);
    case FETCH_USGSOC_HEATMAP_DATA_SUCCESS:
      return state
        .set('loadingUsgSocHeatmap', false)
        .set('usgSocHeatmapData', action.usgSocHeatmapData);
    case FETCH_USGSOC_HEATMAP_DATA_FAILURE:
      return state
        .set('loadingUsgSocHeatmap', false)
        .set('usgSocHeatmapData', null)
        .set('error', action.error);

    case FETCH_USGTEMP_HEATMAP_DATA:
      return state.set('loadingUsgTempHeatmap', true).set('error', null);
    case FETCH_USGTEMP_HEATMAP_DATA_SUCCESS:
      return state
        .set('loadingUsgTempHeatmap', false)
        .set('usgTempHeatmapData', action.usgTempHeatmapData);
    case FETCH_USGTEMP_HEATMAP_DATA_FAILURE:
      return state
        .set('loadingUsgTempHeatmap', false)
        .set('usgTempHeatmapData', null)
        .set('error', action.error);
    /// //////////////////////////////////////////////////////////////////////////////
    case FETCH_OPERATING_HOURS_HISTOGRAM_DATA:
      return state
        .set('loadingOperatingHoursHistogram', true)
        .set('error', null);
    case FETCH_OPERATING_HOURS_HISTOGRAM_DATA_SUCCESS:
      return state
        .set('loadingOperatingHoursHistogram', false)
        .set('operatingHoursHistogramData', action.operatingHoursHistogramData);
    case FETCH_OPERATING_HOURS_HISTOGRAM_DATA_FAILURE:
      return state
        .set('loadingOperatingHoursHistogram', false)
        .set('operatingHoursHistogramData', null)
        .set('error', action.error);
    case SET_MAINSIGNAL1:
      return state.set('selectedMainSignal1', action.payload.trend);
    case SET_MAINSIGNAL2:
      return state.set('selectedMainSignal2', action.payload.trend);
    case SET_MAINSIGNAL3:
      return state.set('selectedMainSignal3', action.payload.trend);
    case SET_OVERLAYSIGNAL1:
      return state.set('selectedOverlaySignal1', action.payload.trend);
    case SET_OVERLAYSIGNAL2:
      return state.set('selectedOverlaySignal2', action.payload.trend);
    case SET_OVERLAYSIGNAL3:
      return state.set('selectedOverlaySignal3', action.payload.trend);

    case SET_MAINSIGNAL:
      return state.set('selectedMainSignal', action.payload.trend);
    case SET_OVERLAYSIGNAL:
      return state.set('selectedOverlaySignal', action.payload.trend);
    case SET_SYNCCHART:
      return state.set('selectedSyncChart', action.payload.trend);

    case SET_SYNCCHARTDATA:
      return state.set('syncGraphDataRes', action.payload.trend);

    case GET_DETAILVIEW_TIMETREND:
      return state.set('loadingTimetrend', true).set('error', null);
    case GET_DETAILVIEW_TIMETREND_SUCCESS:
      return state
        .set('loadingTimetrend', false)
        .set('timetrendData', action.payload);

    case GET_TT_AVGCELLV_SUCCESS:
      return state.set('tTAvgCellVData', action.payload);
    case GET_TT_AVGTEMP_SUCCESS:
      return state.set('tTAvgTemperatureData', action.payload);
    case GET_TT_SOC_SUCCESS:
      return state.set('tTSOCData', action.payload);
    case GET_TT_POWER_SUCCESS:
      return state.set('tTPowerData', action.payload);
    case GET_TT_BVOLTAGE_SUCCESS:
      return state.set('tTBVoltageData', action.payload);
    case GET_TT_BCURRENT_SUCCESS:
      return state.set('tTBCurrentData', action.payload);
    case GET_TT_BPCHARGE_SUCCESS:
      return state.set('tTBatteryPeakCharge', action.payload);
    case GET_TT_BPDISCHARGE_SUCCESS:
      return state.set('tTBatteryPeakDisCharge', action.payload);
    case GET_TT_BCCHARGE_SUCCESS:
      return state.set('tTBatteryConstCharge', action.payload);
    case GET_TT_BCDISCHARGE_SUCCESS:
      return state.set('tTBatteryConstDisCharge', action.payload);
    case GET_TT_BOLCOUNTER_SUCCESS:
      return state.set('tTBatteryOverloadCounter', action.payload);
    case GET_TT_RELIABILITY_SUCCESS:
      return state.set('tTReliability', action.payload);
    case GET_TT_FEEDFLOW_SUCCESS:
      return state.set('tTFeedFlow', action.payload);
    case GET_TT_OVERLOADCOUNTER_SUCCESS:
      return state.set('tTOverloadCounter', action.payload);
    case GET_TT_RETURNFLOW_SUCCESS:
      return state.set('tTReturnFlow', action.payload);
    case GET_TT_DIAGNOSTIC_SUCCESS:
      return state.set('tTDiagnostic', action.payload);
    case GET_TT_CONTACTORSTATUS_SUCCESS:
      return state.set('tTContactorStatus', action.payload);

    case GET_DETAILVIEW_TIMETREND_FAILURE:
      return (
        state
          .set('loadingTimetrend', false)
          // .set("timetrendData", null)
          .set('error', action.payload)
      );
    case CLEAR_DETAILVIEW_TIMETREND:
      return state
        .set('tTAvgCellVData', null)
        .set('tTAvgTemperatureData', null)
        .set('tTSOCData', null)
        .set('tTPowerData', null)
        .set('tTBVoltageData', null)
        .set('tTBCurrentData', null)
        .set('tTBatteryPeakCharge', null)
        .set('tTBatteryPeakDisCharge', null)
        .set('tTBatteryConstCharge', null)
        .set('tTBatteryConstDisCharge', null)
        .set('tTBatteryOverloadCounter', null)
        .set('tTReliability', null)
        .set('tTFeedFlow', null)
        .set('tTOverloadCounter', null)
        .set('tTReturnFlow', null)
        .set('tTDiagnostic', null)
        .set('tTContactorStatus', null);

    case SET_TIMETREND_LOADING:
      return state.set('loadingTimetrend', action.payload);

    case SET_MAINTRENDSIGNAL:
      return state.set('selectedMainTrend', action.payload.trend);

    case ADD_OVERLAYTRENDSIGNAL:
      return state.set('selectedOverlaysTrend', action.payload);

    case FETCH_BATTERY_REL_DATA:
      return state.set('loadingBatteryRel', true).set('error', null);
    case FETCH_BATTERY_REL_DATA_SUCCESS:
      return state
        .set('loadingBatteryRel', false)
        .set('batteryRelData', action.batteryRelData);
    case FETCH_BATTERY_REL_DATA_FAILURE:
      return state
        .set('loadingBatteryRel', false)
        .set('batteryRelData', null)
        .set('error', action.error);
    case FETCH_BATTERY_STR_DATA:
      return state.set('loadingBatteryStr', true).set('error', null);
    case FETCH_BATTERY_STR_DATA_SUCCESS:
      return state
        .set('loadingBatteryStr', false)
        .set('batteryStrData', action.batteryStrData);
    case FETCH_BATTERY_STR_DATA_FAILURE:
      return state
        .set('loadingBatteryStr', false)
        .set('batteryStrData', null)
        .set('error', action.error);

    case FETCH_SOH_DATA:
      return state.set('loadingSOHData', true).set('error', null);
    case FETCH_SOH_DATA_SUCCESS:
      return state.set('loadingSOHData', false).set('SOHData', action.sohData);
    case FETCH_SOH_DATA_FAILURE:
      return state
        .set('loadingSOHData', false)
        .set('SOHData', null)
        .set('error', action.error);

    default:
      return state;
  }
}
