import Highcharts from 'highcharts-latest';
import accessibility from 'highcharts-latest/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef, useState } from 'react';

accessibility(Highcharts);
function SimpleChart({ options }) {
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef(null);
  useEffect(() => {
    setChartOptions(options);
    const chart = chartRef.current.chart;
    chart.reflow();
  }, [options]);

  return (
    <div>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
}

export default SimpleChart;
