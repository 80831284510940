import React, { useEffect, useState } from 'react';
import exportChart from '../../assets/images/exportChart.svg';
import NoDataFound from './NoDataFound';
import SimpleChart from './SimpleChart';

function FleetCharts({
  data,
  vehicle,
  setSelectedRange,
  setEndRange,
  setStartRange,
}) {
  const url = new URL(window.location.href);
  const variable = url.searchParams.get('ut')
    ? url.searchParams.get('ut')
    : 'none';
  let enable = false;
  if (variable.includes('SoH')) {
    enable = true;
  } else {
    enable = false;
  }
  const [options, setOptions] = useState({
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.2,
        borderWidth: 0,
        // condition for SoH, it does not require between
        pointPlacement: variable.includes('SoH') ? 'none' : 'between',
        color: '#3B786E',
      },
    },
    series: [
      {
        showInLegend: false,
        name: '',
        data: [],
      },
    ],
  });

  useEffect(() => {
    if (data) {
      setOptions({
        ...options,
        chart: {
          type: 'column',
          events: {
            render: function () {
              const chart = this;
              // add attribute to each of the columns
              chart.series[0].data.forEach((point, i) => {
                point.graphic.attr({
                  'data-intro': `fleet-satistics-columns-${i}`,
                });
              });
            },
          },
        },
        xAxis: {
          startOnTick: false,
          endOnTick: false,
          lineWidth: 0,
          tickWidth: 0,
          categories: data.xAxis,
          crosshair: true,
          title: {
            text: data.xTitle,
            align: 'low',
          },
        },
        yAxis: {
          title: {
            text: data.yTitle,
            align: 'high',
            margin: 55,
          },
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            return `${this.y.toFixed(2)}`;
          },
        },
        series: [
          {
            showInLegend: false,
            // to show the data labels on top of the bars
            dataLabels: {
              enabled: enable,
              formatter: function () {
                return this.y.toFixed(0);
              },
            },
            exporting: {
              buttons: {
                contextButton: {
                  _id: 'custom_export',
                  symbol: `url(${exportChart})`,
                  symbolX: 25,
                  symbolY: 40,
                  align: 'right',
                  verticalAlign: 'top',
                  height: 28,
                  width: 32,
                  symbolSize: 22,
                  x: 40,
                  y: 50,
                  menuItems: ['downloadPNG', 'printChart'],
                  theme: {
                    states: {
                      hover: {
                        fill: 'transparent',
                      },
                      pressed: {
                        fill: 'transparent',
                      },
                    },
                  },
                },
              },
            },
            name: '',
            data: data.data.map(item => item.y),
            point: {
              events: {
                click: function () {
                  const pointIndex = this.index;
                  const xAxis = this.series.xAxis;
                  const onlyNumber = this.category?.name;
                  const newNumber = onlyNumber.replace(/[^\d-]/g, '');
                  // const startValue = xAxis.categories[pointIndex];
                  const endValue = xAxis.categories[pointIndex + 1] || '';
                  // set the selected range to the clicked category
                  setSelectedRange(newNumber);
                  setEndRange(endValue);
                  setStartRange(onlyNumber);
                  // Reset all bars to default color
                  this.series.data.forEach(point => {
                    point.update({ color: '#3B786E90' }, true, false);
                  });
                  // Highlight the clicked bar
                  this.update({ color: '#3B786E' }, true, false);
                },
              },
            },
          },
        ],
      });
    }
  }, [data, vehicle]);
  return (
    <div>
      {data && data.xAxis.length ? (
        <div>
          <div className="text-right">
            Click on each bar in the histogram to view the battery list.
          </div>
          <div
            style={{
              width: '100%',
              height: '450px',
              border: '0px solid #0000001F',
            }}>
            <SimpleChart options={options} />
          </div>
        </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}

export default FleetCharts;
