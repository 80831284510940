import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  fetchBatteryOverallConditionDataFailure,
  fetchBatteryOverallConditionDataSucess,
  fetchBatteryOverviewDataFailure,
  fetchBatteryOverviewDataSucess,
  fetchBatteryRelDataFailure,
  fetchBatteryRelDataSucess,
  fetchBatterySOCRangeDataFailure,
  fetchBatterySOCRangeDataSucess,
  fetchBatteryStateOfChargeDataFailure,
  fetchBatteryStateOfChargeDataSucess,
  fetchBatteryStrDataFailure,
  fetchBatteryStrDataSucess,
  fetchBoxPlotDataFailure,
  fetchBoxPlotDataSucess,
  fetchCellVoltageDataFailure,
  fetchCellVoltageDataSucess,
  fetchHistogramDataFailure,
  fetchHistogramDataSucess,
  fetchOperatingHoursHistogramDataFailure,
  fetchOperatingHoursHistogramDataSucess,
  fetchSOHDataFailure,
  fetchSOHDataSucess,
  fetchSyncGraphDataFailure,
  fetchSyncGraphDataSucess,
  fetchTemperatureHistogramDataFailure,
  fetchTemperatureHistogramDataSucess,
  fetchUsgDodHeatmapDataFailure,
  fetchUsgDodHeatmapDataSucess,
  fetchUsgDodHistogramDataFailure,
  fetchUsgDodHistogramDataSucess,
  fetchUsgPowHeatmapDataFailure,
  fetchUsgPowHeatmapDataSucess,
  fetchUsgPowHistogramDataFailure,
  fetchUsgPowHistogramDataSucess,
  fetchUsgSocHeatmapDataFailure,
  fetchUsgSocHeatmapDataSucess,
  fetchUsgSocHistogramDataFailure,
  fetchUsgSocHistogramDataSucess,
  fetchUsgTempHeatmapDataFailure,
  fetchUsgTempHeatmapDataSucess,
  getDetailViewTimetrendFailure,
  getTtAvgCellVSuccess,
  getTtAvgTempSuccess,
  getTtBCChargeSuccess,
  getTtBCDisChargeSuccess,
  getTtBCurrentSuccess,
  getTtBOLCounterSuccess,
  getTtBPChargeSuccess,
  getTtBPDisChargeSuccess,
  getTtBVoltageSuccess,
  getTtContactorStatusSuccess,
  getTtDiagnosticEventSuccess,
  getTtFeedflowSuccess,
  getTtOverloadcounterSuccess,
  getTtPowerSuccess,
  getTtReliabilitySuccess,
  getTtReturnflowSuccess,
  getTtSOCSuccess,
} from './actions';
import {
  FETCH_BATTERY_OVERALL_CONDITION_DATA,
  FETCH_BATTERY_OVERVIEW_DATA,
  FETCH_BATTERY_REL_DATA,
  FETCH_BATTERY_SOC_DATA,
  FETCH_BATTERY_SOC_RANGE_DATA,
  FETCH_BATTERY_STR_DATA,
  FETCH_BOXPLOT_DATA,
  FETCH_CELL_VOLTAGE_DATA,
  FETCH_HISTOGRAM_DATA,
  FETCH_OPERATING_HOURS_HISTOGRAM_DATA,
  FETCH_SOH_DATA,
  FETCH_SYNC_GRAPH_DATA,
  FETCH_TEMPERATURES_HISTOGRAM_DATA,
  FETCH_USGDOD_HEATMAP_DATA,
  FETCH_USGDOD_HISTOGRAM_DATA,
  FETCH_USGPOW_HEATMAP_DATA,
  FETCH_USGPOW_HISTOGRAM_DATA,
  FETCH_USGSOC_HEATMAP_DATA,
  FETCH_USGSOC_HISTOGRAM_DATA,
  FETCH_USGTEMP_HEATMAP_DATA,
  GET_DETAILVIEW_TIMETREND,
} from './constants';

export function* fetchBatteryOverallConditionSaga() {
  yield takeLatest(
    FETCH_BATTERY_OVERALL_CONDITION_DATA,
    fetchingBatteryOverallConditionSaga
  );
}
export function* fetchingBatteryOverallConditionSaga(action) {
  try {
    const { sitename, fleetname, vehicleName, pecUniqueSerialNumber } =
      action.payload;
    const response = yield call(
      api.fetchBatteryOverallConditionData,
      sitename,
      fleetname,
      vehicleName,
      pecUniqueSerialNumber
    );
    const batteryOverallConditionData = response.dc;
    yield put(
      fetchBatteryOverallConditionDataSucess(batteryOverallConditionData)
    );
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchBatteryOverallConditionDataFailure));
  }
}

export function* fetchBatteryStateOfChargeSaga() {
  yield takeLatest(FETCH_BATTERY_SOC_DATA, fetchingBatteryStateOfChargeSaga);
}
export function* fetchingBatteryStateOfChargeSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate } =
      action.payload;
    const response = yield call(
      api.fetchBatteryStateOfChargeData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate
    );
    const batteryStateOfChargeData = response;
    yield put(fetchBatteryStateOfChargeDataSucess(batteryStateOfChargeData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchBatteryStateOfChargeDataFailure));
  }
}

export function* fetchBatterySOCRangeSaga() {
  yield takeLatest(FETCH_BATTERY_SOC_RANGE_DATA, fetchingBatterySOCRangeSaga);
}
export function* fetchingBatterySOCRangeSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchBatterySOCRangeData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const batterySOCRangeData = response;
    yield put(fetchBatterySOCRangeDataSucess(batterySOCRangeData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchBatterySOCRangeDataFailure));
  }
}

export function* fetchCellVoltageSaga() {
  yield takeLatest(FETCH_CELL_VOLTAGE_DATA, fetchingCellVoltageSaga);
}
export function* fetchingCellVoltageSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate } =
      action.payload;
    const response = yield call(
      api.fetchCellVoltageData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate
    );
    const cellVoltageData = response.dc;
    yield put(fetchCellVoltageDataSucess(cellVoltageData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchCellVoltageDataFailure));
  }
}
// sync graph
export function* fetchSyncGraphSaga() {
  yield takeLatest(FETCH_SYNC_GRAPH_DATA, fetchingSyncGraphSaga);
}
export function* fetchingSyncGraphSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate } =
      action.payload;
    const response = yield call(
      api.fetchSyncGraphData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate
    );
    const syncGraphData = response.dc[0].series;
    yield put(fetchSyncGraphDataSucess(syncGraphData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchSyncGraphDataFailure));
  }
}

export function* fetchHistogramSaga() {
  yield takeLatest(FETCH_HISTOGRAM_DATA, fetchingHistogramSaga);
}
export default function* fetchingHistogramSaga(action) {
  try {
    // const { startDate, endDate } = action.payload;
    const response = yield call(api.fetchHistogramData);
    const histogramData = response.data_Constants;
    yield put(fetchHistogramDataSucess(histogramData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchHistogramDataFailure));
  }
}

export function* fetachBoxPlotDataSaga() {
  yield takeLatest(FETCH_BOXPLOT_DATA, fetchingBoxPlotDataSaga);
}
export function* fetchingBoxPlotDataSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, docType } =
      action.payload;
    const response = yield call(
      api.fetchBoxPlotData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      docType
    );
    const BoxPlotData = [];
    if (response.dc.length !== 0) {
      BoxPlotData[0] = response.dc[0].obs[0];
      if (response.dc[0].sct[0].data.length > 0) {
        BoxPlotData[1] = response.dc[0].sct[0];
      }
    }
    // dispatch a success action to the store with the new dog
    yield put(fetchBoxPlotDataSucess(BoxPlotData));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchBoxPlotDataFailure));
  }
}

export function* fetchBatteryOverviewSaga() {
  yield takeLatest(FETCH_BATTERY_OVERVIEW_DATA, fetchingBatteryOverviewSaga);
}
export function* fetchingBatteryOverviewSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, datetype } = action.payload;
    const response = yield call(
      api.fetchBatteryOverviewData,
      vehicleName,
      pecUniqueSerialNumber,
      datetype
    );
    const batteryOverviewData = response.dc;

    yield put(fetchBatteryOverviewDataSucess(batteryOverviewData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchBatteryOverviewDataFailure));
  }
}

export function* fetchTemperatureHistogramSaga() {
  yield takeLatest(
    FETCH_TEMPERATURES_HISTOGRAM_DATA,
    fetchingTemperatureHistogramSaga
  );
}
export function* fetchingTemperatureHistogramSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchTemperatureHistogramData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const TemperatureHistogramData = response.dc;
    yield put(fetchTemperatureHistogramDataSucess(TemperatureHistogramData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchTemperatureHistogramDataFailure));
  }
}

/// //////////////////////////////////////////
export function* fetchUsgSocHistogramSaga() {
  yield takeLatest(FETCH_USGSOC_HISTOGRAM_DATA, fetchingUsgSocHistogramSaga);
}
export function* fetchingUsgSocHistogramSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchUsgSocHistogramData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const usgSocHistogramData = response.dc;
    yield put(fetchUsgSocHistogramDataSucess(usgSocHistogramData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchUsgSocHistogramDataFailure));
  }
}

export function* fetchUsgPowHistogramSaga() {
  yield takeLatest(FETCH_USGPOW_HISTOGRAM_DATA, fetchingUsgPowHistogramSaga);
}
export function* fetchingUsgPowHistogramSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchUsgPowHistogramData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const usgPowHistogramData = response.dc;
    yield put(fetchUsgPowHistogramDataSucess(usgPowHistogramData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchUsgPowHistogramDataFailure));
  }
}

export function* fetchUsgDodHistogramSaga() {
  yield takeLatest(FETCH_USGDOD_HISTOGRAM_DATA, fetchingUsgDodHistogramSaga);
}
export function* fetchingUsgDodHistogramSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchUsgDodHistogramData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const usgDodHistogramData = response.dc;
    yield put(fetchUsgDodHistogramDataSucess(usgDodHistogramData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchUsgDodHistogramDataFailure));
  }
}
/// ///////////////////////////////////////////////////////////////////////

export function* fetchUsgDodHeatmapSaga() {
  yield takeLatest(FETCH_USGDOD_HEATMAP_DATA, fetchingUsgDodHeatmapSaga);
}
export function* fetchingUsgDodHeatmapSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchUsgDodHeatmapData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const usgDodHeatmapData = response.dc;
    yield put(fetchUsgDodHeatmapDataSucess(usgDodHeatmapData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchUsgDodHeatmapDataFailure));
  }
}

export function* fetchUsgPowHeatmapSaga() {
  yield takeLatest(FETCH_USGPOW_HEATMAP_DATA, fetchingUsgPowHeatmapSaga);
}
export function* fetchingUsgPowHeatmapSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchUsgPowHeatmapData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const usgPowHeatmapData = response.dc;
    yield put(fetchUsgPowHeatmapDataSucess(usgPowHeatmapData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchUsgPowHeatmapDataFailure));
  }
}

export function* fetchUsgSocHeatmapSaga() {
  yield takeLatest(FETCH_USGSOC_HEATMAP_DATA, fetchingUsgSocHeatmapSaga);
}
export function* fetchingUsgSocHeatmapSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchUsgSocHeatmapData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const usgSocHeatmapData = response.dc;
    yield put(fetchUsgSocHeatmapDataSucess(usgSocHeatmapData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchUsgSocHeatmapDataFailure));
  }
}

export function* fetchUsgTempHeatmapSaga() {
  yield takeLatest(FETCH_USGTEMP_HEATMAP_DATA, fetchingUsgTempHeatmapSaga);
}
export function* fetchingUsgTempHeatmapSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchUsgTempHeatmapData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );

    const usgTempHeatmapData = response.dc;

    yield put(fetchUsgTempHeatmapDataSucess(usgTempHeatmapData));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchUsgTempHeatmapDataFailure));
  }
}

/// //////////////////////////////////////////////////////////////////////////////

export function* fetchOperatingHoursHistogramSaga() {
  yield takeLatest(
    FETCH_OPERATING_HOURS_HISTOGRAM_DATA,
    fetchingOperatingHoursHistogramSaga
  );
}
export function* fetchingOperatingHoursHistogramSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber, startDate, endDate, datetype } =
      action.payload;
    const response = yield call(
      api.fetchOperatingHoursHistogramData,
      vehicleName,
      pecUniqueSerialNumber,
      startDate,
      endDate,
      datetype
    );
    const operatingHoursHistogramData = response;
    yield put(
      fetchOperatingHoursHistogramDataSucess(operatingHoursHistogramData)
    );
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchOperatingHoursHistogramDataFailure));
  }
}

// Timetrend
export function* getDetailViewTimetrendSaga() {
  yield takeEvery(GET_DETAILVIEW_TIMETREND, gettingDetailViewTimetrend);
}
export function* gettingDetailViewTimetrend(action) {
  try {
    const { id, startDate, endDate, docType, bogie, datetype } = action.payload;
    const response = yield call(
      api.fetchDetailViewTimetrendData,
      id,
      startDate,
      endDate,
      docType,
      bogie,
      datetype
    );

    const GraphData = response.dc[0];

    // if (GraphData === undefined) GraphData = { data: [], config: { unit: "" } };

    // if (docType === "Slip") {
    /* yield put(getDetailViewTimetrendSuccess(GraphData.dc)); */
    // }

    if (docType === 'Avg CellV') {
      yield put(getTtAvgCellVSuccess(GraphData));
    }
    if (docType === 'Avg Temperature') {
      yield put(getTtAvgTempSuccess(GraphData));
    }
    if (docType === 'State of Charge') {
      yield put(getTtSOCSuccess(GraphData));
    }
    if (docType === 'Power') {
      yield put(getTtPowerSuccess(GraphData));
    }
    if (docType === 'Battery Voltage') {
      yield put(getTtBVoltageSuccess(GraphData));
    }
    if (docType === 'Battery Current') {
      yield put(getTtBCurrentSuccess(GraphData));
    }
    if (docType === 'BatteryPeakCharge') {
      yield put(getTtBPChargeSuccess(GraphData));
    }
    if (docType === 'BatteryPeakDisCharge') {
      yield put(getTtBPDisChargeSuccess(GraphData));
    }
    if (docType === 'BatteryConstCharge') {
      yield put(getTtBCChargeSuccess(GraphData));
    }
    if (docType === 'BatteryConstDisCharge') {
      yield put(getTtBCDisChargeSuccess(GraphData));
    }
    if (docType === 'BatteryOverloadCounter') {
      yield put(getTtBOLCounterSuccess(GraphData));
    }
    if (docType === 'Reliability') {
      yield put(getTtReliabilitySuccess(GraphData));
    }
    if (docType === 'FeedFlow') {
      yield put(getTtFeedflowSuccess(GraphData));
    }
    if (docType === 'OverloadCounter') {
      yield put(getTtOverloadcounterSuccess(GraphData));
    }
    if (docType === 'ReturnFlow') {
      yield put(getTtReturnflowSuccess(GraphData));
    }
    if (docType === 'DiagnosticEvent') {
      yield put(getTtDiagnosticEventSuccess(GraphData));
    }
    if (docType === 'Contactor Status') {
      yield put(getTtContactorStatusSuccess(GraphData));
    }
  } catch (error) {
    yield put(handleError(error, getDetailViewTimetrendFailure));
  }
}

// Battery rel data
export function* fetchBatteryRelSaga() {
  yield takeLatest(FETCH_BATTERY_REL_DATA, fetchingBatteryRelSaga);
}
export function* fetchingBatteryRelSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber } = action.payload;
    const response = yield call(
      api.fetchBatteryRelData,
      vehicleName,
      pecUniqueSerialNumber
    );
    const batteryRelData = response.dc;

    yield put(fetchBatteryRelDataSucess(batteryRelData));
  } catch (error) {
    yield put(handleError(error, fetchBatteryRelDataFailure));
  }
}

// Battery str data
export function* fetchBatteryStrSaga() {
  yield takeLatest(FETCH_BATTERY_STR_DATA, fetchingBatteryStrSaga);
}
export function* fetchingBatteryStrSaga(action) {
  try {
    const { vehicleName, pecUniqueSerialNumber } = action.payload;
    const response = yield call(
      api.fetchBatteryStrData,
      vehicleName,
      pecUniqueSerialNumber
    );
    const batteryStrData = response.dc;
    yield put(fetchBatteryStrDataSucess(batteryStrData));
  } catch (error) {
    yield put(handleError(error, fetchBatteryStrDataFailure));
  }
}

export function* fetchSOHSaga() {
  yield takeLatest(FETCH_SOH_DATA, fetchingSOHSaga);
}
export function* fetchingSOHSaga(action) {
  try {
    const { fleetName, bPUniqueSerialNumber, scuID } = action.payload;
    const response = yield call(
      api.fetchSOHData,
      fleetName,
      bPUniqueSerialNumber,
      scuID
    );
    const sohData = response;
    yield put(fetchSOHDataSucess(sohData));
  } catch (error) {
    yield put(handleError(error, fetchSOHDataFailure));
  }
}
