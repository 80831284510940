import React from 'react';

/**
 * Renders a YearPicker component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.settoggle - The function to toggle the YearPicker.
 * @param {Function} props.setselectedYear - The function to set the selected year.
 * @param {number} props.selectedYear - The currently selected year.
 * @returns {JSX.Element} The YearPicker component.
 */
export function YearPicker({ settoggle, setselectedYear, selectedYear }) {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(9),
    (val, index) => currentYear - index
  ).sort((a, b) => a - b);
  const style = {
    container: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      width: '160px',
      gap: '4px',
    },
    year: {
      padding: '8px',
      textAlign: 'center',
      width: '48px',
      cursor: 'pointer',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '400',
      hover: {
        backgroundColor: '#DDEDFD',
        border: '1px solid #2E92FA',
        color: '#0C74DA',
      },
    },
  };
  return (
    <div style={style.container}>
      {years.map((year, idx) => (
        <div
          onClick={() => {
            setselectedYear(year);
            settoggle(false);
          }}
          style={{
            ...style.year,
            backgroundColor: selectedYear === year ? '#DDEDFD' : '',
          }}
          key={idx}>
          <span>{year}</span>
        </div>
      ))}
    </div>
  );
}
